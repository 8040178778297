import React from "react";
import ObjectsService from "../../../services/Objects/ObjectsService";
import Columns from "../../common/ObjectCollection/ObjectsTableColumns";
import { useNavigate } from "react-router-dom";
import CSTable from "../../common/CSTable/CSTable";

function ObjectsTable() {
  const columns = Columns();

  const navigate = useNavigate();

  const onRowClick = (id: string) => {
    navigate(`/objects/${id}`);
  };

  return (
    <CSTable
      dataTestId="objects-list"
      columns={columns}
      loadDataFunction={ObjectsService.getObjects}
      onRowClick={onRowClick}
    />
  );
}

export default ObjectsTable;
