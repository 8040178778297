import { IComponentCellProps, ITableColumn } from "../../../../types/common";
import CSImage from "../../../common/CSImage";
import React from "react";
import Fallback from "./../../../../images/image-fallback.png";
import DealStatus from "../../DealStatus";

function Columns(): ITableColumn[] {
  return [
    {
      id: "imageUrl",
      displayName: "Deals",
      width: "280px",
      component: ImageCell,
    },
    {
      id: "name",
      displayName: "Admin deal identifier",
    },
    {
      id: "locations",
      displayName: "Location",
      width: "110px",
      pattern: (v) => v.length,
    },
    {
      id: "status",
      displayName: "Status",
      width: "110px",
      component: DealStatus,
    },
    {
      id: "startDisplayOn",
      displayName: "Start date",
      width: "150px",
    },
    {
      id: "endDisplayOn",
      displayName: "End date",
      width: "150px",
    },
  ];
}

function ImageCell(props: IComponentCellProps) {
  return (
    <CSImage
      style={{ objectFit: "cover", borderRadius: "18px" }}
      alt="object"
      width="280px"
      height="116px"
      src={props.value + "?ts=" + Date.now() || Fallback}
    />
  );
}

export default Columns;
