import { combineReducers } from "redux";
import { navbarReducer } from "./NavbarReducer";
import { alertsReducer } from "./AlertsReducer";
import { modalReducer } from "./ModalReducer";
import { authReducer } from "./AuthReducer";
import { collectionReducer } from "./CollectionReducer";
import { modalInfoReducer } from "./ModalInfoReducer";
import { objectReducer } from "./ObjectReducer";

export const rootReducer = combineReducers({
  navbar: navbarReducer,
  alerts: alertsReducer,
  modal: modalReducer,
  modalInfo: modalInfoReducer,
  auth: authReducer,
  collection: collectionReducer,
  object: objectReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
