import React from "react";
import { IObject } from "../../../types/objects";

interface IProps {
    objects: IObject[] | null;
    emptyPlaceholder?: string; 

}
function ObjectsListSimple({ objects, emptyPlaceholder }: IProps) {

    
    if (!objects)
        return <div></div>;

    return (
        <div>
            {objects.map((object, index) => (
                <div key={index} className="mb-2">
                    <img src={object.iconUrl} alt={object.internalName} className="rounded me-2" style={{ width: "40px" }} />
                    <a href={`${window.location.origin}/objects/${object.id}`} target="_blank" rel="noreferrer">
                        {object.internalName}
                    </a>
                </div>
            ))}

            {objects.length === 0 && <div>{emptyPlaceholder}</div>}
        </div>);
}

export default ObjectsListSimple;