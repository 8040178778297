import React from "react";
import CSTable from "../../common/CSTable/CSTable";
import Columns from "./UserManagementTableColumns";
import UserManagementService from "../../../services/UserManagement/UserManagementService";
import { IUser } from "../../../types/userManagement";
import { useNavigate } from "react-router-dom";

function UserManagementTable() {
  const columns = Columns();
  const navigate = useNavigate();

  const onRowClick = (id: string) => {
    navigate(`${id}`);
  };

  const inactiveRow = (r: IUser) => {
    return r.suspended;
  };

  return (
    <CSTable
      dataTestId="users-list"
      columns={columns}
      loadDataFunction={UserManagementService.getUsers}
      onRowClick={onRowClick}
      inactiveRow={inactiveRow}
    />
  );
}

export default UserManagementTable;
