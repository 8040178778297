import React from "react";
import CSTable from "../../common/CSTable/CSTable";
import Columns from "./VisibilitySchedulesTableColumns";
import VisibilitySchedulesService from "../../../services/VisibilitySchedules/VisibilitySchedulesService";
import {
  IVisibilitySchedule,
  enrichVisibilitySchedule,
} from "../../../types/visibilitySchedule";
import { useNavigate } from "react-router-dom";

function VisibilitySchedulesTable() {
  const columns = Columns();
  const navigate = useNavigate();

  const onRowClick = (id: string) => {
    navigate(`${id}`);
  };

  const inactiveRow = (r: IVisibilitySchedule) => {
    return r.isArchived;
  };

  return (
    <CSTable
      dataTestId="visibility-schedules-list"
      columns={columns}
      loadDataFunction={VisibilitySchedulesService.getAllSchedules}
      transformDataFunction={enrichVisibilitySchedule}
      onRowClick={onRowClick}
      inactiveRow={inactiveRow}
    />
  );
}

export default VisibilitySchedulesTable;
