import React from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IModal, ModalActionTypes } from "../../../types/modal";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import commonStyles from "../../../styles/common.module.css";

function ModalDialog() {
  const modal = useTypedSelector<IModal>((store) => store.modal);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({ type: ModalActionTypes.CLOSE_MODAL });
  };

  const handleSecondaryClick = () => {
    if (modal.secondaryAction) {
      modal.secondaryAction();
    }
    closeModal();
  };

  const handlePrimaryClick = () => {
    modal.primaryAction();
    closeModal();
  };

  return (
    <Modal show={modal.show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">{modal.title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={commonStyles.whiteSpacePreLine}>
        {modal.text}
      </Modal.Body>
      <Modal.Footer>
        {!modal.oneActionMode && (
          <Button variant="secondary" onClick={handleSecondaryClick}>
            {modal.secondaryText || "No"}
          </Button>
        )}
        <Button variant="primary" onClick={handlePrimaryClick}>
          {modal.primaryText || "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDialog;
