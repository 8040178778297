import React from "react";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { ISponsorshipForm, ISponsorshipType } from "../../../../types/advertisers";
import moment from "moment";
import MapObjectLookup from "../../../common/MapObjectLookup";

interface IProp {
  value: ISponsorshipForm;
  tz: string,
  valueChange: (name: keyof ISponsorshipForm, value: any) => void;
  errorValues: string[];
}
function SponsorshipEditorForm({
  value,
  tz,
  valueChange,
  errorValues,
}: IProp) {

  const startDate = moment.tz(value.startsOn, tz).format("yyyy-MM-DD");
  const endDate = moment.tz(value.expiresOn, tz).format("yyyy-MM-DD");

  const onFormControlChange = (event: any) => {
    valueChange(event.target.name, event.target.value);
  };

  const onDateChanged = (evt: any) => {
    // create new value out of new date and previous time
    const newDate = moment(evt.target.value).tz(tz, true).valueOf();

    valueChange(evt.target.name, newDate);
  };

  const onSponsorshipTypeChanged = (evt: any) => {
    valueChange("sponsorshipType", evt.target.value as ISponsorshipType);
  }

  return (
    <div data-test-id="sponsorship-editor-form">
      <Form>


        <Container>
          <Row>
            <Col>
              <Form.Group controlId="mapObjectId">
                <Form.Label>Object ID
                  <small className="text-muted ps-3">Search for an object by name or paste URL (copy it from a browser address bar).</small>
                </Form.Label>
                <MapObjectLookup name="mapObjectId" value={value.mapObjectId} onChange={onFormControlChange} />
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col>
              <Stack gap={3} direction="horizontal" style={{ minHeight: "38px" }}>
                <Form.Check
                  type="radio"
                  label="Sponsor (non-exclusive)"
                  value="Sponsor"
                  checked={value.sponsorshipType === ISponsorshipType.Sponsor}
                  onChange={onSponsorshipTypeChanged}
                />
                <Form.Check
                  type="radio"
                  label="Owner (exclusive)"
                  value="Owner"
                  checked={value.sponsorshipType === ISponsorshipType.Owner}
                  onChange={onSponsorshipTypeChanged}
                />
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="mb-0">
                Starts on
              </Form.Label>
              <Form.Control
                value={startDate}
                onChange={onDateChanged}
                type="date"
                name="startsOn"
              />
            </Col>
            <Col>
              <Form.Label className="mb-0">
                Expires on
              </Form.Label>
              <Form.Control
                value={endDate}
                onChange={onDateChanged}
                name="expiresOn"
                type="date"
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default SponsorshipEditorForm;
