import constants from "../constants";
import axios from "axios";

class HelperService {
  resizeDataURL = (dataUrl: string, width: number, height: number) => {
    return new Promise(async function (resolve) {
      const img = document.createElement("img");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.height = height;
        canvas.width = width;
        const imgW = img.width;
        const imgH = img.height;
        let dx = 0;
        let dy = 0;
        let scale;
        if (imgW < imgH) {
          scale = width / imgW;
          dy = -(imgH * scale - height) / 2;
        } else {
          scale = height / imgH;
          dx = -(imgW * scale - width) / 2;
        }
        if (ctx) {
          ctx.drawImage(img, dx, dy, imgW * scale, imgH * scale);
        }
        resolve(canvas.toDataURL());
      };
      img.src = dataUrl;
    });
  };

  getCoordinatesString = (lat: any, lon: any) => {
    if (lat === 0 && lon === 0) {
      return "";
    }
    return lat + ", " + lon;
  };
  geoObjectFromString = (geo: string) => {
    const geoObject = { lat: 0, lon: 0 };
    const geoArray = geo.split(",").map((g) => g.trim());
    if (geoArray.length !== 2) return geoObject;
    if (geoArray[0].match(constants.LATITUDE_REGEXP)) {
      geoObject.lat = +geoArray[0];
    }
    if (geoArray[1].match(constants.LONGITUDE_REGEXP)) {
      geoObject.lon = +geoArray[1];
    }
    return geoObject;
  };
  isGeoStringValid = (geo: string, isOriginValid = true) => {
    if (geo.length === 0) return true;
    const geoArray = geo.split(",").map((g) => g.trim());
    if (geoArray.length !== 2) return false;
    if (!isOriginValid && geoArray[0] === "0" && geoArray[1] === "0")
      return false;
    if (!geoArray[0].match(constants.LATITUDE_REGEXP)) return false;
    if (!geoArray[1].match(constants.LONGITUDE_REGEXP)) return false;
    return true;
  };

  dataUrlToFile = (
    dataUrl: string,
    fileName: string,
    mimeType: string
  ): Promise<File> => {
    return fetch(dataUrl)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], fileName, { type: mimeType });
      });
  };

  delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  isFileExist = async (url: string): Promise<boolean> => {
    try {
      const config = {
        url: url,
        selfHandled: true,
      };
      await axios(config).then();
      return true;
    } catch {
      return false;
    }
  };

  getAssetUrl = (
    assetsBaseUrl: string,
    asset: string,
    timestamp = false
  ): string =>
    (asset.startsWith("http") ? asset : assetsBaseUrl + "/" + asset) +
    (timestamp ? "?ts=" + Date.now() : "");
}

export default new HelperService();
