import React from "react";
import { ILocation } from "../../../../types/advertisers";
import styles from "../../../common/ObjectCollection/ObjectCollection.module.css";

interface IProp {
  location: ILocation;
}

function LocationEditorFormInfo({ location }: IProp) {
  return (
    <div>
      <div className={styles.textBlock}>
        <h6>Customer facing name</h6>
        <div data-test-id="location-info-name">{location.name}</div>
      </div>
      <div className={styles.textBlock}>
        <h6>Customer facing address</h6>
        <div data-test-id="location-info-address">{location.address}</div>
      </div>
      <div className={styles.textBlock}>
        <h6>Coordinates</h6>
        <div data-test-id="location-info-coordinates">
          {location.coordinates}
        </div>
      </div>
      {location.adminNotes && (
        <div className={styles.textBlock}>
          <h6>Admin Comment</h6>
          <div data-test-id="location-info-adminNotes">
            {location.adminNotes}
          </div>
        </div>
      )}
    </div>
  );
}

export default LocationEditorFormInfo;
