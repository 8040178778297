import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

interface IProps {
  children: any;
  to: string;
  [propName: string]: any;
}
function NavlinkWithQuery({ children, to, ...props }: IProps) {
  const { search } = useLocation();

  return (
    <Nav.Link as={Link} to={to + search} {...props}>
      {children}
    </Nav.Link>
  );
}

export default NavlinkWithQuery;
