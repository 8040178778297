import constants from "../../constants";
import axios from "axios";
import { IUserEditor } from "../../types/userManagement";

class UserManagementService {
  getUsers = () => {
    const url = `${constants.API_ADMIN_USERS}/List`;

    const params = { userPool: constants.APP_POOL };
    return axios.get(url, { params }).then((res) => res.data);
  };

  getUser = (id: string) => {
    const url = `${constants.API_ADMIN_USERS}/Get?id=${id}`;

    return axios.get(url).then((res) => ({
      ...res.data,
      name: res.data.name || "",
      availableAdvertisers: (res.data.availableAdvertisers || []).map(
        (ad: string) => ({
          id: ad,
          name: "",
        })
      ),
    }));
  };

  createUser = async (user: IUserEditor) => {
    const url = `${constants.API_ADMIN_USERS}/Create`;

    const createData = {
      email: user.email,
      name: user.name,
      role: user.role,
      userPool: constants.APP_POOL,
    };

    try {
      return await axios.post(url, createData).then((res) => res.data);
    } catch {
      return false;
    }
  };

  updateUser = async (user: IUserEditor) => {
    const url = `${constants.API_ADMIN_USERS}/Update`;

    const updateData = {
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role,
      suspended: user.suspended,
      availableAdvertisers:
        user.availableAdvertisers.map((ad) => ad.id) || null,
    };

    try {
      return await axios.put(url, updateData).then();
    } catch {
      return false;
    }
  };
}

export default new UserManagementService();
