export interface IVisibilitySchedule {
  id: string;
  name: string;
  displayTimeZone: string;
  isArchived: boolean;
  records: IScheduleRecord[];
  objectsCount: number;
}

export interface IScheduleRecord {
  startOn: number;
  visible: boolean;
  collectable: boolean;
}

export interface IScheduleRecordDisplayInfo {
  on: string;
  visible: boolean;
  collectable: boolean;
}

export interface IVisibilityScheduleEnriched extends IVisibilitySchedule {
  start: IScheduleRecordDisplayInfo;
  end: IScheduleRecordDisplayInfo;
}

export const initSchedule: IVisibilitySchedule = {
  id: "",
  name: "",
  displayTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  isArchived: false,
  records: [],
  objectsCount: 0
};

export const initScheduleRecord = (
  prevRecord: IScheduleRecord | null = null
): IScheduleRecord => {
  if (prevRecord) {
    const currentDate = new Date(prevRecord.startOn);
    currentDate.setDate(currentDate.getDate() + 1);

    var visible = false;
    var collectable = false;
    if (!prevRecord.visible) {
      visible = true;
    }
    if (prevRecord.visible && !prevRecord.collectable) {
      visible = true;
      collectable = true;
    }
    return {
      startOn: currentDate.getTime(),
      visible: visible,
      collectable: collectable,
    };
  }

  const now = new Date();
  const nextMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    0,
    0
  );
  const nextMidnightUnix = nextMidnight.getTime();
  return {
    startOn: nextMidnightUnix,
    visible: true,
    collectable: false,
  };
};

export function getDisplayDateTime(timestamp: number, tz: string) {
  let date = new Date(timestamp);
  tz = tz || "UTC";
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: tz,
  }).format(date);
}

export function enrichVisibilitySchedule(
  item: IVisibilitySchedule
): IVisibilityScheduleEnriched {
  if(item.records.length === 0) {
    return {
      ...item,
      start: {
        on: "-",
        visible: false,
        collectable: false,
      },
      end: {
        on: "-",
        visible: false,
        collectable: false,
      },
    };
  }
  return {
    ...item,
    start: {
      on: getDisplayDateTime(item.records[0].startOn, item.displayTimeZone),
      visible: item.records[0].visible,
      collectable: item.records[0].collectable,
    },
    end: {
      on: getDisplayDateTime(
        item.records[item.records.length - 1].startOn,
        item.displayTimeZone
      ),
      visible: item.records[item.records.length - 1].visible,
      collectable: item.records[item.records.length - 1].collectable,
    },
  };
}
