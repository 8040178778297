import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/action-creators/modal";
import {
  IVisibilitySchedule,
  initSchedule,
} from "../../../types/visibilitySchedule";
import { IStatus } from "../../../types/common";
import VisibilitySchedulesService from "../../../services/VisibilitySchedules/VisibilitySchedulesService";
import styles from "../../../styles/details.module.css";
import { Button } from "react-bootstrap";
import LoadingWrapper from "../../common/LoadingWrapper";
import VisibilityScheduleEditorForm from "./VisibilityScheduleEditorForm";
import ModalInfoService from "../../../redux/services/ModalInfoService";
import VisibilityScheduleAssignedObjects from "./VisibilityScheduleAssignedObjects";

function VisibilitySchedulesEditor() {
  let { scheduleId } = useParams<{ scheduleId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<IVisibilitySchedule>(initSchedule);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        return await VisibilitySchedulesService.getSchedule(id);
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    if (scheduleId) {
      loadData(scheduleId).then((res) => {
        if (res) {
          setStatus(IStatus.Success);
          setSchedule(res);
        }
      });
    } else {
      setSchedule(initSchedule);
      setStatus(IStatus.Success);
    }
  }, [scheduleId]);

  const isFormValid = () => {
    var result = true;
    if (!schedule.name || schedule.name.length === 0) {
      setErrorValues((prev) => [...prev, "name"]);
      result = false;
    }
    return result;
  };
  const onSaveClick = () => {
    console.log("save");

    if (schedule && isFormValid()) {
      ModalInfoService.showModalInfoLoading();
      (scheduleId
        ? VisibilitySchedulesService.updateSchedule(schedule)
        : VisibilitySchedulesService.createSchedule(schedule)
      ).then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          handleBackToList();
        } else {
          ModalInfoService.showModalInfoError(
            "We have problems saving a schedule"
          );
        }
      });
    }
  };
  const onCancelClick = () => {
    hasChanges
      ? dispatch(
        showModal({
          text: "Go back without saving?",
          primaryAction: handleBackToList,
        })
      )
      : handleBackToList();
  };

  const handleBackToList = () => {
    navigate(`/visibilitySchedules`);
  };

  const handleScheduleChange = (name: string, value: any) => {
    setHasChanges(true);
    setSchedule((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  return (
    <div className={styles.editorContainer}>
      {/* <h3 className={styles.pageHeader}>Visibility schedule</h3> */}


      <div className={`d-flex align-items-stretch ${styles.editorContent}`}>

        <div className={` ${styles.w50EditorContainer}`}>
          <h3 className={styles.pageHeader}>Visibility schedule</h3>
          <LoadingWrapper status={status}>
            <div className={styles.adminEditorContainer}>
              <VisibilityScheduleEditorForm
                schedule={schedule}
                scheduleChanged={handleScheduleChange}
                errorValues={errorValues}
              />
            </div>
          </LoadingWrapper>
        </div>
        <div className={`${styles.w50EditorContainer}`}>
          <h6>Assigned objects</h6>
          <VisibilityScheduleAssignedObjects scheduleId={scheduleId} />
        </div>
      </div>


      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <Button variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </Button>
      </div>

    </div>

  );
}

export default VisibilitySchedulesEditor;
