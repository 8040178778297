import React from "react";
import { IDealForm } from "../../../../types/advertisers";
import { Col, Form, Row } from "react-bootstrap";
import styles from "../../advertisers.module.css";
import commonStyles from "../../../../styles/common.module.css";
import { BiPlusCircle } from "react-icons/bi";
import ClickableImage from "../../../common/ClickableImage/ClickableImage";
import FormInputLimited from "../../../common/FormInputLimited";

interface IProp {
  deal: IDealForm;
  dealChange: (name: keyof IDealForm, value: any) => void;
  errorValues: string[];
}
function DealEditorForm({ deal, dealChange, errorValues }: IProp) {
  const onFormControlChange = (event: any) => {
    dealChange(event.target.name, event.target.value);
  };

  const onDateKeyDown = (e: any) => {
    e.preventDefault();
    return false;
  };

  const handleInputFileChange = (event: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => dealChange("imageUrl", reader.result);
  };

  const handleDeleteImageClick = () => {
    dealChange("imageUrl", "");
  };

  return (
    <div data-test-id="deal-editor-form">
      <Form>
        <FormInputLimited
          isInvalid={errorValues.includes("name")}
          data-test-id="deal-form-name"
          label="Admin deal identifier"
          onChange={onFormControlChange}
          lengthLimit={80}
          value={deal.name}
          type="text"
          name="name"
        />
        <FormInputLimited
          data-test-id="deal-form-description"
          label="Description"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={deal.description}
          name="description"
          as="textarea"
          rows={6}
        />
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Start date</Form.Label>
              <Form.Control
                isInvalid={errorValues.includes("dates")}
                data-test-id="deal-form-startDisplayOn"
                onChange={onFormControlChange}
                onKeyDown={onDateKeyDown}
                name="startDisplayOn"
                type="date"
                value={deal.startDisplayOn}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>End date</Form.Label>
              <Form.Control
                isInvalid={errorValues.includes("dates")}
                data-test-id="deal-form-endDisplayOn"
                min={deal.startDisplayOn}
                onChange={onFormControlChange}
                onKeyDown={onDateKeyDown}
                name="endDisplayOn"
                type="date"
                value={deal.endDisplayOn}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label>Link</Form.Label>
          <Form.Control
            isInvalid={errorValues.includes("link")}
            data-test-id="deal-form-link"
            onChange={onFormControlChange}
            name="link"
            type="text"
            value={deal.link}
          />
        </Form.Group>
        <div className="d-flex justify-content-center mt-5">
          {deal.imageUrl ? (
            <ClickableImage
              style={{ objectFit: "cover", borderRadius: "18px" }}
              alt="deal"
              width="280px"
              height="116px"
              src={deal.imageUrl}
              deleteClick={handleDeleteImageClick}
            />
          ) : (
            <Form.Group controlId="dealImage" data-test-id="deal-form-image">
              <Form.Label className="mb-0">
                <div
                  className={`${styles.addImage} ${
                    errorValues.includes("imageUrl") && commonStyles.errorBorder
                  }`}
                >
                  <BiPlusCircle />
                  <div className="ms-4">
                    <div>Upload Deal image</div>
                    <div>
                      <i>JPG 280x116 pix</i>
                    </div>
                  </div>
                </div>
              </Form.Label>
              <Form.Control
                type="file"
                className="d-none"
                multiple
                accept=".jpeg,.jpg"
                onChange={handleInputFileChange}
              />
            </Form.Group>
          )}
        </div>
      </Form>
    </div>
  );
}

export default DealEditorForm;
