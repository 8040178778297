import React from "react";
import { Button, ButtonGroup, Nav, Navbar } from "react-bootstrap";
import styles from "../advertisers.module.css";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { IAdvertiserItem } from "../../../types/advertisers";
import ArrowBack from "../../../images/arrowBack.png";
import AddNewButton from "../../common/AddNewButton/AddNewButton";
import PrivateAccess from "../../common/PrivateAccess";
import { UserRole } from "../../../types/userManagement";

interface IProps {
  advertiser: IAdvertiserItem;
}

function AdvertiserNavbar({ advertiser }: IProps) {
  const navigate = useNavigate();
  const locationsActive = Boolean(useMatch("advertisers/:path/locations"));
  const dealsActive = Boolean(useMatch("advertisers/:path/deals"));
  const couponsActive = Boolean(useMatch("advertisers/:path/coupons"));
  const sponsorshipActive = Boolean(useMatch("advertisers/:path/sponsorship"));
  const userManagementActive = Boolean(useMatch("advertisers/:path/user-management"));

  const handleBackToList = () => navigate("/advertisers");

  const AdvertiserInfo = () => {
    const editClick = () => {
      navigate("edit");
    };

    return (
      <div
        className={`d-flex align-items-center ${styles.adInfoContainer}`}
        data-test-id="ad-info"
      >
        <div className="d-flex flex-column align-items-end">
          <h3 className="mb-0">{advertiser.name}</h3>
          <div>{advertiser.country ? advertiser.country.displayName : ""}</div>
        </div>
        <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
          <Button
            variant="light"
            className="ms-4"
            size="sm"
            onClick={editClick}
            disabled={!advertiser.id}
          >
            Edit
          </Button>
        </PrivateAccess>
      </div>
    );
  };

  const AddItemButton = () => {
    if(locationsActive) {
      return <AddNewButton
        plusNavigation="locations/new"
        dataTestId="add-location"
      />
    }

    if(dealsActive) {
      return <AddNewButton
        disabled={advertiser.locationsCount === 0}
        plusNavigation="deals/new"
        dataTestId="add-deal"
      />
    }

    if(couponsActive) {
      return <AddNewButton
        plusNavigation="coupons/new"
        dataTestId="add-coupon"
      />
    }

    if(sponsorshipActive) {
      return <AddNewButton
        plusNavigation="sponsorship/new"
        dataTestId="add-sponsorship"
      />
    }

    if(userManagementActive) {
      return <AddNewButton
        plusNavigation="user-management/new"
        dataTestId="add-user"
      />
    }
  };

  return (
    <Navbar bg="white" className={`${styles.navbarContainer} shadow`}>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.navbarMain}`}
      >
        <div>
          <img
            alt="go back"
            className={styles.imageButton}
            data-test-id="ad-go-back"
            src={ArrowBack}
            onClick={handleBackToList}
          />
          <ButtonGroup size="sm">
            <Button
              variant="light"
              active={couponsActive}
              data-test-id="ad-nav-coupons"
            >
              <Nav.Link as={Link} to="coupons">
                Coupons
              </Nav.Link>
            </Button>
            <Button
              variant="light"
              active={dealsActive}
              data-test-id="ad-nav-deals"
            >
              <Nav.Link as={Link} to="deals">
                Deals
              </Nav.Link>
            </Button>
            <Button
              variant="light"
              active={locationsActive}
              data-test-id="ad-nav-locations"
            >
              <Nav.Link as={Link} to="locations">
                Locations
              </Nav.Link>
            </Button>
            <Button
              variant="light"
              active={sponsorshipActive}
              data-test-id="ad-nav-sponsorship"
            >
              <Nav.Link as={Link} to="sponsorship">
                Sponsorship
              </Nav.Link>
            </Button>
            <Button
              variant="light"
              active={userManagementActive}
              data-test-id="ad-nav-access-management"
            >
              <Nav.Link as={Link} to="user-management">
                Access management
              </Nav.Link>
            </Button>

          </ButtonGroup>
          <PrivateAccess
            roles={[
              UserRole.Admin,
              UserRole.Editor,
              UserRole.AdvertisingManager,
            ]}
          >
            {AddItemButton()}
          </PrivateAccess>
        </div>
        {AdvertiserInfo()}
      </div>
    </Navbar>
  );
}

export default AdvertiserNavbar;
