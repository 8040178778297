import constants from "../../constants";
import axios from "axios";
import { IVisibilitySchedule } from "../../types/visibilitySchedule";

class VisibilitySchedulesService {
  getAllSchedules = (skip: number, limit: number, text: string) => {
    const url = `${constants.API_ADMIN_VISIBILITY_SCHEDULES_ENDPOINT}/GetAll`;

    const params = { text, skip, limit };
    return axios.get(url, { params }).then((res) => res.data);
  };

  getSchedule = (id: string) => {
    const url = `${constants.API_ADMIN_VISIBILITY_SCHEDULES_ENDPOINT}/Get?id=${id}`;

    return axios.get(url).then((res) => res.data);
  };

  createSchedule = async (schedule: IVisibilitySchedule) => {
    const url = `${constants.API_ADMIN_VISIBILITY_SCHEDULES_ENDPOINT}/CreateSchedule`;

    const createData = {
      name: schedule.name,
      displayTimeZone: schedule.displayTimeZone,
      records: schedule.records,
    };

    try {
      return await axios.post(url, createData).then((res) => res.data);
    } catch {
      return false;
    }
  };

  updateSchedule = async (schedule: IVisibilitySchedule) => {
    const url = `${constants.API_ADMIN_VISIBILITY_SCHEDULES_ENDPOINT}/UpdateSchedule`;

    const updateData = {
      id: schedule.id,
      name: schedule.name,
      displayTimeZone: schedule.displayTimeZone,
      records: schedule.records,
      isArchived: schedule.isArchived,
    };

    try {
      return await axios.post(url, updateData).then();
    } catch {
      return false;
    }
  };

  deleteSchedule = async (id: string) => {
    const url = `${constants.API_ADMIN_VISIBILITY_SCHEDULES_ENDPOINT}/DeleteSchedule?id=${id}`;

    try {
      return await axios.delete(url).then();
    } catch {
      return false;
    }
  };
}

export default new VisibilitySchedulesService();
