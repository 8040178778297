import React, { useEffect, useState } from "react";
import styles from "../Object.module.css";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IObjectState, IObjectVersion } from "../../../types/objects";
import ObjectPublicService from "../../../services/Objects/ObjectPublicService";
import { IStatus } from "../../../types/common";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/action-creators/modal";
import PublicInfo from "../../common/ObjectCollection/PublicInfo";
import LoadingWrapper from "../../common/LoadingWrapper";
import PrivateAccess from "../../common/PrivateAccess";
import { UserRole } from "../../../types/userManagement";
import ModalInfoService from "../../../redux/services/ModalInfoService";

interface IProps {
  duplicateClick: (versionId: string) => void;
  editClick: (draftId: string) => void;
  backToList: () => void;
}

function ObjectPublic({ duplicateClick, editClick, backToList }: IProps) {
  let { objectId } = useParams<{ objectId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [versions, setVersions] = useState<IObjectVersion[]>([]);
  const [selectedVersionIndex, setSelectedVersionIndex] = useState<number>(0);
  const [reloadKey, setReloadKey] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async (id: string) => {
      setStatus(IStatus.Loading);
      try {
        const res = await ObjectPublicService.getVersions(id);
        setStatus(IStatus.Success);
        return res;
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };
    if (objectId) {
      loadData(objectId).then((res) => {
        if (res) {
          res.sort((a: IObjectVersion, b: IObjectVersion) => {
            if (a.state === b.state) {
              return (
                +b.versionName.split(" ")[1] - +a.versionName.split(" ")[1]
              );
            }
            if (a.state === IObjectState.Draft) return -1;
            if (b.state === IObjectState.Draft) return 1;
            if (a.state === IObjectState.Archived) return 1;
            if (b.state === IObjectState.Archived) return -1;
            return 0;
          });
          setVersions(res);
        }
      });
    }
  }, [objectId, reloadKey]);

  const isDraft = () => {
    return versions.length === 0
      ? false
      : versions[selectedVersionIndex].state === IObjectState.Draft;
  };

  const isActive = () => {
    return versions.length === 0
      ? false
      : versions[selectedVersionIndex].state === IObjectState.Published ||
          versions[selectedVersionIndex].state === IObjectState.Active;
  };

  const onSelectVersion = (index: number) => {
    setSelectedVersionIndex(index);
  };

  const onDuplicateClick = () => {
    duplicateClick(versions[selectedVersionIndex].id);
  };

  const onEditClick = () => {
    editClick(versions[selectedVersionIndex].id);
  };

  const onPublishClick = async () => {
    ModalInfoService.showModalInfoLoading();
    const { possible, problems } = await ObjectPublicService.possibleToPublish(
      versions[selectedVersionIndex]
    );
    ModalInfoService.closeModalInfo();
    dispatch(
      possible
        ? dispatch(
            showModal({
              title: "Publish",
              text: "This will publish a new version of object. Proceed?",
              primaryAction: publish,
              primaryText: "Publish",
              secondaryText: "Cancel",
            })
          )
        : showModal({
            title: "Unable to publish",
            text:
              "Unable to publish object due to several problems:\n" +
              problems.map((p) => "- " + p + "").join("\n"),
            primaryAction: () => {},
            primaryText: "Ok",
            oneActionMode: true,
          })
    );
  };

  const publish = async () => {
    const published = await ObjectPublicService.publishDraft(
      versions[selectedVersionIndex]
    );
    if (published) {
      setReloadKey((prevState) => prevState + 1);
    }
  };

  const onArchiveClick = async () => {
    dispatch(
      showModal({
        title: "Archive",
        text: "This will remove the object from the map for all users. Users that have already collected the object before will still see it in their showrooms. Proceed?",
        primaryAction: archive,
        primaryText: "Archive the object",
        secondaryText: "Cancel",
      })
    );
  };

  const archive = async () => {
    const archived = await ObjectPublicService.archiveVersion(
      versions[selectedVersionIndex].id
    );
    if (archived) {
      setReloadKey((prevState) => prevState + 1);
    }
  };

  const onDeleteClick = async () => {
    dispatch(
      showModal({
        title: "Delete",
        text: " This will delete the draft version. Proceed?",
        primaryAction: handleDelete,
        primaryText: "Delete draft",
        secondaryText: "Cancel",
      })
    );
  };

  const handleDelete = async () => {
    const deleted = await ObjectPublicService.deleteDraft(
      versions[selectedVersionIndex].id
    );
    if (deleted) {
      if (versions.length === 1) {
        backToList();
      } else {
        setReloadKey((prevState) => prevState + 1);
      }
    }
  };

  return (
    <div>
      <div className={styles.contentContainer}>
        <div className="d-flex align-items-start">
          <h3>Public</h3>
          {versions.length !== 0 && (
            <DropdownButton
              data-test-id="obj-p-ver-dropdown"
              variant={isDraft() ? "dark" : "light"}
              size="sm"
              title={
                versions[selectedVersionIndex].versionName +
                " (" +
                versions[selectedVersionIndex].state +
                ")"
              }
              className={styles.dropdownContainer}
            >
              {versions.map((version, index) => (
                <Dropdown.Item
                  as={"button"}
                  key={version.id}
                  onClick={() => onSelectVersion(index)}
                >
                  {version.versionName + " (" + version.state + ")"}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </div>
        <LoadingWrapper status={status}>
          <PublicInfo data={versions[selectedVersionIndex]} />
        </LoadingWrapper>
      </div>
      <div className={styles.buttonContainer}>
        <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
          {versions.length !== 0 &&
            (isDraft() ? (
              <>
                <Button
                  data-test-id="obj-p-delete"
                  variant="danger"
                  size="sm"
                  onClick={onDeleteClick}
                >
                  Delete
                </Button>
                <Button
                  data-test-id="obj-p-edit"
                  variant="secondary"
                  size="sm"
                  onClick={onEditClick}
                >
                  Edit
                </Button>
                <Button
                  data-test-id="obj-p-publish"
                  variant="primary"
                  size="sm"
                  onClick={onPublishClick}
                >
                  Publish
                </Button>
              </>
            ) : (
              <>
                {isActive() && (
                  <Button
                    data-test-id="obj-p-archive"
                    variant="light"
                    size="sm"
                    onClick={onArchiveClick}
                  >
                    Archive
                  </Button>
                )}
                <Button
                  data-test-id="obj-p-duplicate"
                  variant="primary"
                  size="sm"
                  onClick={onDuplicateClick}
                >
                  New version
                </Button>
              </>
            ))}
        </PrivateAccess>
      </div>
    </div>
  );
}

export default ObjectPublic;
