import React, { useLayoutEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { BiReset, BiTrash } from "react-icons/bi";
import commonStyles from "../../../styles/common.module.css";
import styles from "../Object.module.css";
import ModelCanvas from "./ModelCanvas";
import ClickableImage from "../../common/ClickableImage/ClickableImage";
import HelperService from "../../../services/HelperService";
import Model3DLib from "../../../lib/model3DLib";
import { IMimeType } from "../../../types/common";
import CSTooltip from "../../common/CSTooltip";
import { AlertType } from "../../../types/alerts";
import ObjectModelControls from "./ObjectModelControls";
import { envPresetType } from "../../../types/objects";
import AlertsService from "../../../redux/services/AlertsService";

interface IProps {
  model: any;
  modelChange: (values: { name: string; value: any }[]) => void;
}

function DraftModel({ model, modelChange }: IProps) {
  const modelContainer = useRef<HTMLDivElement | null>(null);
  const [canvasSize, setCanvasSize] = useState<number>(0);
  const [verticalRotation, setVerticalRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [exposure, setExposure] = useState<number>(0.8);
  const [preset, setPreset] = useState<envPresetType>(undefined);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const discardChanges = () => {
    setZoom(1);
    setExposure(0.8);
    setVerticalRotation(0);
  };

  const deleteModel = () => {
    modelChange([
      { name: "model", value: {} },
      { name: "image", value: {} },
      { name: "icon", value: {} },
      { name: "rotation", value: 0 },
    ]);
    discardChanges();
  };
  const deleteImage = () => {
    modelChange([
      { name: "image", value: {} },
      { name: "icon", value: {} },
    ]);
  };

  const ResetTransformation = () => {
    modelChange([{ name: "rotation", value: 0 }]);
    discardChanges();
  };

  const onRotationClick = (v = 1) => {
    modelChange([{ name: "rotation", value: (model.rotation || 0) + v * 10 }]);
  };

  const onVerticalRotationClick = (v = 1) => {
    setVerticalRotation((prevState) => prevState + v * 10);
  };

  const handleZoomChange = (e: any) => {
    setZoom(e.target.value);
  };

  const handleExposureChange = (e: any) => {
    setExposure(e.target.value);
  };

  const handlePresetChange = (e: any) => {
    setPreset(e.target.value === "bridge" ? undefined : e.target.value);
  };

  const takeScreenshot = async () => {
    if (canvasRef.current) {
      const defUrl = canvasRef.current.toDataURL("image/png");
      await processPictureUpdate(defUrl);
    }
  };

  const handleInputFileChange = async (event: any) => {
    const maxFileSize = 25 * 1024 * 1024;
    if (event.target.files[0].size <= maxFileSize) {
      const modelUrl = await Model3DLib.handleLoadModelFile(
        event.target.files[0]
      );
      if (modelUrl) {
        modelChange([
          {
            name: "model",
            value: {
              url: modelUrl,
              file: await HelperService.dataUrlToFile(
                modelUrl,
                "model.glb",
                IMimeType.model
              ),
            },
          },
          { name: "rotation", value: 0 },
        ]);
      }
    }
  };

  const processPictureUpdate = async (url: string) => {
    const image = await HelperService.resizeDataURL(url, 800, 800);
    const icon = await HelperService.resizeDataURL(url, 150, 150);
    modelChange([
      { name: "image", value: { url: image } },
      { name: "icon", value: { url: icon } },
    ]);
  };

  const handleInputPicture = async (event: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async () => {
      if (reader.result) {
        const img = new Image();
        img.src = reader.result.toString();
        img.onload = async () => {
          if (img.width < 800 || img.height < 800) {
            AlertsService.addAlert(
              "Image can't be less then 800x800 pix",
              AlertType.WARNING
            );
          } else {
            await processPictureUpdate(img.src);
          }
        };
      }
    };
  };

  useLayoutEffect(() => {
    if (modelContainer.current) {
      setCanvasSize(
        Math.min(
          modelContainer.current.offsetWidth - 100,
          modelContainer.current.offsetHeight - 150
        )
      );
    }
  }, []);

  return (
    <div
      data-test-id="mdl-container"
      ref={modelContainer}
      className={`w-100 h-100 position-relative d-flex align-items-center justify-content-center ${styles.modelContainer}`}
    >
      {model.model && model.model.url ? (
        <>
          <div
            style={{ width: canvasSize, height: canvasSize }}
            className={styles.modelViewerOuterBorder}
          >
            <ModelCanvas
              model={model.model.url}
              width={canvasSize}
              exposure={exposure}
              rotation={model.rotation || 0}
              verticalRotation={verticalRotation}
              preset={preset}
              canvasRef={canvasRef}
              zoom={zoom}
            />
            <div className={styles.modelViewerInnerBorder}></div>
          </div>

          <div
            className="position-absolute"
            style={{ left: 0, top: 0 }}
            data-test-id="mdl-img-container"
          >
            {model.image && model.image.url ? (
              <ClickableImage
                deleteClick={deleteImage}
                style={{ objectFit: "cover" }}
                src={model.image.url}
                width={64}
                height={64}
                rounded
              />
            ) : (
              <>
                <Button
                  data-test-id="mdl-img-take-pic"
                  variant="light"
                  size="sm"
                  onClick={takeScreenshot}
                >
                  Take picture
                </Button>
                <Form.Group
                  controlId="pictureInput"
                  className="d-inline"
                  data-test-id="mdl-img-upload-pic"
                >
                  <Button variant="link" size="sm">
                    <Form.Label className={`${commonStyles.pointer} mb-0`}>
                      Upload picture
                    </Form.Label>
                  </Button>
                  <Form.Control
                    type="file"
                    className="d-none"
                    accept=".png,.jpeg,.jpg"
                    onChange={handleInputPicture}
                  />
                </Form.Group>
              </>
            )}
          </div>
          <ObjectModelControls
            canvasWidth={canvasSize}
            zoom={[zoom, handleZoomChange]}
            exposure={[exposure, handleExposureChange]}
            preset={[preset, handlePresetChange]}
            onRotation={onRotationClick}
            onVerticalRotation={onVerticalRotationClick}
          />
          <CSTooltip text="Reset transformation" placement="bottom">
            <Button
              data-test-id="mdl-reset"
              variant="light"
              size="sm"
              className="position-absolute"
              style={{ right: 40, top: 0 }}
              onClick={ResetTransformation}
            >
              <BiReset />
            </Button>
          </CSTooltip>
          <CSTooltip text="Delete model" placement="bottom">
            <Button
              data-test-id="mdl-delete"
              variant="danger"
              size="sm"
              className="position-absolute"
              style={{ right: 0, top: 0 }}
              onClick={deleteModel}
            >
              <BiTrash />
            </Button>
          </CSTooltip>
        </>
      ) : (
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Form.Group controlId="modelInput" data-test-id="mdl-upload">
            <Button variant="link" className="pb-0">
              <Form.Label className={`${commonStyles.pointer} mb-0`}>
                Upload 3D model
              </Form.Label>
            </Button>
            <Form.Control
              type="file"
              className="d-none"
              accept=".glb,.gltf"
              onChange={handleInputFileChange}
            />
          </Form.Group>
          <div>
            <small>
              <i>GLB, GLTF format. Max file size: 25 Mb</i>
            </small>
          </div>
        </div>
      )}
    </div>
  );
}

export default DraftModel;
