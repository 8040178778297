import React from "react";
import { Button, Dropdown, Image } from "react-bootstrap";
import Avatar from "../../images/default-avatar.jpeg";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { userRolesStorage } from "../../types/userManagement";

function Profile() {
  const user = useTypedSelector((store) => store.auth);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };
  return (
    <Dropdown align="end">
      <Dropdown.Toggle className="bg-white border-0 p-0">
        <Image src={Avatar} width={24} height={24} rounded></Image>
      </Dropdown.Toggle>
      <Dropdown.Menu className="shadow p-0">
        <div className="p-3">
          {user.role && (
            <h6 className="mb-0">{userRolesStorage[user.role].name}</h6>
          )}

          <div className="text-muted small">{user.email}</div>
        </div>
        <hr className="m-0" />
        <div className="d-flex justify-content-center p-2">
          <Button variant="light" onClick={logout}>
            Logout
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Profile;
