import constants from "../../constants";
import axios from "axios";
import { IDeal, IDealForm, IDealStatus } from "../../types/advertisers";
import HelperService from "../HelperService";
import { IMimeType } from "../../types/common";
import CommonService from "../CommonService";
import moment from "moment-timezone";

class DealsService {
  getDealStatus = (
    startDisplayOn: number,
    endDisplayOn: number
  ): IDealStatus => {
    const now = new Date().getTime();
    if (endDisplayOn < now) return IDealStatus.Expired;
    if (startDisplayOn > now) return IDealStatus.Scheduled;
    return IDealStatus.Active;
  };

  getDeals = (advertiserId: any, tz: string | null = null) => {
    const url = `${constants.API_ADMIN_AD}/GetDeals?advertiserId=${advertiserId}`;
    const timeZone = tz ? tz : moment.tz.guess();

    return axios.get(url).then((res): IDeal[] =>
      res.data.map((d: IDeal) => ({
        ...d,
        status:
          d.status === IDealStatus.Draft
            ? IDealStatus.Draft
            : this.getDealStatus(d.startDisplayOn, d.endDisplayOn),
        startDisplayOn: moment.tz(d.startDisplayOn, timeZone).format("ll"),
        endDisplayOn: moment.tz(d.endDisplayOn, timeZone).format("ll"),
      }))
    );
  };

  getDeal = (dealId: string, tz: string | null = null) => {
    const url = `${constants.API_ADMIN_AD}/GetDeal?id=${dealId}`;
    const timeZone = tz ? tz : moment.tz.guess();

    return axios.get(url).then(
      (res): IDeal => ({
        ...res.data,
        status:
          res.data.status === IDealStatus.Draft
            ? IDealStatus.Draft
            : this.getDealStatus(
                res.data.startDisplayOn,
                res.data.endDisplayOn
              ),
        startDisplayOn: moment.tz(res.data.startDisplayOn, timeZone),
        endDisplayOn: moment.tz(res.data.endDisplayOn, timeZone),
      })
    );
  };

  updateDeal = async (
    deal: IDealForm,
    locations: string[],
    tz: string | null = null
  ) => {
    try {
      const url = `${constants.API_ADMIN_AD}/UpdateDeal`;

      let imageUpdate = false;

      if (deal.imageUrl.startsWith("data:")) {
        await this.uploadDealImage(deal.imageUrl, deal.id);
        imageUpdate = true;
      }

      const timeZone = tz ? tz : "UTC";

      const updateData = {
        id: deal.id,
        startDisplayOn: moment.tz(deal.startDisplayOn, timeZone).format("x"),
        endDisplayOn: moment.tz(deal.endDisplayOn, timeZone).format("x"),
        name: deal.name,
        link: { url: deal.link, title: "" },
        locations: locations,
        status:
          deal.status === IDealStatus.Draft
            ? IDealStatus.Draft
            : IDealStatus.Active,
        description: deal.description,
      };

      await axios.post(url, updateData).then();
      if (imageUpdate) await HelperService.delay(5000);

      return true;
    } catch {
      return false;
    }
  };

  uploadDealImage = async (imageUrl: string, dealId: string) => {
    const getUploadUrl = `${constants.API_ADMIN_AD}/GetDealCoverUploadUrl`;
    const file: File = await HelperService.dataUrlToFile(
      imageUrl,
      "image.jpg",
      IMimeType.imageJpeg
    );
    await CommonService.uploadFile(getUploadUrl, file, { dealId });
  };

  createDeal = async (
    deal: IDealForm,
    locations: string[],
    tz: string | null = null
  ) => {
    try {
      const url = `${constants.API_ADMIN_AD}/CreateDeal`;
      const timeZone = tz ? tz : "UTC";

      const createData = {
        advertiserId: deal.advertiserId,
        startDisplayOn: moment.tz(deal.startDisplayOn, timeZone).format("x"),
        endDisplayOn: moment.tz(deal.endDisplayOn, timeZone).format("x"),
        name: deal.name,
        link: { url: deal.link, title: deal.linkTitle },
        locations: locations,
        status:
          deal.status === IDealStatus.Draft
            ? IDealStatus.Draft
            : IDealStatus.Active,
        description: deal.description,
      };

      const dealId = await axios
        .post(url, createData)
        .then((res) => res.data.id);

      if (deal.imageUrl.startsWith("data:"))
        await this.uploadDealImage(deal.imageUrl, dealId);

      return dealId;
    } catch {
      return false;
    }
  };

  duplicateDeal = async (deal: IDealForm, locations: string[]) => {
    const id = await this.createDeal(
      { ...deal, status: IDealStatus.Draft, imageUrl: "" },
      locations
    ).then();
    if (id && deal.imageUrl) await this.uploadDealImage(deal.imageUrl, id);
    return id;
  };

  deleteDeal = async (id: string) => {
    const url = `${constants.API_ADMIN_AD}/DeleteDeal?id=${id}`;
    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new DealsService();
