import axios from "axios";
import HelperService from "../HelperService";
import constants from "../../constants";
import { IObjectVersion } from "../../types/objects";

class ObjectPublicService {
  getVersions = (objectId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/GetVersions?objectId=${objectId}`;

    return axios.get(url).then((res) => res.data);
  };

  getVersion = (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/GetVersion?id=${versionId}`;

    return axios.get(url).then((res) => ({
      version: {
        id: res.data.id,
        objectId: res.data.objectId,
        coordinates: HelperService.getCoordinatesString(
          res.data.location.lat,
          res.data.location.lon
        ),
        title: res.data.displayName,
        details: res.data.details,
        extraPhotos: res.data.extraPhotos.map((image: string) => ({
          url: HelperService.getAssetUrl(res.data.assetsBaseUrl, image),
          name: image,
        })),
        linkTitle: res.data.link?.title || "",
        link: res.data.link?.url || "",
        modelAuthor: res.data.modelAuthor?.id || "",
        modelAuthorNote: res.data.modelAuthorNote || "",
      },
      versionFiles: {
        model: HelperService.getAssetUrl(
          res.data.assetsBaseUrl,
          res.data.modelFile,
          true
        ),
        image: HelperService.getAssetUrl(
          res.data.assetsBaseUrl,
          res.data.imageFile,
          true
        ),
        icon: HelperService.getAssetUrl(
          res.data.assetsBaseUrl,
          res.data.iconFile,
          true
        ),
      },
    }));
  };

  publishDraft = async (draft: IObjectVersion): Promise<boolean> => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/PublishVersion`;
    const publishPayload = {
      versionId: draft.id,
    };

    try {
      await axios.post(url, publishPayload).then();
      return true;
    } catch {
      return false;
    }
  };

  possibleToPublish = async (draft: IObjectVersion) => {
    let problems = [];
    let possible = true;
    if (draft.displayName.length === 0) {
      problems.push("Title field is empty");
      possible = false;
    }
    if (draft.details.length === 0) {
      problems.push("Description field is empty");
      possible = false;
    }
    if (!(draft.location.lon || draft.location.lat)) {
      problems.push("Coordinates are not set");
      possible = false;
    }
    if (
      !(await HelperService.isFileExist(
        HelperService.getAssetUrl(draft.assetsBaseUrl, draft.modelFile, true)
      ))
    ) {
      problems.push("Model file doesn't exist");
      possible = false;
    }
    if (
      !(await HelperService.isFileExist(
        HelperService.getAssetUrl(draft.assetsBaseUrl, draft.imageFile, true)
      ))
    ) {
      problems.push("Model image doesn't exist");
      possible = false;
    }
    if (
      !(await HelperService.isFileExist(
        HelperService.getAssetUrl(draft.assetsBaseUrl, draft.iconFile, true)
      ))
    ) {
      problems.push("Model icon doesn't exist");
      possible = false;
    }
    return { possible, problems };
  };

  archiveVersion = async (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/ArchiveVersion`;

    const archivePayload = {
      versionId,
    };

    try {
      await axios.post(url, archivePayload).then();
      return true;
    } catch {
      return false;
    }
  };

  deleteDraft = async (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/DeleteDraft?versionId=${versionId}`;

    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new ObjectPublicService();
