import React, { useEffect, useState } from "react";
import styles from "./ObjectsMap.module.css";
import { useDispatch } from "react-redux";
import CloseBtn from "../../common/CloseBtn/CloseBtn";
import Loading from "../../common/Loading";
import ObjectsService from "../../../services/Objects/ObjectsService";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import MapComponent from "./MapComponent";
import { IObjectMap } from "../../../types/objects";
import { useParams } from "react-router-dom";

enum IMapStatus {
  Success,
  Error,
  Loading,
}

function ObjectsMap() {
  const [mapStatus, setMapStatus] = useState<IMapStatus>(IMapStatus.Loading);
  const [data, setData] = useState<IObjectMap[]>([]);
  const dispatch = useDispatch();
  let { collectionId } = useParams<{ collectionId: string }>();
  const search = useTypedSelector((state) => state.navbar.searchString);

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await ObjectsService.getMapObjects(
          collectionId ? "" : search,
          collectionId
        );
        setMapStatus(IMapStatus.Success);
        return res;
      } catch (e) {
        setMapStatus(IMapStatus.Error);
      }
    };

    loadData().then((res) => {
      if (res) {
        setData(res);
      }
    });

    return () => {
      ObjectsService.abortGetMapObjects();
    };
  }, [search, collectionId]);

  const handleCloseClick = () => {
    dispatch({ type: "HIDE_MAP" });
  };

  return (
    <div className={styles.mapContainer}>
      {mapStatus === IMapStatus.Success && <MapComponent data={data} />}
      {mapStatus === IMapStatus.Loading && (
        <div style={{ marginTop: "200px" }}>
          <Loading />
        </div>
      )}
      {mapStatus === IMapStatus.Error && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <div>We have problems loading data ...</div>
        </div>
      )}
      <CloseBtn handleClick={handleCloseClick} />
    </div>
  );
}

export default ObjectsMap;
