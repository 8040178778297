import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Login from "./components/Login/Login";
import SetUpService from "./services/SetUpService";
import Alerts from "./components/common/Alerts/Alerts";
import ModalDialog from "./components/common/ModalDialog/ModalDialog";
import AuthChecker from "./components/common/AuthChecker";
import ObjectRoutes from "./components/routes/ObjectRoutes";
import ConversationRoutes from "./components/routes/ConversationRoutes";
import VisibilitySchedulesRoutes from "./components/routes/VisibilitySchedulesRoutes";
import AdvertiserRoutes from "./components/routes/AdvertiserRoutes";
import CollectionRoutes from "./components/routes/CollectionRoutes";
import ModalInfoDialog from "./components/common/ModalInfoDialog";
import UserManagementRoutes from "./components/routes/UserManagementRoutes";
import { UserRole, userRolesStorage } from "./types/userManagement";
import { useTypedSelector } from "./hooks/useTypedSelector";

function App() {
  const role = useTypedSelector((store) => store.auth.role);
  const [completeSetUp, setCompleteSetUp] = useState<boolean>(false);

  useEffect(() => {
    SetUpService.axiosSetUp();
    SetUpService.applyUrl();
    setCompleteSetUp(true);
  }, []);

  return completeSetUp ? (
    <>
      <Alerts />
      <ModalDialog />
      <ModalInfoDialog />
      <Router>
        <AuthChecker />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <Navigate
                to={userRolesStorage[role || UserRole.Reader].defaultPath}
                replace
              />
            }
          />
          {ObjectRoutes}
          {VisibilitySchedulesRoutes}
          {CollectionRoutes}
          {AdvertiserRoutes}
          {UserManagementRoutes}
          {ConversationRoutes}
        </Routes>
      </Router>
    </>
  ) : (
    <></>
  );
}

export default App;
