import { IReceiveFileUploadUrl } from "../types/common";
import axios from "axios";


class CommonService {
  getFileUploadUrl = (data: any, getUploadUrl: string) => {
    return axios.post(getUploadUrl, data).then((res) => res.data);
  };

  uploadFile = async (
    getUploadUrl: string,
    file: File,
    data: any,
    returnName: boolean = true
  ): Promise<IReceiveFileUploadUrl> => {
    const mimeType = file.type;
    const uploadData: IReceiveFileUploadUrl = await this.getFileUploadUrl(
      data,
      getUploadUrl
    );

    const config = {
      headers: {
        "Content-Type": mimeType,
      },
    };

    const auth = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];
    await axios.put(uploadData.presignedUploadUrl, file, config);
    axios.defaults.headers.common["Authorization"] = auth;
    return uploadData;
  };
}

export default new CommonService();
