import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styles from "./ObjectCollection.module.css";
import commonStyles from "../../../styles/common.module.css";
import MapWithMarker from "../../common/MapWithMarker";
import ClickableImage from "../../common/ClickableImage/ClickableImage";
import HelperService from "../../../services/HelperService";
import constants from "../../../constants";

interface IProps {
  data: {
    assetsBaseUrl: string;
    imageFile: string;
    extraPhotos: string[];
    location?: { lon: number; lat: number };
    id?: string;
    name?: string;
    displayName?: string;
    details: string;
    link?: { title: string; url: string };
    modelAuthor?: { id: string; displayName: string };
    modelAuthorNote?: string;
  };
}

function PublicInfo({ data }: IProps) {
  const [imageSrc, setImageSrc] = useState<string>("");
  const [extraImageSrc, setExtraImageSrc] = useState<string[]>([]);

  const coordinatesHref = data?.location
    ? constants.GOOGLE_MAP_URL_SEARCH +
      data.location.lat +
      "," +
      data.location.lon
    : "";

  useEffect(() => {
    setImageSrc(
      HelperService.getAssetUrl(data.assetsBaseUrl, data.imageFile, true)
    );
    setExtraImageSrc(
      data.extraPhotos.map((image) =>
        HelperService.getAssetUrl(data.assetsBaseUrl, image)
      )
    );
  }, [data]);

  return (
    <div data-test-id="obj-p-version">
      <div
        className={`d-inline-flex flex-wrap gap-3 align-items-center ${styles.publicTopContainer}`}
      >
        <ClickableImage fluid src={imageSrc} width={160} />
        {data.location && (
          <>
            <div className={styles.mapContainer} data-test-id="obj-p-map">
              <MapWithMarker
                id={data.id || "marker"}
                lon={data.location.lon}
                lat={data.location.lat}
              />
            </div>
            <a
              data-test-id="public-location"
              href={coordinatesHref}
              target="_blank"
              rel="noreferrer"
            >
              {data.location.lat.toFixed(6)}&deg;,{" "}
              {data.location.lon.toFixed(6)}&deg;
            </a>
          </>
        )}
      </div>
      <div className={styles.textBlock}>
        <h4 data-test-id="public-name">
          {data.displayName || data.name || ""}
        </h4>
        <div
          data-test-id="public-details"
          className={commonStyles.whiteSpacePreLine}
        >
          <ReactMarkdown children={data.details} skipHtml={true} />
        </div>
      </div>
      <div className={styles.imagesContainer} data-test-id="obj-p-images">
        {extraImageSrc.map((image, index) => (
          <ClickableImage
            fluid
            key={index + image}
            src={image}
            width={200}
            rounded
          />
        ))}
      </div>
      {data.link && (
        <div className="mt-3">
          <a
            data-test-id="public-link"
            href={data.link.url}
            target="_blank"
            rel="noreferrer"
          >
            {data.link.title}
          </a>
        </div>
      )}
      {data.modelAuthor && (
        <div className={`mt-5 ${styles.textBlock}`}>
          <h6>3D model author</h6>
          <div
            data-test-id="public-modelAuthor"
            className={commonStyles.whiteSpacePreLine}
          >
            {data.modelAuthor.displayName} ({data.modelAuthor.id})
          </div>
        </div>
      )}
      {data.modelAuthorNote && (
        <div className={styles.textBlock}>
          <h6>3D model author personal note</h6>
          <div
            data-test-id="public-modelAuthorNote"
            className={commonStyles.whiteSpacePreLine}
          >
            {data.modelAuthorNote}
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicInfo;
