import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IObjectVersionForm } from "../../../types/objects";
import Images from "../../common/ObjectCollection/Images";
import { IFileWithUrl } from "../../../types/common";
import FormInputLimited from "../../common/FormInputLimited";
import MarkdownSupportComment from "../../common/MarkdownSupportComment";

interface IProps {
  version: IObjectVersionForm;
  versionChange: (name: string, value: any) => void;
  errorValues: string[];
}
function DraftForm({ version, versionChange, errorValues }: IProps) {
  const onFormControlChange = (event: any) => {
    versionChange(event.target.name, event.target.value);
  };

  const handleDeleteImage = (index: number) => {
    version.extraPhotos.splice(index, 1);
    versionChange("extraPhotos", version.extraPhotos);
  };

  const handleAddImage = (fileUrls: IFileWithUrl[]) => {
    versionChange("extraPhotos", version.extraPhotos.concat(fileUrls));
  };

  return (
    <div>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Coordinates</Form.Label>
          <Form.Control
            isInvalid={errorValues.includes("coordinates")}
            data-test-id="obj-editor-coordinates"
            onChange={onFormControlChange}
            name="coordinates"
            type="text"
            value={version.coordinates}
          />
        </Form.Group>
        <FormInputLimited
          isInvalid={errorValues.includes("title")}
          data-test-id="obj-editor-title"
          label="Title"
          onChange={onFormControlChange}
          lengthLimit={80}
          value={version.title}
          type="text"
          name="title"
        />

        <FormInputLimited
          data-test-id="obj-editor-details"
          label="Description"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={version.details}
          name="details"
          as="textarea"
          rows={5}
          comment={MarkdownSupportComment()}
        />

        <Images
          id="extraPhotos"
          images={version.extraPhotos}
          onDeleteImage={handleDeleteImage}
          onAddImage={handleAddImage}
        />

        <Row className="px-2">
          <Col className="px-1">
            <FormInputLimited
              data-test-id="obj-editor-linkTitle"
              label="Title link"
              onChange={onFormControlChange}
              lengthLimit={50}
              value={version.linkTitle}
              type="text"
              name="linkTitle"
            />
          </Col>
          <Col className="px-1">
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                data-test-id="obj-editor-link"
                onChange={onFormControlChange}
                name="link"
                type="text"
                value={version.link}
              />
            </Form.Group>
          </Col>
        </Row>
        <FormInputLimited
          data-test-id="obj-editor-modelAuthor"
          isInvalid={errorValues.includes("modelAuthor")}
          label="3D model author (id or email)"
          onChange={onFormControlChange}
          lengthLimit={80}
          value={version.modelAuthor}
          type="text"
          name="modelAuthor"
        />
        <FormInputLimited
          data-test-id="obj-editor-modelAuthorNote"
          isInvalid={errorValues.includes("modelAuthorNote")}
          label="3D model author personal note"
          onChange={onFormControlChange}
          lengthLimit={300}
          value={version.modelAuthorNote}
          type="text"
          name="modelAuthorNote"
          as="textarea"
          rows={5}
        />
      </Form>
    </div>
  );
}

export default DraftForm;
