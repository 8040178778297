import React, { useEffect, useState } from "react";
import { CollectionDistance, getCollectionDistanceLabel, ICollectionConditions } from "../../../types/objects";
import styles from "./ObjectCollection.module.css";
import ClickableImage from "../../common/ClickableImage/ClickableImage";
import HelperService from "../../../services/HelperService";
import commonStyles from "../../../styles/common.module.css";
import CouponInfo from "../../Advertisers/AdvertiserDetails/Coupons/CouponInfo";
import { IDisplayObject } from "../../../types/common";

interface IProps {
  data: {
    assetsBaseUrl?: string;
    country: IDisplayObject | null;
    region: IDisplayObject | null;
    place: IDisplayObject | null;
    tags: string[];
    notes?: string;
    adminNotes?: string;
    adminPictures?: string[];
    sharingUrl?: string;
    displayTimeZone?: string;
    scheduleId?: null | string;
    sendAnnouncements?: boolean;
    collectionDistance?: CollectionDistance;
    collectionConditions?: ICollectionConditions;
    couponCampaignId?: string | null;
  };
}

function AdminInfo({ data }: IProps) {
  const [geo, setGeo] = useState<string>("");
  const [adminPictures, setAdminPictures] = useState<string[]>([]);

  useEffect(() => {
    const geoString = [];
    if (data.country) geoString.push(data.country.displayName);
    if (data.region) geoString.push(data.region.displayName);
    if (data.place) geoString.push(data.place.displayName);
    setGeo(geoString.join(", "));
    if (data.adminPictures)
      setAdminPictures(
        data.adminPictures.map((image) =>
          HelperService.getAssetUrl(data.assetsBaseUrl || "", image)
        )
      );

  }, [data]);




  return (
    <div>
      <div data-test-id="admin-location" className={styles.tagsBlock}>
        {geo}
      </div>

      {data.collectionDistance && (
        <div className={styles.textBlock}>
          <h6>Collection distance</h6>
          <div data-test-id="collection-distance">{getCollectionDistanceLabel(data.collectionDistance)}</div>
        </div>
      )}

      {data.tags.length !== 0 && (
        <div className={styles.textBlock}>
          <h6>Tags</h6>
          <div data-test-id="admin-tags">{data.tags.join(", ")}</div>
        </div>
      )}

      {data.sendAnnouncements !== undefined && (
        <div className={styles.textBlock}>
          <h6>Announcements</h6>
          <div data-test-id="anouncements">{data.sendAnnouncements ? "Show" : "Suppress"}</div>
        </div>
      )}

      {data.couponCampaignId && (
        <div className={styles.textBlock}>
          <h6>Coupon</h6>
          <div data-test-id="coupon-campaign">
            <CouponInfo couponCampaignId={data.couponCampaignId} />
          </div>
        </div>
      )}

      {(data.notes || data.adminNotes) && (
        <div className={styles.textBlock}>
          <h6>Note</h6>
          <div
            data-test-id="admin-notes"
            className={commonStyles.whiteSpacePreLine}
          >
            {data.notes || data.adminNotes}
          </div>
        </div>
      )}
      <div className={styles.imagesContainer}>
        {adminPictures.map((image) => (
          <ClickableImage fluid key={image} src={image} width={60} rounded />
        ))}
      </div>
      {data.sharingUrl && (
        <div className="mt-3">
          <a
            data-test-id="admin-sharingUrl"
            href={data.sharingUrl}
            target="_blank"
            rel="noreferrer"
          >
            Share link
          </a>
        </div>
      )}


    </div>
  );
}

export default AdminInfo;
