import React from "react";
import { Button, ButtonGroup, Navbar } from "react-bootstrap";
import CSLogo from "../../images/cs-logo-icon.png";
import styles from "./NavbarMain.module.css";
import commonStyles from "../../styles/common.module.css";
import Search from "./Search/Search";
import { Link, useLocation } from "react-router-dom";
import Profile from "./Profile";
import { useDispatch } from "react-redux";
import AddNewButton from "../common/AddNewButton/AddNewButton";
import NavlinkWithQuery from "../common/NavlinkWithQuery";
import {
  INavigation,
  INavigationItem,
  navigation,
} from "../../types/navigation";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import PrivateAccess from "../common/PrivateAccess";
import { UserRole } from "../../types/userManagement";

function NavbarMain({
  search,
  map,
  plusNavigation,
  searchInfo,
  actions
}: INavigationItem) {
  const role = useTypedSelector((store) => store.auth.role);
  const state = useTypedSelector((store) => store);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleViewMapClick = () => {
    dispatch({ type: "SHOW_MAP" });
  };

  return (
    <Navbar bg="white" className={`${styles.navbarContainer} shadow`}>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.navbarMain}`}
        data-test-id="navbar-main"
      >
        <div>
          <Navbar.Brand as={Link} to="/">
            <img src={CSLogo} width="30" height="30" alt="logo" />
          </Navbar.Brand>
          <ButtonGroup size="sm">
            {Object.keys(navigation).map((navKey: any) => {
              const navItem = navigation[navKey as keyof INavigation];
              return (
                role &&
                navItem.roles.indexOf(role) !== -1 && (
                  <Button
                    active={location.pathname === "/" + navItem.pathname}
                    data-test-id={"nav-" + navItem.pathname}
                    key={navKey}
                    variant="light"
                  >
                    <NavlinkWithQuery to={"/" + navItem.pathname}>
                      {navItem.name}
                    </NavlinkWithQuery>
                  </Button>
                )
              );
            })}
          </ButtonGroup>
          <PrivateAccess roles={[UserRole.Editor, UserRole.Admin]}>
            {plusNavigation && <AddNewButton plusNavigation={plusNavigation} />}
          </PrivateAccess>
          {map && (
            <Button
              data-test-id="nav-map"
              variant="link"
              className={commonStyles.noUnderline}
              size="sm"
              onClick={handleViewMapClick}
            >
              View map
            </Button>
          )}
          {
            actions && actions.map((action, i) => (<div className="actions-dropdown" key={i}> {action(state)}</div>))
          }
        </div>
        {search && <Search searchInfo={searchInfo} />}
        <Profile />
      </div>
    </Navbar>
  );
}

export default NavbarMain;
