import React, { useEffect, useState } from "react";
import { IDictionaryName, IDisplayObject } from "../../../types/common";
import styles from "../Object.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import GeonamesService from "../../../services/GeonamesService";

interface IProps {
  country: IDisplayObject | null;
  region: IDisplayObject | null;
  place: IDisplayObject | null;
  geoChange: (values: { name: string; value: any }[]) => void;
}

function GeoDropdowns({ country, region, place, geoChange }: IProps) {
  const [countries, setCountries] = useState<IDictionaryName[]>([]);
  const [regions, setRegions] = useState<IDictionaryName[]>([]);
  const [places, setPlaces] = useState<IDictionaryName[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        return await GeonamesService.getAllCountries();
      } catch {}
    };
    loadData().then((res) => {
      if (res) setCountries(res);
    });
  }, []);

  useEffect(() => {
    const loadData = async (countryId: string) => {
      try {
        return await GeonamesService.getRegionsByCountry(countryId);
      } catch {}
    };
    if (country) {
      loadData(country.id).then((res) => {
        if (res) setRegions(res);
      });
    }
  }, [country]);

  useEffect(() => {
    const loadData = async (regionId: string) => {
      try {
        return await GeonamesService.getPlaceByRegion(regionId);
      } catch {}
    };
    if (region) {
      loadData(region.id).then((res) => {
        if (res) setPlaces(res);
      });
    }
  }, [region]);

  const onSelectCountry = (country: IDictionaryName | null) => {
    geoChange([
      {
        name: "country",
        value: country ? { id: country.id, displayName: country.name } : null,
      },
      { name: "region", value: null },
      { name: "place", value: null },
    ]);
  };

  const onSelectRegion = (region: IDictionaryName | null) => {
    geoChange([
      {
        name: "region",
        value: region ? { id: region.id, displayName: region.name } : null,
      },
      { name: "place", value: null },
    ]);
  };

  const onSelectPlace = (place: IDictionaryName | null) => {
    geoChange([
      {
        name: "place",
        value: place ? { id: place.id, displayName: place.name } : null,
      },
    ]);
  };

  return (
    <div className="d-flex flex-wrap">
      <DropdownButton
        variant="light"
        size="sm"
        title={country ? country.displayName : "Country"}
        className={styles.dropdownGeoContainer}
      >
        <Dropdown.Item
          as={"button"}
          onClick={() => onSelectCountry(null)}
          type="button"
        >
          ---
        </Dropdown.Item>
        {countries.map((country) => (
          <Dropdown.Item
            as={"button"}
            key={country.id}
            onClick={() => onSelectCountry(country)}
            type="button"
          >
            {country.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {country && (
        <DropdownButton
          variant="light"
          size="sm"
          title={region ? region.displayName : "Region"}
          className={styles.dropdownGeoContainer}
        >
          <Dropdown.Item
            as={"button"}
            onClick={() => onSelectRegion(null)}
            type="button"
          >
            ---
          </Dropdown.Item>
          {regions.map((region) => (
            <Dropdown.Item
              as={"button"}
              key={region.id}
              onClick={() => onSelectRegion(region)}
              type="button"
            >
              {region.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}

      {region && (
        <DropdownButton
          variant="light"
          size="sm"
          title={place ? place.displayName : "Place"}
          className={styles.dropdownGeoContainer}
        >
          <Dropdown.Item
            as={"button"}
            onClick={() => onSelectPlace(null)}
            type="button"
          >
            ---
          </Dropdown.Item>
          {places.map((place) => (
            <Dropdown.Item
              as={"button"}
              key={place.id}
              onClick={() => onSelectPlace(place)}
              type="button"
            >
              {place.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
    </div>
  );
}

export default GeoDropdowns;
