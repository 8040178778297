import constants from "../../constants";
import axios from "axios";
import { IObjectAdmin } from "../../types/objects";
import CommonService from "../CommonService";
import { IAttachmentType } from "../../types/common";

let searchTagsController: AbortController | null;

class ObjectAdminService {
  getObjectAdmin = (id: string) => {
    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/Get?id=${id}`;

    return axios.get(url).then((res) => res.data);
  };

  editorSaveObject = async (objectId: string, object: IObjectAdmin) => {
    try {
      const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/UpdateObject`;
      const getUploadUrl = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/GetAdminFileUploadUrl`;

      const adminPictures = [];

      for (const image of object.adminPictures) {
        if (image.file) {
          const uploadResult =  await CommonService.uploadFile(
              getUploadUrl,
              image.file,
              {
                targetId: objectId,
                attachmentType: IAttachmentType.AdminPhoto,
                mimeType: image.file.type,
              })
          adminPictures.push( uploadResult.permanentUrl );
        } else if (image.url) {
          adminPictures.push(image.url);
        }
      }

      const updateData = {
        id: objectId,
        tags: object.tags,
        countryId: object.country ? object.country.id : null,
        regionId: object.region ? object.region.id : null,
        placeId: object.place ? object.place.id : null,
        adminNotes: object.notes,
        adminLinks: [],
        adminPictures,
        scheduleId: object.scheduleId,
        sendAnnouncements: object.sendAnnouncements,
        collectionDistance: object.collectionDistance,
        collectionConditions: object.collectionConditions
      };
      await axios.post(url, updateData).then();

      return true;
    } catch {
      return false;
    }
  };

  getTags = async (searchString: string) => {
    if (searchTagsController) {
      searchTagsController.abort();
    }

    searchTagsController = new AbortController();

    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/GetTags`;

    return axios
      .get(url, {
        params: { searchString },
        signal: searchTagsController.signal,
      })
      .then((res) => res.data);
  };
}

export default new ObjectAdminService();
