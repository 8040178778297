import React from "react";
import styles from "./CloseBtn.module.css";
import { CloseButton } from "react-bootstrap";

interface ICloseBtnProps {
  handleClick: () => void;
}

function CloseBtn({ handleClick }: ICloseBtnProps) {
  return (
    <div className={styles.closeButtonContainer}>
      <CloseButton className={styles.closeButton} onClick={handleClick} />
    </div>
  );
}

export default CloseBtn;
