import { AlertActionTypes, AlertType } from "../../types/alerts";
import { store } from "../index";

class AlertsService {
  addAlert = (text: string, type?: AlertType) => {
    store.dispatch({
      type: AlertActionTypes.ADD_ALERT,
      payload: { text, type },
    });
  };

  deleteAlert = (id: string) => {
    store.dispatch({ type: AlertActionTypes.DELETE_ALERT, payload: id });
  };
}

export default new AlertsService();
