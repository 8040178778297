import React, { useCallback } from "react";
import CSTable from "../../../common/CSTable/CSTable";
import Columns from "./LocationsTableColumns";
import { useNavigate, useParams } from "react-router-dom";
import LocationsService from "../../../../services/Advertisers/LocationsService";

function LocationsList() {
  let { adId } = useParams<{ adId: string }>();
  const columns = Columns();
  const navigate = useNavigate();

  const getData = useCallback(
    () => LocationsService.getLocations(adId),
    [adId]
  );

  const onRowClick = (id: string) => {
    navigate(`${id}`);
  };

  const emptyTable = () => {
    return (
      <div className="d-flex justify-content-center m-5">
        <div>
          Before you can add a deal, you need to define at least one location
        </div>
      </div>
    );
  };

  return (
    <CSTable
      dataTestId="locations-list"
      columns={columns}
      loadDataFunction={getData}
      onRowClick={onRowClick}
      height="calc(100vh - 80px)"
      emptyComponent={emptyTable()}
    />
  );
}

export default LocationsList;
