import React, { useCallback } from "react";
import CSTable from "../../../common/CSTable/CSTable";
import Columns from "./CouponsTableColumns";
import { useNavigate, useParams } from "react-router-dom";
import CouponsService from "../../../../services/Advertisers/CouponsService";

function CouponsList() {
  let { adId } = useParams<{ adId: string }>();
  const columns = Columns();
  const navigate = useNavigate();

  const getData = useCallback(
    () => CouponsService.getCoupons(adId),
    [adId]
  );

  const onRowClick = (id: string) => {
    navigate(`${id}`);
  };

  const emptyTable = () => {
    return (
      <div className="d-flex justify-content-center m-5">
        <div>
            No coupons yet
        </div>
      </div>
    );
  };

  return (
    <CSTable
      dataTestId="coupons-list"
      columns={columns}
      loadDataFunction={getData}
      onRowClick={onRowClick}
      height="calc(100vh - 80px)"
      emptyComponent={emptyTable()}
    />
  );
}

export default CouponsList;
