import React from "react";

function MarkdownSupportComment() {
  return (
    <div>
      Supports{" "}
      <a
        href="https://commonmark.org/help/"
        target="_blank"
        rel="noreferrer"
        className="link-secondary"
      >
        markdown syntax
      </a>
    </div>
  );
}

export default MarkdownSupportComment;
