import React from "react";
import { Modal } from "react-bootstrap";
import { useTypedSelector } from "../../hooks/useTypedSelector";

function ModalInfoDialog() {
  const modalInfo = useTypedSelector((state) => state.modalInfo);
  return (
    <Modal
      show={modalInfo.show}
      centered={modalInfo.centered !== undefined ? modalInfo.centered : true}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Body>{modalInfo.content}</Modal.Body>
    </Modal>
  );
}

export default ModalInfoDialog;
