import React, { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

function AuthChecker() {
  const auth = useTypedSelector((store) => store.auth.isAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth === false) {
      AuthService.clearTokens();
      navigate("/login");
    }
  }, [auth, navigate]);

  return <></>;
}

export default AuthChecker;
