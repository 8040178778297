import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showModal } from "../../../../../redux/action-creators/modal";
import AlertsService from "../../../../../redux/services/AlertsService";
import ModalInfoService from "../../../../../redux/services/ModalInfoService";
import AdUsersService from "../../../../../services/Advertisers/AdUsersService";

import AdvertiserService from "../../../../../services/Advertisers/AdvertiserService";
import {
  IAdUserForm,
  initAdUserForm
} from "../../../../../types/advertisers";
import { AlertType } from "../../../../../types/alerts";
import AdUserEditorForm from "./AdUserEditorForm";
import LoadingWrapper from "../../../../common/LoadingWrapper";
import { IStatus } from "../../../../../types/common";
import styles from "../../../../../styles/details.module.css";

function AdUserEditor() {
  const { adId } = useParams<{ adId: string }>();
  const [advertiserName, setAdvertiserName] = useState<string>("");
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [user, setUser] = useState<IAdUserForm>(initAdUserForm(adId ?? ""));
  const [errorValues, setErrorValues] = useState<string[]>([]);
  
  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdData = async (id: string) => {
      try {
        return await AdvertiserService.getAdvertiser(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };

    if (adId) {
      loadAdData(adId).then((resAd) => {
        if (resAd === undefined) return;

        if(resAd.country?.displayName === "Japan") {
          setUser((prevState) => {
            return { ...prevState, "languageCode": "jp" };;
          });
        }
        
        setAdvertiserName(resAd.name);
        setStatus(IStatus.Success);
      });
    }
  }, [adId]);



  const handleUserChange = useCallback((name: string, value: any) => {
    setHasChanges(true);
    setErrorValues((prevState) =>
      prevState.includes(name)
        ? prevState.filter((er) => er !== name)
        : prevState
    );
    setUser((prevState) => {
      return { ...prevState, [name]: value };;
    });
  }, []);


  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const onSaveClick = () => {
    setErrorValues([]);
    let possible = true;
    if (!user.name) {
      setErrorValues((prevState) => [...prevState, "name"]);
      AlertsService.addAlert("Name field is empty", AlertType.WARNING);
      possible = false;
    }
    if (!user.email) {
      setErrorValues((prevState) => [...prevState, "email"]);
      AlertsService.addAlert("Email field is empty", AlertType.WARNING);
      possible = false;
    } else if (!isValidEmail(user.email)) {
      setErrorValues((prevState) => [...prevState, "email"]);
      AlertsService.addAlert("Email is not valid", AlertType.WARNING);
      possible = false;
    }
  
    if (!possible) return;
    ModalInfoService.showModalInfoLoading();
    AdUsersService.createUser(user).then((res) => {
      if (res) {
        ModalInfoService.closeModalInfo();
        AlertsService.addAlert(
          "User was successfully created",
          AlertType.SUCCESS
        );
        handleBackToList();
      } else {
        ModalInfoService.showModalInfoError("We have problems saving user");
      }
    });
  };

  const onCancelClick = () => {
    hasChanges
      ? dispatch(
          showModal({
            text: "Go back without saving?",
            primaryAction: handleBackToList,
          })
        )
      : handleBackToList();
  };

  const handleBackToList = () => {
    navigate(`/advertisers/${adId}/user-management`);
  };

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
      <LoadingWrapper status={status}>
          <div className={styles.w100EditorContainer}>
            
            <h3 className={styles.editorTitle}>Create a User for '{advertiserName}'</h3>
            <AdUserEditorForm
              user={user}
              userChange={handleUserChange}
              errorValues={errorValues}
            />
          </div>
          </LoadingWrapper>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <Button
          data-test-id="user-editor-cancel"
          variant="light"
          size="sm"
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        <Button
          data-test-id="user-editor-save"
          variant="primary"
          size="sm"
          onClick={onSaveClick}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default AdUserEditor;
