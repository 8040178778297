import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { BiLinkExternal } from "react-icons/bi";
import VisibilitySchedulesService from "../../../services/VisibilitySchedules/VisibilitySchedulesService";
import { IVisibilitySchedule } from "../../../types/visibilitySchedule";

interface IProps {
    value: string | null;
    onChange: (scheduleId: string) => void;
}

function VisibilitySchedulesDropdown({ onChange, value }: IProps) {
    const [input, setInput] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (value === null)
            return;

        VisibilitySchedulesService.getAllSchedules(0, 1000, input).then((res) => {
            setOptions(res.filter((x: IVisibilitySchedule) => !x.isArchived || x.id === value));
            if (value) {
                const initialOption = res.find(
                    (x: IVisibilitySchedule) => x.id === value
                );
                setSelectedOption(initialOption);
            }
        });
    }, [input, value]);

    const onElementChange = (selected: any[]) => {
        if (selected.length > 0) {
            setSelectedOption(selected[0]);
            onChange(selected[0].id);
        }
    };

    return (
        <div>
            
            <Stack direction="horizontal" gap={3}>

                <Typeahead
                    id="schedule-typeahead"
                    labelKey="name" // adjust this to the property of your schedule object you want to display
                    onInputChange={(text) => setInput(text)}
                    options={options}
                    paginate={true}
                    maxResults={10}
                    placeholder="Search for a schedule"
                    onChange={onElementChange}
                    selected={selectedOption ? [selectedOption] : []}
                    style={{minWidth: "300px"}}
                />
                <Button
                    href={`${window.location.origin}/visibilitySchedules/${value}`}
                    target="_blank"
                    disabled={!value}
                >
                    <BiLinkExternal />
                </Button>
            </Stack>

        </div>
    );
}

export default VisibilitySchedulesDropdown;
