import React, { useEffect, useState } from "react";
import styles from "../Object.module.css";
import { Button, ButtonGroup } from "react-bootstrap";
import ObjectAdminService from "../../../services/Objects/ObjectAdminService";
import { useParams } from "react-router-dom";
import { IStatus } from "../../../types/common";
import AdminInfo from "../../common/ObjectCollection/AdminInfo";
import LoadingWrapper from "../../common/LoadingWrapper";
import PrivateAccess from "../../common/PrivateAccess";
import { UserRole } from "../../../types/userManagement";
import Collections from "../Collections/Collections";
import { useDispatch } from "react-redux";
import { setObjCollections, setObjId } from "../../../redux/action-creators/object";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { showModal } from "../../../redux/action-creators/modal";
import CollectionObjectsService from "../../../services/Collections/CollectionObjectsService";
import Conditions from "../../common/ObjectCollection/Conditions";
import { ICollectionConditions } from "../../../types/objects";
import CollectionsManager from "../Collections/CollectionsManager";
import { ICollectionShort } from "../../../types/collections";

interface IProps {
  editAdminInfoClick: () => void;
  editConditionsClick: () => void;
  activeTab?: ActiveTab;
}

export enum ActiveTab {
  Admin = 0,
  Collections = 1,
  Conditions = 2,
}

function ObjectAdmin({ editAdminInfoClick, editConditionsClick, activeTab = ActiveTab.Admin }: IProps) {
  const objectState = useTypedSelector((state) => state.object);
  let { objectId } = useParams<{ objectId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [object, setObject] = useState<any>(null);
  const [collectionsCount, setCollectionsCount] = useState<number>(0);
  const [activeTabState, setActiveTabState] = useState<ActiveTab>(activeTab);
  const [collectionsManager, setCollectionsManager] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        const res = await ObjectAdminService.getObjectAdmin(id);
        setStatus(IStatus.Success);
        return res;
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    const loadCollectionData = async (id: string) => {
      try {
        const res = await CollectionObjectsService.getCollectionsByObject(id);
        return res ? res.length : 0;
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    if (objectId) {
      dispatch(setObjId(objectId));
      loadData(objectId).then((res) => {
        if (res) setObject(res);
      });

      loadCollectionData(objectId).then((res) => {
        if (res) setCollectionsCount(res);
      });
    }
  }, [objectId, dispatch]);

  const renderButtonGroup = () => {
    const onButtonClick = (activeTab: ActiveTab = ActiveTab.Admin) => {
      if (activeTabState === ActiveTab.Collections && activeTab !== activeTabState) {
        onCollectionsTabLeaveClick(activeTab);
      } else {
        setActiveTabState(activeTab);
      }
    };

    const onCollectionsTabLeaveClick = (activeTab: ActiveTab) => {
      objectState.wasChanged
        ? dispatch(
            showModal({
              title: "Unsaved changes",
              text: "There are unsaved changes.\nPlease discard or save them.",
              primaryAction: () => {},
              primaryText: "Ok",
              oneActionMode: true,
            })
          )
        : setActiveTabState(activeTab);
    };

    // Return "true" if schedule or any of collectionConditions defined, excluding question,
    // because on question-answer pair only answer is required on the API level
    const isConditionsDefined = object && (
      object.scheduleId ||
      (object.collectionConditions as ICollectionConditions).maxCollections ||
      (object.collectionConditions as ICollectionConditions).requiredObjectId ||
      (object.collectionConditions as ICollectionConditions).answer
    );

    return (
      <>
        <ButtonGroup size="sm" className="pb-3">
          <Button
            variant="light"
            active={activeTabState === ActiveTab.Admin}
            data-test-id="obj-nav-admin"
            onClick={() => onButtonClick(ActiveTab.Admin)}
          >
            Admin info
          </Button>
          <Button
            variant="light"
            active={activeTabState === ActiveTab.Collections}
            data-test-id="obj-nav-collections"
            onClick={() => onButtonClick(ActiveTab.Collections)}
          >
            Collections <span className={`badge rounded-pill ${collectionsCount > 0 ? 'bg-primary' : 'bg-light text-dark'}`}>{collectionsCount}</span>
          </Button>
          <Button
            variant="light"
            active={activeTabState === ActiveTab.Conditions}
            data-test-id="obj-nav-conditions"
            onClick={() => onButtonClick(ActiveTab.Conditions)}
          >
            Conditions <span className={`badge rounded-pill ${isConditionsDefined ? 'bg-primary' : 'bg-light text-dark'}`}>
              {
                isConditionsDefined ? "Yes" : "No"
              }
              </span>
          </Button>
        </ButtonGroup>
        <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
          <Button
            data-test-id="obj-edit"
            variant="secondary"
            size="sm"
            className={"mb-3 ms-3"}
            onClick={activeTabState === ActiveTab.Admin
                      ? editAdminInfoClick
                      : activeTabState === ActiveTab.Conditions
                        ? editConditionsClick
                        : () => setCollectionsManager(true)}
          >
            Edit
          </Button>
        </PrivateAccess>
      </>
    );
  };

  switch (activeTabState) {
    case ActiveTab.Admin:
      return (
        <>
          <div className={styles.contentContainer}>
            {renderButtonGroup()}
            <h3>Admin info</h3>
            <LoadingWrapper status={status}>
              {object !== null ? <AdminInfo data={object} /> : <div />}
            </LoadingWrapper>
          </div>
        </>
      )
    case ActiveTab.Collections:
      return (
        <>
          <div className="h-100">
            <div style={{ padding: "24px 24px 0", height: 71 }}>
              {renderButtonGroup()}
            </div>
            <Collections />
            {collectionsManager && (
            <CollectionsManager
              closeManager={() => setCollectionsManager(false)}
              onSaveClick={async (collections: ICollectionShort[]) => dispatch(setObjCollections(collections, true))}
              preSelected={objectState.collections}
            />
      )}
          </div>
        </>
      )
    case ActiveTab.Conditions:
      return (
        <>
          <div className="h-100">
            <div style={{ padding: "24px 24px 0", height: 71 }}>
              {renderButtonGroup()}
            </div>
            {object !== null ? <Conditions data={object} /> : <div />}
          </div>
        </>
      )
  }
}

export default ObjectAdmin;
