import React from "react";
import { Table } from "react-bootstrap";
import styles from "../../../styles/table.module.css";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import CSImage from "../../common/CSImage";
import commonStyles from "../../../styles/common.module.css";

function CollectionsTable() {
  const objectState = useTypedSelector((state) => state.object);

  const onRowClick = (id: string) => {
    window.open(window.location.origin + "/collections/" + id, "_blank");
  };

  return (
    <div className="overflow-auto px-4" style={{ height: "calc(100% - 41px)" }}>
      <Table hover bordered data-test-id="object-collections-list">
        <tbody className={styles.tbodyWithCenteredTd}>
          {objectState.collections.map((col) => (
            <tr
              onClick={() => onRowClick(col.id)}
              key={col.id}
              className={commonStyles.pointer}
            >
              <td width="40px" height="41px">
                <CSImage alt="object" width="24px" src={col.iconUrl} />
              </td>
              <td style={{ borderWidth: 0 }}>
                <div className={styles.tdContent}>{col.name}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default CollectionsTable;
