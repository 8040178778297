import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IComment, IConversationTopic } from "../../types/conversations";
import { IStatus } from "../../types/common";
import ConversationsService from "../../services/ConversationsService";
import LoadingWrapper from "../common/LoadingWrapper";
import { Button } from "react-bootstrap";
import { BiUpArrowAlt, BiLinkExternal } from "react-icons/bi";

function Conversation() {

    let { topicId } = useParams<{ topicId: string }>();

    const [status, setStatus] = useState<IStatus>(IStatus.Loading);
    const [conversation, setConversation] = useState<IConversationTopic | null>(null);

    useEffect(() => {
        if (topicId) {
            fetchConversation(topicId);
        }
    }, [topicId]);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const targetCommentId = query.get("commentId");

    const fetchConversation = async (id: string) => {
        try {
            const res = await ConversationsService.getConversationTopic(id);
            if (res) {
                setConversation(res);
                setStatus(IStatus.Success);
            }
        } catch (e) {
            setStatus(IStatus.Error);
        }
    }

    const onCommentDelete = async (id: string) => {
        await ConversationsService.deleteComment(id);
        fetchConversation(topicId!);
    };

    const renderComment = (comment: IComment, index: number) => {
        var createdAt = new Date(comment.createdAt).toLocaleString();
        var wasReported = (comment.id === targetCommentId)
        var blockClass = "p-1 mb-1 bg-light";
        if (wasReported) 
            blockClass += " border border-danger";
        return (
            <div key={index} className={blockClass}>
                <div>
                    <span className="fw-bold">{comment.author.name} ({comment.author.email})</span>
                    <span className="fst-italic"> {createdAt}</span>
                </div>
                <div className="blockquote">
                    <span>{comment.content}</span>
                </div>
                <div>
                    <span hidden={!wasReported}><BiUpArrowAlt /> THIS COMMENT WAS REPORTED <BiUpArrowAlt /> </span>
                    <Button size="sm" variant={wasReported?"danger":"outline-danger"} onClick={() => onCommentDelete(comment.id)}>Delete</Button>

                </div>
            </div>
        );
    };

    const renderRelatedObjectLink = () => {

        var url = `/objects/${conversation?.relatedObject.id}`;
        return (
            <a href={url} target="_blank" rel="noreferrer">{conversation?.relatedObject.displayName} <BiLinkExternal /></a>
        );
    }

    const renderComments = () => {
        if (conversation?.comments.length === 0) {
            return (
                <div>
                    <p>No comments yet</p>
                </div>
            );
        }
        
        return (
            <div>
                {conversation?.comments.map((comment, index) => renderComment(comment, index))}
            </div>
        );

    };

    return (

        <div>
            <LoadingWrapper status={status}>
                <div className="container-sm">
                    <h3>Trophy discussion</h3>
                    <div>
                        <p>{renderRelatedObjectLink()} owned by {conversation?.owner.name} ({conversation?.owner.email})</p>
                    </div>
                    <h4>Comments</h4>

                    {renderComments()}
                </div>

            </LoadingWrapper>

        </div>


    );
}

export default Conversation;