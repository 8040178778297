import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../common/PrivateRoute";
import { UserRole } from "../../types/userManagement";
import Conversation from "../Conversation/Conversation";

const ConversationRoutes = [
  <Route
    key="conversations/:topicId"
    path="conversations/:topicId"
    element={
      <PrivateRoute roles={[UserRole.Admin ]}>
        <Conversation/>
      </PrivateRoute>
    }
  />,
];

export default ConversationRoutes;
