import React from "react";
import { IDealStatus } from "../../types/advertisers";
import styles from "../../styles/common.module.css";

function DealStatus(props: any) {
  let color = "#E9ECEF";
  let textColor = "#212529";
  if (!props.value) {
    return <span></span>;
  }
  switch (props.value) {
    case IDealStatus.Active: {
      color = "#28A745";
      textColor = "#FFFFFF";
      break;
    }
    case IDealStatus.Scheduled: {
      color = "#343A40";
      textColor = "#FFFFFF";
      break;
    }
    case IDealStatus.Draft: {
      color = "#6B7AFF";
      textColor = "#FFFFFF";
      break;
    }
    default:
      break;
  }
  return (
    <div
      className={styles.tag}
      style={{ backgroundColor: color, color: textColor }}
    >
      {props.value}
    </div>
  );
}

export default DealStatus;
