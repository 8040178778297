import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FormInputLimited from "../FormInputLimited";
import { ILink } from "../../../types/common";

interface IProp {
    link: ILink | null;
    testDataIdPrefix?: string;
    layout: 'horizontal' | 'vertical';
    isInvalid?: boolean;
    valueChange: (value: ILink) => void;
}

function ButtonEditor({ link, testDataIdPrefix, layout, isInvalid, valueChange }: IProp) {

    const [urlValue, setUrlValue] = useState<string>(link?.url || '');
    const [titleValue, setTitleValue] = useState<string>(link?.title || '');

    const buildTestDataId = (suffix: string) => {
        if (testDataIdPrefix)
            return `${testDataIdPrefix}-${suffix}`;
        return suffix;
    }

    const onFormControlChange = (event: any) => {
        if (event.target.name === 'title') {
            setTitleValue(event.target.value);
            valueChange({ title: event.target.value, url: urlValue });
        }

        if (event.target.name === 'url') {
            setUrlValue(event.target.value);
            valueChange({ title: titleValue, url: event.target.value });
        }

    }

    const renderTitle = () => (
        <FormInputLimited
            required={Boolean(link?.url)}
            isInvalid={isInvalid}
            data-test-id={buildTestDataId('button-title')}
            label="Button caption"
            onChange={onFormControlChange}
            lengthLimit={30}
            value={link?.title || ''}
            type="text"
            name="title"
        />);

    const renderUrl = () => (
        <FormInputLimited
            required={Boolean(link?.title)}
            isInvalid={isInvalid}
            data-test-id={buildTestDataId('button-url')}
            label="Button link"
            onChange={onFormControlChange}
            lengthLimit={80}
            value={link?.url || ''}
            type="text"
            name="url"
        />);

    if (layout === 'vertical')
        return (
            <Container>
                <Row>
                    <Col>
                        {renderTitle()}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {renderUrl()}
                    </Col>
                </Row>
            </Container>

        );
    else
        return (
            <Container>
                <Row>
                    <Col>
                        {renderTitle()}

                    </Col>
                    <Col>
                        {renderUrl()}
                    </Col>
                </Row>
            </Container>

        );
}

export default ButtonEditor;