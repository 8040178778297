import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "../../common/GoBack";
import styles from "../Collection.module.css";
import CollectionDetailsInfo from "./CollectionDetailsInfo";
import CollectionDetailsObjects from "./Objects/CollectionDetailsObjects";
import { IStatus } from "../../../types/common";
import { ICollectionDetails } from "../../../types/collections";
import CollectionsService from "../../../services/Collections/CollectionsService";
import ObjectsMap from "../../Objects/ObjectsMap/ObjectsMap";
import { useDispatch } from "react-redux";
import {
  clearColStore,
  setColId,
  setColStatus,
} from "../../../redux/action-creators/collection";
import LoadingWrapper from "../../common/LoadingWrapper";
import { showModal } from "../../../redux/action-creators/modal";

function CollectionDetails() {
  let { collectionId } = useParams<{ collectionId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [collection, setCollection] = useState<ICollectionDetails | null>(null);
  const search = useTypedSelector((state) => state.navbar.searchString);
  const showMap = useTypedSelector((state) => state.navbar.map);
  const colWasChanged = useTypedSelector(
    (state) => state.collection.wasChanged
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onGoBackClick = () => {
    colWasChanged
      ? dispatch(
          showModal({
            title: "Unsaved changes",
            text: "There are unsaved changes in objects list.\nPlease discard or publish them before closing.",
            primaryAction: () => {},
            primaryText: "Ok",
            oneActionMode: true,
          })
        )
      : handleGoBack();
  };

  const handleGoBack = () => {
    navigate(search.length ? "/collections?search=" + search : "/collections");
  };

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        return await CollectionsService.getCollection(id);
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };
    if (collectionId) {
      dispatch(setColId(collectionId));
      loadData(collectionId).then((res) => {
        if (res) {
          setCollection(res);
          setStatus(IStatus.Success);
          dispatch(setColStatus(res.status));
        }
      });
      return () => {
        dispatch(clearColStore());
      };
    }
  }, [collectionId, dispatch]);

  return (
    <>
      {showMap && <ObjectsMap />}
      <GoBack handleGoBack={onGoBackClick} text={"Back to Collections list"} />
      <LoadingWrapper status={status}>
        <div className={`d-flex align-items-stretch ${styles.container}`}>
          {collection && <CollectionDetailsInfo collection={collection} />}
          <CollectionDetailsObjects />
        </div>
      </LoadingWrapper>
    </>
  );
}

export default CollectionDetails;
