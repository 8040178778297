import React, { useEffect, useState } from "react";
import { ICouponCampaignDisplay } from "../../../../types/coupons";
import { IStatus } from "../../../../types/common";
import CouponsService from "../../../../services/Advertisers/CouponsService";
import LoadingWrapper from "../../../common/LoadingWrapper";
import { BiLinkExternal } from "react-icons/bi";

interface IProp {
  couponCampaignId: string | null;
}
function CouponInfo({ couponCampaignId }: IProp) {

    const [coupon, setCoupon] = useState<ICouponCampaignDisplay|null>(null);
   

    useEffect(() => {
        // load coupon by id
        if (couponCampaignId) {

            CouponsService.getCoupon(couponCampaignId).then((coupon) => {
                setCoupon(coupon);
            });
        }
    }, [couponCampaignId]);

    if(couponCampaignId === null) 
      return <></>;

    return (
        <div>
            <LoadingWrapper status={coupon? IStatus.Success : IStatus.Loading}>
                <span>
                  {coupon?.advertiser.displayName} | {coupon?.name} | {coupon?.status} from {coupon?.startDisplayOn} to {coupon?.endDisplayOn} 
                  <a className="ms-2"
                    href={`${window.location.origin}/advertisers/${coupon?.advertiser.id}/coupons/${coupon?.id}`}
                    rel="noreferrer"
                    target="_blank" >
                    <BiLinkExternal />
                  </a>
                </span>
            </LoadingWrapper>

        </div>
    );
}

export default CouponInfo;
