import { IComponentCellProps, ITableColumn } from "../../../types/common";
import styles from "../../../styles/common.module.css";
import React from "react";
import { userRolesStorage, UserRoleType } from "../../../types/userManagement";

function Columns(): ITableColumn[] {
  return [
    {
      id: "name",
      displayName: "Name",
      width: "calc(50vw - 250px)",
      minWidth: "150px",
      pattern: (v) => v || "",
    },
    {
      id: "email",
      displayName: "Email",
      width: "calc(50vw - 250px)",
      minWidth: "150px",
    },
    {
      id: "role",
      displayName: "Access level",
      width: "160px",
      pattern: (v) => userRolesStorage[v as keyof UserRoleType].name,
    },
    {
      id: "suspended",
      displayName: "Status",
      width: "100px",
      component: StatusCell,
    },
    {
      id: "lastLoginTimestamp",
      displayName: "Last login date",
      width: "150px",
      pattern: getDisplayDate,
    },
  ];
}

function getDisplayDate(v: string) {
  return v
    ? new Date(v).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : "";
}

function StatusCell(props: IComponentCellProps) {
  return (
    <div
      className={styles.tag}
      style={{
        backgroundColor: props.value ? "#E9ECEF" : "#28A745",
        color: props.value ? "#212529" : "#FFFFFF",
      }}
    >
      {props.value ? "Suspended" : "Active"}
    </div>
  );
}

export default Columns;
