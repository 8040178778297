import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../../styles/details.module.css";
import { Button } from "react-bootstrap";
import { IAdvertiserListItem, initSponsorshipForm, ISponsorshipForm } from "../../../../types/advertisers";
import AdvertiserService from "../../../../services/Advertisers/AdvertiserService";
import { AlertType } from "../../../../types/alerts";
import ModalInfoService from "../../../../redux/services/ModalInfoService";
import AlertsService from "../../../../redux/services/AlertsService";
import SponsorshipEditorForm from "./SponsorshipEditorForm";
import SponsorshipService from "../../../../services/Advertisers/SponsorshipService";

function SponsorshipEditor() {
  const [sponsorship, setSponsorship] = useState<ISponsorshipForm>(initSponsorshipForm);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  let { adId } = useParams<{ adId: string }>();
  const [tz, setTz] = useState<string>("UTC");
  const [advertizer, setAdvertizer] = useState<IAdvertiserListItem>();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdData = async (id: string) => {
        let advertizer = await AdvertiserService.getAdvertiser(id);
        setAdvertizer(advertizer);
        return advertizer;
    };


    if (adId) {
      loadAdData(adId).then((resAd) => {
        if (resAd === undefined) return;
        setTz(resAd.displayTimeZone || "UTC");
        setSponsorship({
          ...initSponsorshipForm,
          advertiserId:  adId || "",
        });
   
      });
    }
  }, [adId]);


  const onSaveClick = async () => {
    if (await possibleToSave(sponsorship)) {
      ModalInfoService.showModalInfoLoading();
      SponsorshipService.createSponsorship(sponsorship)
      .then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          handleBackToAdInfo();
        } else {
          ModalInfoService.showModalInfoError(
            "We have problems saving sponsorship data"
          );
        }
      });
    }
  };

  const possibleToSave = async (sponsorship: ISponsorshipForm): Promise<boolean> => {
    setErrorValues([]);
    let possible: boolean = true;
    if (!sponsorship.mapObjectId) {
      saveProblemAlert("Please enter a valid object ID");
      setErrorValues((prevState) => [...prevState, "mapObjectId"]);
      possible = false;
    }

    if(sponsorship.expiresOn<=sponsorship.startsOn){
      saveProblemAlert("Expires on must be after Starts on");
      setErrorValues((prevState) => [...prevState, "expiresOn"]);
      possible = false;
    }

    return possible;
  };

  const saveProblemAlert = (text: string) => {
    AlertsService.addAlert(text, AlertType.WARNING);
  };

  const onCancelClick = () => {
    handleBackToAdInfo();
  };

  const handleBackToAdInfo = () => {
    navigate(`/advertisers/${adId}/sponsorship`);
  };

  const handleSponsorshipChange = (name: string, value: any) => {
    errorValues.includes(name) &&
      setErrorValues((prevState) => prevState.filter((er) => er !== name));
    setSponsorship((prevState) =>
      prevState ? { ...prevState, [name]: value } : initSponsorshipForm
    );
  };

  return (
    <div className={styles.editorContainer}>
      
      <div className={`d-flex justify-content-start ${styles.editorContent}`}>
          <>
          
            <div className={styles.w50EditorContainer}>
            <h1>Create new sponsorship for {advertizer?.name}</h1>
              <SponsorshipEditorForm
                  value={sponsorship}
                  tz={tz}
                  valueChange={handleSponsorshipChange}
                  errorValues={errorValues}
                /> 
            </div>
          </>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <div>
          <Button
            data-test-id="sponsorship-editor-cancel"
            variant="light"
            size="sm"
            onClick={onCancelClick}
          >
            Cancel
          </Button>
        </div>
        <div>
            <Button
              data-test-id="location-editor-save"
              variant="primary"
              size="sm"
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>
      </div>
    </div>
  );
}

export default SponsorshipEditor;
