import React from "react";

import { BiPlusCircle } from "react-icons/bi";
import ClickableImage from "../ClickableImage/ClickableImage";
import { Form } from "react-bootstrap";
import commonStyles from "../../../styles/common.module.css";
import styles from "./ImagePicker.module.css";

interface IProps {
  valueChange?: (name: string, value: string | ArrayBuffer | null) => void;
  caption: string;
  imageUrl: string|null;
  hasError: boolean;
  width: number;
  height: number;
  imageResize?: boolean;
  imageRecommendedWidth: number;
  imageRecommendedHeight: number;
  supportJpg: boolean;
  supportPng: boolean;
  name: string;
  [propName: string]: any;
}

function ImagePicker({ valueChange, imageUrl, hasError, width, height, caption, name, imageRecommendedHeight, imageRecommendedWidth, imageResize, ...props }: IProps) {

  const handleInputFileChange = (event: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => {
      resizeImage(reader.result as string, imageRecommendedWidth, imageRecommendedHeight).then((resizedImage) => {
        if (valueChange) valueChange(name, resizedImage);
      });
    };
  };

  const handleDeleteImageClick = () => {
    if(valueChange)
      valueChange(name, null);
  };

  const resizeImage = (src: string, maxWidth: number, maxHeight: number): Promise<string> => {
    if(!imageResize)
      return new Promise((resolve) => { resolve(src); });

    return new Promise((resolve) => {
      let img = new Image();
      img.src = src;
  
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        let width = img.width;
        let height = img.height;
  
        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;  
            height = maxHeight;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
        ctx!.drawImage(img, 0, 0, width, height);
  
        resolve(canvas.toDataURL("image/jpeg"));
      };
    });
  };
  

  var acceptMimeTypes = [];
  var acceptExtensions = [];
  if(props.supportJpg) {
    acceptMimeTypes.push('image/jpeg');
    acceptExtensions.push('JPG');
  }
  if(props.supportPng){
    acceptMimeTypes.push('image/png');
    acceptExtensions.push('PNG');
  }

  if(imageUrl)

    return (
      <div className="d-flex justify-content-center">
        
      <ClickableImage
        style={{ objectFit: "cover", borderRadius: "18px", maxWidth: width, maxHeight: height }}
        alt="coupon"
        width="auto"
        height="auto"
        src={imageUrl}
        deleteClick={handleDeleteImageClick}
      /></div>
    );
  else {
    return (
      <div className="d-flex justify-content-center">
    
        <Form.Group controlId={name} data-test-id={"image-picker-" + name} >
          <Form.Label className="mb-0">
            <div
                style={{ width: width, height}}
                className={`${styles.addImage} ${hasError && commonStyles.errorBorder
                }`}
            
            >
              <BiPlusCircle />
              <div className="ms-4">
                <div>{caption}</div>
                <div>
                  <i>{acceptExtensions.join(' / ')} {imageRecommendedWidth}x{imageRecommendedHeight} pix</i>
                </div>
              </div>
            </div>
          </Form.Label>
          <Form.Control
            type="file"
            className="d-none"
            multiple
            accept={acceptMimeTypes.join(', ')}
            onChange={handleInputFileChange}
          />
        </Form.Group>
    </div>);
  }


}

export default ImagePicker;
