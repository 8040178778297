import React, { Suspense } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

interface IProps {
  scale: number;
  rotation: number;
  verticalRotation: number;
  model: string;
}

const deg2rad = (degrees: number) => degrees * (Math.PI / 180);

function Scene({ model, rotation, verticalRotation, scale }: IProps) {
  let gltf = useLoader(GLTFLoader, model);

  return (
    <Suspense fallback={null}>
      <mesh rotation={[deg2rad(verticalRotation), deg2rad(rotation), 0]}>
        <primitive object={gltf.scene} scale={scale} />
      </mesh>
    </Suspense>
  );
}

export default Scene;
