import React, { useEffect, useState } from "react";
import VisibilitySchedulesService from "../../../services/VisibilitySchedules/VisibilitySchedulesService";
import { IVisibilitySchedule, getDisplayDateTime } from "../../../types/visibilitySchedule";

interface IProps {
    value?: string | null;
}

function VisibilityScheduleView({ value }: IProps) {

    const [schedule, setSchedule] = useState<IVisibilitySchedule | null>(null);

    useEffect(() => {
        if (!value)
            return;

        VisibilitySchedulesService.getSchedule(value).then((res) => {
            setSchedule(res);
        });
    }, [value]);


    if(!value)
        return <div>Always visible</div>;
    else if(schedule === null)
        return <div>Loading...</div>;
    else
        return (
            <div>
            <strong>{schedule.name}</strong>
            <span> for {schedule.displayTimeZone}</span>
            <ul>
                { schedule.records.map((record, index) => (
                    <li key={index}>
                        {getDisplayDateTime(record.startOn, schedule.displayTimeZone)}
                        <span> - {!record.visible ? "hidden" : (record.collectable ? "collectable" : "visible")}</span>
                    </li>
                )) }
            </ul>

        </div>
        );



}

export default VisibilityScheduleView;
