import React from "react";
import styles from "../Collection.module.css";
import detailStyles from "../../../styles/details.module.css";
import { Button } from "react-bootstrap";
import PublicInfo from "../../common/ObjectCollection/PublicInfo";
import AdminInfo from "../../common/ObjectCollection/AdminInfo";
import {
  ICollectionDetails,
  ICollectionState,
} from "../../../types/collections";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { showModal } from "../../../redux/action-creators/modal";
import { useDispatch } from "react-redux";
import CollectionsService from "../../../services/Collections/CollectionsService";
import { setColStatus } from "../../../redux/action-creators/collection";
import { AlertType } from "../../../types/alerts";
import AlertsService from "../../../redux/services/AlertsService";
import HelperService from "../../../services/HelperService";
import PrivateAccess from "../../common/PrivateAccess";
import { UserRole } from "../../../types/userManagement";
import { IObjectState } from "../../../types/objects";
import ModalInfoService from "../../../redux/services/ModalInfoService";

interface IProps {
  collection: ICollectionDetails;
}
function CollectionDetailsInfo({ collection }: IProps) {
  const navigate = useNavigate();
  const collectionState = useTypedSelector((state) => state.collection);
  const search = useTypedSelector((state) => state.navbar.searchString);
  const dispatch = useDispatch();

  const onEditClick = () => {
    navigate("edit");
  };

  const onArchiveClick = async () => {
    dispatch(
      showModal({
        title: "Archive",
        text: "This will keep the collection in the list but remove from all users that are have not yet collected it. You can publish it again later. Proceed?",
        primaryAction: archive,
        primaryText: "Archive collection",
        secondaryText: "Cancel",
      })
    );
  };

  const archive = async () => {
    const archived = await CollectionsService.archiveCollection(collection.id);
    if (archived) {
      dispatch(setColStatus(ICollectionState.Archived));
      AlertsService.addAlert(
        "Collection was successfully archived",
        AlertType.SUCCESS
      );
    }
  };

  const onPublishClick = async () => {
    ModalInfoService.showModalInfoLoading();
    let problems: string[] = [];
    let possible: boolean = true;
    if (!collection.name) {
      problems.push("Collection title is empty");
      possible = false;
    }
    if (!collection.details) {
      problems.push("Collection description is empty");
      possible = false;
    }
    let publishedObjectsLength = 0;
    collectionState.objects.forEach((obj) =>
      obj.status === IObjectState.Published ? publishedObjectsLength++ : null
    );
    if (publishedObjectsLength < 2) {
      problems.push("Collection should have at least 2 published objects");
      possible = false;
    }
    if (
      !(
        collection.imageFile &&
        (await HelperService.isFileExist(
          HelperService.getAssetUrl(
            collection.assetsBaseUrl,
            collection.imageFile
          )
        ))
      )
    ) {
      problems.push("Collection image should be set");
      possible = false;
    }
    ModalInfoService.closeModalInfo();
    dispatch(
      possible
        ? showModal({
            title: "Publish",
            text: "When collection is published, it will become available for all users. Continue?",
            primaryAction: publish,
            primaryText: "Publish",
            secondaryText: "Cancel",
          })
        : showModal({
            title: "Unable to publish",
            text:
              "Unable to publish collection due to several problems:\n" +
              problems.map((p) => "- " + p + "").join("\n"),
            primaryAction: () => {},
            primaryText: "Ok",
            oneActionMode: true,
          })
    );
  };

  const publish = async () => {
    const published = await CollectionsService.publishCollection(collection.id);
    if (published) {
      dispatch(setColStatus(ICollectionState.Published));
      AlertsService.addAlert(
        "Collection was successfully published",
        AlertType.SUCCESS
      );
    }
  };

  const onDeleteClick = async () => {
    dispatch(
      showModal({
        title: "Delete",
        text: "Delete this draft collection? This cannot be undone.",
        primaryAction: deleteCollection,
        primaryText: "Delete collection",
        secondaryText: "Cancel",
      })
    );
  };

  const deleteCollection = async () => {
    if (await CollectionsService.deleteCollection(collection.id)) {
      navigate(
        search.length ? "/collections?search=" + search : "/collections"
      );
    }
  };

  return (
    <div>
      <div className={styles.contentContainer}>
        {collection !== null && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Public</h3>
              {collection.statistic && (
                <div className="small mb-2">
                  {collection.statistic.started} started /&nbsp;
                  {collection.statistic.completed} finished
                </div>
              )}
            </div>
            <PublicInfo data={collection} />
            <h3 className="mt-5">Admin info</h3>
            <AdminInfo data={collection} />
          </>
        )}
      </div>
      <div className={detailStyles.buttonContainer}>
        <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
          {collectionState.status === ICollectionState.Draft && (
            <Button
              data-test-id="col-delete"
              variant="danger"
              size="sm"
              onClick={onDeleteClick}
            >
              Delete
            </Button>
          )}
          {collectionState.status === ICollectionState.Published && (
            <Button
              data-test-id="col-archive"
              onClick={onArchiveClick}
              variant="light"
              size="sm"
            >
              Archive
            </Button>
          )}

          <Button
            data-test-id="col-edit"
            variant="secondary"
            size="sm"
            onClick={onEditClick}
          >
            Edit
          </Button>
          {collectionState.status !== ICollectionState.Published && (
            <Button
              disabled={collectionState.wasChanged}
              data-test-id="col-publish"
              variant="primary"
              size="sm"
              onClick={onPublishClick}
            >
              Publish
            </Button>
          )}
        </PrivateAccess>
      </div>
    </div>
  );
}

export default CollectionDetailsInfo;
