import React from "react";
import { Button, Form } from "react-bootstrap";
import styles from "../Object.module.css";
import {
  BiDownArrowAlt,
  BiLeftArrowAlt,
  BiRightArrowAlt,
  BiUpArrowAlt,
} from "react-icons/bi";
import { envPresets, envPresetType } from "../../../types/objects";

interface IProps {
  canvasWidth: number;
  zoom: [value: number, onChange: (e: any) => void];
  exposure: [value: number, onChange: (e: any) => void];
  preset: [value: envPresetType, onChange: (e: any) => void];
  onRotation: Function;
  onVerticalRotation: Function;
}
function ObjectModelControls({
  canvasWidth,
  zoom,
  exposure,
  preset,
  onRotation,
  onVerticalRotation,
}: IProps) {
  return (
    <>
      <div
        className="position-absolute d-flex justify-content-between flex-wrap w-100"
        style={{
          left: 0,
          bottom: 0,
          backgroundColor: "rgba(255,255,255,0.8)",
        }}
      >
        <div className="d-flex align-items-center" data-test-id="mdl-zoom">
          <small className="me-2 text-sm-center">Scale</small>
          <Form.Range
            style={{ width: 100 }}
            onChange={zoom[1]}
            step="0.05"
            value={zoom[0]}
            max="3"
            min="0.1"
          />
        </div>
        <div className="d-flex align-items-center" data-test-id="mdl-exposure">
          <small className="me-2">Exposure</small>
          <Form.Range
            style={{ width: 100 }}
            onChange={exposure[1]}
            step="0.08"
            value={exposure[0]}
            max="2"
            min="0.08"
          />
        </div>
        <div className="d-flex align-items-center">
          <small className="me-2">Environment</small>
          <Form.Select
            size={"sm"}
            onChange={preset[1]}
            value={preset[0]}
            data-test-id="mdl-env"
          >
            {envPresets.map((preset) => (
              <option key={preset || "bridge"} value={preset}>
                {preset || "bridge"}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <Button
        data-test-id="mdl-rot-right"
        variant="light"
        className={`position-absolute ${styles.showOnParentHover}`}
        style={{
          right: "calc(50% - " + canvasWidth / 2 + "px - 44px)",
          top: "calc(50% - 20px)",
        }}
        onClick={() => onRotation()}
      >
        <BiRightArrowAlt />
      </Button>
      <Button
        data-test-id="mdl-rot-left"
        variant="light"
        className={`position-absolute ${styles.showOnParentHover}`}
        style={{
          left: "calc(50% - " + canvasWidth / 2 + "px - 44px)",
          top: "calc(50% - 20px)",
        }}
        onClick={() => onRotation(-1)}
      >
        <BiLeftArrowAlt />
      </Button>
      <Button
        data-test-id="mdl-rot-up"
        variant="light"
        className={`position-absolute ${styles.showOnParentHover}`}
        style={{
          left: "calc(50% - 21px)",
          top: "calc(50% - " + canvasWidth / 2 + "px - 40px)",
        }}
        onClick={() => onVerticalRotation(-1)}
      >
        <BiUpArrowAlt />
      </Button>
      <Button
        data-test-id="mdl-rot-down"
        variant="light"
        className={`position-absolute ${styles.showOnParentHover}`}
        style={{
          left: "calc(50% - 21px)",
          bottom: "calc(50% - " + canvasWidth / 2 + "px - 40px)",
        }}
        onClick={() => onVerticalRotation(1)}
      >
        <BiDownArrowAlt />
      </Button>
    </>
  );
}

export default ObjectModelControls;
