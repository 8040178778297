import React, { useEffect, useState } from "react";
import styles from "../../../styles/details.module.css";
import { IStatus } from "../../../types/common";
import { Button } from "react-bootstrap";
import { showModal } from "../../../redux/action-creators/modal";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ICollectionEditor,
  ICollectionState,
  initColItem,
} from "../../../types/collections";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import CollectionEditorForm from "./CollectionEditorForm";
import CollectionEditorImage from "./CollectionEditorImage";
import { AlertType } from "../../../types/alerts";
import CollectionsService from "../../../services/Collections/CollectionsService";
import HelperService from "../../../services/HelperService";
import LoadingWrapper from "../../common/LoadingWrapper";
import ModalInfoService from "../../../redux/services/ModalInfoService";
import AlertsService from "../../../redux/services/AlertsService";

function CollectionEditor() {
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [collection, setCollection] = useState<ICollectionEditor>(initColItem);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  let { collectionId } = useParams<{ collectionId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useTypedSelector((state) => state.navbar.searchString);

  useEffect(() => {
    const loadColData = async (id: string) => {
      try {
        return await CollectionsService.getCollection(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    collectionId
      ? loadColData(collectionId).then((res) => {
          if (res) generateCollectionForm(res);
        })
      : setStatus(IStatus.Success);
  }, [collectionId]);

  const generateCollectionForm = async (col: any) => {
    const imageFile = HelperService.getAssetUrl(
      col.assetsBaseUrl,
      col.imageFile
    );
    setCollection({
      ...col,
      extraPhotos: col.extraPhotos
        ? col.extraPhotos.map((img: string) => ({
            url: HelperService.getAssetUrl(col.assetsBaseUrl, img),
            name: img,
          }))
        : [],
      adminPictures: col.adminPictures
        ? col.adminPictures.map((img: string) => ({
            url: HelperService.getAssetUrl(col.assetsBaseUrl, img),
            name: img,
          }))
        : [],
      imageFile: (await HelperService.isFileExist(imageFile))
        ? { url: imageFile + "?ts=" + Date.now() }
        : null,
      link: col.link.url,
      linkTitle: col.link.title,
    } as ICollectionEditor);
    setStatus(IStatus.Success);
  };

  const handleCollectionChange = (values: { name: string; value: any }[]) => {
    setHasChanges(true);
    const newCollection = { ...collection };
    values.forEach((v) => {
      newCollection[v.name as keyof typeof collection] = v.value;
      errorValues.includes(v.name) &&
        setErrorValues((prevState) => prevState.filter((er) => er !== v.name));
    });
    setCollection(newCollection);
  };

  const onSaveClick = async () => {
    setErrorValues([]);
    let unableToSave = false;
    if (collection.name.length === 0) {
      setErrorValues((prevState) => [...prevState, "name"]);
      unableToSave = true;
      unableToSaveAlert("Title field is empty");
    }
    if (collection.status !== ICollectionState.Draft) {
      if (!collection.details) {
        setErrorValues((prevState) => [...prevState, "details"]);
        unableToSave = true;
        unableToSaveAlert("Description field is empty");
      }
      if (
        !collection.imageFile ||
        !(await HelperService.isFileExist(collection.imageFile.url))
      ) {
        unableToSave = true;
        unableToSaveAlert("Collection image is not set");
      }
    }
    if (unableToSave) return;
    ModalInfoService.showModalInfoLoading();
    handleSaveObject();
  };

  const unableToSaveAlert = (text: string) =>
    AlertsService.addAlert(text, AlertType.WARNING);

  const handleSaveObject = () => {
    (collectionId
      ? CollectionsService.editorUpdateCollection(collectionId, collection)
      : CollectionsService.editorCreateCollection(collection)
    ).then((res) => {
      if (res) {
        ModalInfoService.closeModalInfo();
        navigate(`/collections/${res}`);
      } else {
        ModalInfoService.showModalInfoError(
          "We have problems saving collection"
        );
      }
    });
  };

  const onCancelClick = () => {
    const action = collectionId ? handleBackToInfo : handleBackToList;
    hasChanges
      ? dispatch(
          showModal({
            text: "Go back without saving?",
            primaryAction: action,
          })
        )
      : action();
  };

  const handleBackToList = () => {
    navigate(search.length ? "/collections?search=" + search : "/collections");
  };

  const handleBackToInfo = () => {
    navigate(`/collections/${collectionId}`);
  };

  return (
    <div className={styles.editorContainer} data-test-id="col-editor">
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <>
            <div className={styles.w50EditorContainer}>
              <CollectionEditorForm
                collection={collection}
                collectionChange={handleCollectionChange}
                errorValues={errorValues}
              />
            </div>
            <div className={styles.w50EditorContainer}>
              <CollectionEditorImage
                image={collection.imageFile}
                collectionChange={handleCollectionChange}
              />
            </div>
          </>
        </LoadingWrapper>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <Button
          data-test-id="col-editor-cancel"
          variant="light"
          size="sm"
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        <Button
          data-test-id="col-editor-save"
          variant="primary"
          size="sm"
          onClick={onSaveClick}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default CollectionEditor;
