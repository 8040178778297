import React, { useEffect, useState } from "react";
import styles from "../Object.module.css";
import { Button } from "react-bootstrap";
import DraftForm from "./DraftForm";
import ObjectPublicService from "../../../services/Objects/ObjectPublicService";
import { IStatus } from "../../../types/common";
import Loading from "../../common/Loading";
import {
  initObjectVersionForm,
  IObjectModel,
  IObjectVersionForm,
} from "../../../types/objects";
import DraftModel from "./DraftModel";
import { showModal } from "../../../redux/action-creators/modal";
import { AlertType } from "../../../types/alerts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HelperService from "../../../services/HelperService";
import ObjectEditorService from "../../../services/Objects/ObjectEditorService";
import AlertsService from "../../../redux/services/AlertsService";
import LoadingWrapper from "../../common/LoadingWrapper";
import ModalInfoService from "../../../redux/services/ModalInfoService";
import CollectorsService from "../../../services/CollectorsService";

interface IProps {
  isNewVersion?: boolean;
  versionId?: string | null;
  backToInfo: () => void;
  backToList?: () => void;
}

function Draft({
  versionId,
  backToInfo,
  backToList = () => {},
  isNewVersion = true,
}: IProps) {
  const [status, setStatus] = useState<IStatus>(
    versionId ? IStatus.Loading : IStatus.Success
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [version, setVersion] = useState<IObjectVersionForm>(
    initObjectVersionForm
  );
  const [model, setModel] = useState<IObjectModel>({});
  const [modelIsReady, setModelIsReady] = useState<boolean>(false);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        const res = await ObjectPublicService.getVersion(id);
        setStatus(IStatus.Success);
        return res;
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    if (versionId) {
      loadData(versionId).then((res) => {
        if (res) {
          setVersion(res.version);
          setModelFiles(res.versionFiles);
        }
      });
    } else {
      setModelIsReady(true);
    }
  }, [versionId]);

  const setModelFiles = async ({ model, image, icon }: any) => {
    const modelObj: IObjectModel = { rotation: 0 };
    if (await HelperService.isFileExist(model)) modelObj.model = { url: model };
    if (await HelperService.isFileExist(image)) modelObj.image = { url: image };
    if (await HelperService.isFileExist(icon)) modelObj.icon = { url: icon };
    setModel(modelObj);
    setModelIsReady(true);
  };

  const handleVersionChange = (name: string, value: any) => {
    setHasChanges(true);
    errorValues.includes(name) &&
      setErrorValues((prevState) => prevState.filter((er) => er !== name));
    setVersion((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleModelChange = (values: { name: string; value: any }[]) => {
    setHasChanges(true);
    const newModel = { ...model };
    values.forEach((v) => (newModel[v.name as keyof typeof model] = v.value));
    setModel(newModel);
  };

  const onSaveClick = async () => {
    ModalInfoService.showModalInfoLoading();
    const { possible, modelAuthorId } = await possibleToSave(version);
    if (possible) {
      versionId
        ? handleSaveVersion(versionId, modelAuthorId)
        : handleCreateObject(modelAuthorId);
    } else {
      ModalInfoService.closeModalInfo();
    }
  };

  const possibleToSave = async (version: IObjectVersionForm) => {
    setErrorValues([]);
    let possible = true;
    let modelAuthorId = "";
    if (!HelperService.isGeoStringValid(version.coordinates)) {
      setErrorValues((prevState) => [...prevState, "coordinates"]);
      AlertsService.addAlert("Coordinates are not valid", AlertType.WARNING);
      possible = false;
    }
    if (version.title.length === 0) {
      setErrorValues((prevState) => [...prevState, "title"]);
      AlertsService.addAlert("Title field is empty", AlertType.WARNING);
      possible = false;
    }
    if (version.modelAuthor) {
      await (version.modelAuthor.includes("@")
        ? CollectorsService.getCollectorByEmail(version.modelAuthor)
        : CollectorsService.getCollectorById(version.modelAuthor)
      ).then((res) => {
        if (res) {
          modelAuthorId = res.id;
        } else {
          setErrorValues((prevState) => [...prevState, "modelAuthor"]);
          AlertsService.addAlert("Model author not found", AlertType.WARNING);
          possible = false;
        }
      });
    }
    if (version.modelAuthorNote && !version.modelAuthor) {
      setErrorValues((prevState) => [
        ...prevState,
        "modelAuthorNote",
        "modelAuthor",
      ]);
      AlertsService.addAlert(
        "Author personal note won't be saved without author",
        AlertType.WARNING
      );
      possible = false;
    }
    return { possible, modelAuthorId };
  };

  const handleSaveVersion = (id: string, modelAuthorId: string) => {
    ObjectEditorService.editorSaveVersion(
      id,
      version,
      model,
      modelAuthorId,
      isNewVersion
    )
      .then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          backToInfo();
        } else {
          ModalInfoService.showModalInfoError(
            "We have problems saving version"
          );
        }
      })
      .catch(() => ModalInfoService.closeModalInfo());
  };

  const handleCreateObject = (modelAuthorId: string) => {
    ObjectEditorService.editorCreateObject(version, model, modelAuthorId)
      .then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          navigate(`/objects/${res}`);
          backToInfo();
        } else {
          ModalInfoService.showModalInfoError(
            "We have problems saving version"
          );
        }
      })
      .catch(() => ModalInfoService.closeModalInfo());
  };

  const onCancelClick = () => {
    const action = versionId ? backToInfo : backToList;
    hasChanges
      ? dispatch(
          showModal({
            text: "Go back without saving?",
            primaryAction: action,
          })
        )
      : action();
  };

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <>
            <div className={styles.formContainer}>
              <DraftForm
                errorValues={errorValues}
                versionChange={handleVersionChange}
                version={version}
              />
            </div>
            <div className={styles.fileContainer}>
              {modelIsReady ? (
                <DraftModel model={model} modelChange={handleModelChange} />
              ) : (
                <Loading />
              )}
            </div>
          </>
        </LoadingWrapper>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <Button variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default Draft;
