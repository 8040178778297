import React from "react";
import { IComponentCellProps, ITableColumn } from "../../../types/common";
import styles from "../../../styles/common.module.css";

import { IScheduleRecordDisplayInfo } from "../../../types/visibilitySchedule";
import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsBagCheckFill,
  BsBagX,
} from "react-icons/bs";

function Columns(): ITableColumn[] {
  return [
    {
      id: "name",
      displayName: "Name",
      width: "calc(50vw - 500px)",
      minWidth: "150px",
    },
    {
      id: "displayTimeZone",
      displayName: "Time zone",
      minWidth: "150px",
    },
    {
      id: "start",
      displayName: "Starts",
      minWidth: "200px",
      component: ScheduleRangeCell,
    },
    {
      id: "end",
      displayName: "Ends",
      minWidth: "200px",
      component: ScheduleRangeCell,
    },
    {
      id: "objectsCount",
      displayName: "Assigned objects",
      minWidth: "100px"
    },
    {
      id: "isArchived",
      displayName: "Status",
      width: "100px",
      component: StatusCell,
    },
  ];
}

function StatusCell(props: IComponentCellProps) {
  return (
    <div
      className={styles.tag}
      style={{
        backgroundColor: props.value ? "#E9ECEF" : "#28A745",
        color: props.value ? "#212529" : "#FFFFFF",
      }}
    >
      {props.value ? "Archived" : "Active"}
    </div>
  );
}

function ScheduleRangeCell(props: IComponentCellProps) {
  var item = props.value as IScheduleRecordDisplayInfo;
  var startVisibleIcon = item.visible ? (
    <BsFillEyeFill />
  ) : (
    <BsFillEyeSlashFill />
  );
  var startCollectableIcon = item.collectable ? <BsBagCheckFill /> : <BsBagX />;

  return (
    <div>
      {item.on} {startVisibleIcon} {startCollectableIcon}
    </div>
  );
}

export default Columns;
