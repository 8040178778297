import React, { useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Columns from "./DealsTableColumns";
import CSTable from "../../../common/CSTable/CSTable";
import DealsService from "../../../../services/Advertisers/DealsService";
import { IAdvertiserContext } from "../../../../types/advertisers";

function DealsList() {
  let { adId } = useParams<{ adId: string }>();
  const columns = Columns();
  const [adContext] = useOutletContext<IAdvertiserContext[]>();
  const navigate = useNavigate();

  const getData = useCallback(
    () => DealsService.getDeals(adId, adContext.displayTimeZone),
    [adId, adContext.displayTimeZone]
  );

  const onRowClick = (id: string) => {
    navigate(`${id}`);
  };

  const emptyTable = () => {
    return (
      <div className="d-flex justify-content-center m-5">
        <div>
          Before you can add a deal, you need to define at least one location
        </div>
      </div>
    );
  };

  return (
    <CSTable
      dataTestId="deals-list"
      columns={columns}
      loadDataFunction={getData}
      onRowClick={onRowClick}
      height="calc(100vh - 80px)"
      emptyComponent={adContext.locationsCount === 0 ? emptyTable() : null}
    />
  );
}

export default DealsList;
