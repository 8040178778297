import { ILink } from "./common";
import moment from "moment/moment";

// export interface IAdvertiserContext {
//   locationsCount: number;
//   displayTimeZone: string | null;
// }
// export interface IAdvertiserItemShort {
//   id: string;
//   name: string;
// }
// export interface IAdvertiserItem {
//   id: string;
//   name: string;
//   country: IDDictionaryDspName | null;
//   locationsCount?: number;
//   isArchived: boolean;
//   displayTimeZone: string | null;
// }

// export const initAdItem: IAdvertiserItem = {
//   id: "",
//   name: "",
//   country: null,
//   locationsCount: 0,
//   isArchived: false,
//   displayTimeZone: null,
// };

// export interface IAdvertiserListItem extends IAdvertiserItem {
//   activeDealsCount: number;
//   scheduledDealsCount: number;
// }

// export enum IDealStatus {
//   Draft = "Draft",
//   Active = "Active",
//   Scheduled = "Scheduled",
//   Expired = "Expired",
// }

// export interface IDeal {
//   id: string;
//   name: string;
//   imageUrl: string;
//   locations: ILocation[];
//   status: IDealStatus;
//   advertiserId: string;
//   startDisplayOn: number;
//   endDisplayOn: number;
//   link?: { url: string; title: string };
//   description: string;
// }

// export interface IDealForm {
//   id: string;
//   name: string;
//   imageUrl: string;
//   advertiserId: string;
//   startDisplayOn: string;
//   endDisplayOn: string;
//   linkTitle: string;
//   link: string;
//   status: IDealStatus;
//   locations: ILocation[];
//   description: string;
// }

// export const initDealForm: IDealForm = {
//   advertiserId: "",
//   endDisplayOn: moment(new Date()).add(1, "w").format("YYYY-MM-DD"),
//   id: "",
//   imageUrl: "",
//   link: "",
//   linkTitle: "",
//   name: "",
//   startDisplayOn: moment(new Date()).format("YYYY-MM-DD"),
//   status: IDealStatus.Draft,
//   locations: [],
//   description: "",
// };

// export interface ILocation {
//   id: string;
//   coordinates: string;
//   address: string;
//   name: string;
//   advertiserId: string;
//   adminNotes: string;
//   collectorNotes: string;
//   dealsCount?: number;
//   link: string;
// }

// export const initLocation: ILocation = {
//   address: "",
//   adminNotes: "",
//   advertiserId: "",
//   id: "",
//   link: "",
//   coordinates: "",
//   name: "",
//   collectorNotes: "",
// };


export interface ICouponCampaign {
    id: string;
    name: string;

    startOn: number;
    startDisplayOn: string,

    endOn: number;
    endDisplayOn: string, 

    advertiser: {
        id: string;
        displayName: string;
    },
    teaserTitle: string;
    teaserDescription: string;
    teaserImagePath: string | null;
    teaserButton: ILink | null;

    couponTitle: string;
    couponDescription: string;
    couponImagePath: string | null;
    couponButton: ILink | null;

    isActive: boolean;

    status: string;

    connectedObjectsCount: number;
    connectedCollectionsCount: number;
}

export interface ICouponCampaignDisplay extends ICouponCampaign {
    startDisplayOn: string,
    endDisplayOn: string, 
    status: string;
}

export interface ICouponEditForm {

    id: string;
    advertiserId: string;
    name: string;
    startOn: number;
    endOn: number;
    teaserTitle: string;
    teaserDescription: string;
    teaserImagePath: string | null;
    teaserButton: ILink | null;

    couponTitle: string;
    couponDescription: string;
    couponImagePath: string | null;
    couponButton: ILink | null;

    isActive: boolean;
}

export const initCouponForm: ICouponEditForm = { 
    id: "",
    advertiserId: "",
    name: "",
    startOn: moment(new Date()).add(1, "d").valueOf(),
    endOn: moment(new Date()).add(8, "d").valueOf(),
    teaserTitle: "",
    teaserDescription: "",
    teaserImagePath: "",
    teaserButton: null,
    couponTitle: "",
    couponDescription: "",
    couponImagePath: "",
    couponButton: null,
    isActive: false,
}