import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import * as THREE from "three";

class Model3DLib {
  handleLoadModelFile = async (file: File): Promise<string | undefined> => {
    const gltf = await this.modelFileToGltf(file);
    if (gltf) {
      const newScene = this.doNormalization(gltf.scene, 1);
      return this.sceneToObjectURL(newScene);
    }
    return;
  };

  modelFileToGltf = async (file: File): Promise<GLTF | undefined> => {
    return new Promise((res, rej) => {
      const loader = new GLTFLoader();
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (reader.result) {
          loader.load(
            reader.result.toString(),
            (gltf) => {
              res(gltf);
            },
            undefined,
            rej
          );
        }
      };
    });
  };

  searchForNormalizationLayer = (
    threeObject: THREE.Object3D
  ): THREE.Object3D | false => {
    if (threeObject.children?.[0]?.name === "CS-NormalizationLayer") {
      return threeObject;
    }
    if (threeObject.children && threeObject.children[0]) {
      return this.searchForNormalizationLayer(threeObject.children[0]);
    }
    return false;
  };

  doNormalization = (sc: THREE.Object3D, finalSize: number): THREE.Object3D => {
    let scene = sc.clone(true);
    const wasNormalizedBefore = this.searchForNormalizationLayer(scene);

    if (wasNormalizedBefore) {
      scene = wasNormalizedBefore;
    } else {
      const allChildren = [...scene.children];
      scene.children = [new THREE.Object3D()];
      scene.children[0].name = "CS-NormalizationLayer";
      scene.children[0].children = allChildren;

      const bbox = new THREE.Box3().setFromObject(scene);
      const size = bbox.getSize(new THREE.Vector3());
      const scaleValue = finalSize / Math.max(size.x, size.y, size.z);
      scene.children[0].scale.multiplyScalar(scaleValue);

      const cent = bbox.getCenter(new THREE.Vector3());
      scene.children[0].position.copy(cent).multiplyScalar(-scaleValue);
    }
    return scene;
  };

  sceneToObjectURL = async (
    scene: THREE.Object3D
  ): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      const exporter = new GLTFExporter();
      const options = {
        trs: true,
        binary: true,
        forceIndices: true,
      };
      exporter.parse(
        scene,
        (gltf) => {
          resolve(
            gltf instanceof ArrayBuffer
              ? this.arrayBufferToUrl(gltf)
              : this.jsonToUrl(gltf)
          );
        },
        reject,
        options
      );
    });
  };

  arrayBufferToUrl(buffer: ArrayBuffer): string {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    return URL.createObjectURL(blob);
  }

  jsonToUrl(json: any): string {
    const blob = new Blob([JSON.stringify(json)], { type: "text/plain" });
    return URL.createObjectURL(blob);
  }

  applyRotationToFileUrl = async (
    url: string,
    rotation: number
  ): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.load(
        url,
        (gltf) => {
          const newScene = this.setRotationToScene(gltf.scene, rotation);
          resolve(this.sceneToObjectURL(newScene));
        },
        undefined,
        reject
      );
    });
  };

  setRotationToScene = (
    sc: THREE.Object3D,
    rotation: number
  ): THREE.Object3D => {
    let scene = sc.clone(true);
    const wasNormalizedBefore = this.searchForNormalizationLayer(scene);
    if (wasNormalizedBefore) {
      scene = wasNormalizedBefore;
    } else {
      const allChildren = [...scene.children];
      scene.children = [new THREE.Object3D()];
      scene.children[0].name = "CS-NormalizationLayer";
      scene.children[0].children = allChildren;
    }
    scene.rotation.y += this.deg2rad(rotation);
    return scene;
  };

  deg2rad = (degrees: number) => degrees * (Math.PI / 180);
}

export default new Model3DLib();
