import {
  ColObjectsActionTypes,
  ICollectionState,
  IColObjects,
  IColObjectsAction,
} from "../../types/collections";

const initState: IColObjects = {
  wasChanged: false,
  id: "",
  objects: [],
  status: ICollectionState.Draft,
};

export const collectionReducer = (
  state = initState,
  action: IColObjectsAction
): IColObjects => {
  switch (action.type) {
    case ColObjectsActionTypes.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case ColObjectsActionTypes.SET_ID: {
      return { ...state, id: action.payload };
    }
    case ColObjectsActionTypes.SET_OBJECTS: {
      return {
        ...state,
        objects: action.payload.objects,
        wasChanged: action.payload.wasChanged,
      };
    }
    case ColObjectsActionTypes.REMOVE_OBJECT: {
      return {
        ...state,
        objects: state.objects.filter((o) => o.id !== action.payload.id),
        wasChanged: action.payload.affectChange,
      };
    }
    case ColObjectsActionTypes.CLEAR_COL_STORE: {
      return { ...initState };
    }
    default:
      return state;
  }
};
