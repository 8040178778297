import { ModalInfoActions, ModalInfoActionTypes } from "../../types/modal";
import React from "react";

export function showModalInfo(
  content: React.ReactNode,
  centered: boolean = true
): ModalInfoActions {
  return {
    type: ModalInfoActionTypes.SHOW_MODAL_INFO,
    payload: { content, centered },
  };
}

export function closeModalInfo() {
  return {
    type: ModalInfoActionTypes.CLOSE_MODAL_INFO,
  };
}
