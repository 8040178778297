import React, { useEffect, useState } from "react";
import Map, { Source, Layer, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { IObjectMap } from "../../../types/objects";
import constants from "../../../constants";

const MAPBOX_TOKEN = constants.MAPBOX_TOKEN;

interface IMapComponentProps {
  data: IObjectMap[];
}

interface IGeoJson {
  type: string;
  features: IMapFeature[];
}

interface IMapFeature {
  type: string;
  geometry: {
    type: string;
    coordinates: [number, number];
    properties: any;
  };
}

const initGeoJson: IGeoJson = {
  type: "FeatureCollection" as "FeatureCollection",
  features: [],
};

const layerStyle = {
  id: "point",
  type: "circle" as "circle",
  paint: {
    "circle-radius": 4,
    "circle-color": "black",
    "circle-stroke-color": "white",
    "circle-stroke-width": 1,
  },
};

const zoomStyle = {
  position: "absolute" as "absolute",
  right: 0,
  top: "calc(50vh - 50px)",
  boxShadow: "none",
};

function MapComponent({ data }: IMapComponentProps) {
  const [json, setJson] = useState<any>(initGeoJson);

  useEffect(() => {
    const features: IMapFeature[] = [];
    data.forEach((d) => {
      features.push({
        type: "Feature" as "Feature",
        geometry: {
          type: "Point",
          coordinates: [d.location.lon, d.location.lat],
          properties: {},
        },
      });
    });
    setJson({ type: "FeatureCollection" as "FeatureCollection", features });
  }, [data]);

  return (
    <div>
      <Map
        initialViewState={{
          latitude: 0,
          longitude: 0,
          zoom: 1,
        }}
        style={{ width: "100vw", height: "100vh" }}
        mapStyle="mapbox://styles/mapbox/light-v10?optimize=true"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <NavigationControl showCompass={false} style={zoomStyle} />
        <Source id="map-objects" type="geojson" data={json}>
          <Layer {...layerStyle} />
        </Source>
      </Map>
    </div>
  );
}

export default MapComponent;
