import React from "react";
import styles from "../../advertisers.module.css";
import { ILocation } from "../../../../types/advertisers";

interface IProps {
  locations: ILocation[];
}

function DealEditorLocationsInfo({ locations }: IProps) {
  return (
    <div data-test-id="deal-info-locations">
      <h3 className="mb-3">Locations</h3>
      {locations.map((loc) => (
        <div className="mb-2" key={loc.id}>
          <div>{loc.name}</div>
          <small className={styles.lightText}>{loc.address}</small>
        </div>
      ))}
    </div>
  );
}

export default DealEditorLocationsInfo;
