import React from "react";
import { Alert, Button, Form, Stack, Table } from "react-bootstrap";
import { BiPlus, BiTrash } from "react-icons/bi";
import { ICollection } from "../../../types/collections";

interface IProps {
    collections: ICollection[] | null;
    emptyPlaceholder?: string;
    onCollectionAdded?: (objectId: string) => void;
    onCollectionRemoved?: (objectId: string) => void;

}
function CollectionsListWithAddRemove({ collections, emptyPlaceholder, onCollectionAdded, onCollectionRemoved }: IProps) {
    const [collectionId, setCollectionId] = React.useState<string>("" as string);
    const handleAddCollection = () => {

        // if collectionId is an url, take only the last part of the path
        const objId = collectionId.split("/").pop() || collectionId;
        if (onCollectionAdded)
            onCollectionAdded(objId);

        setCollectionId("");
    }

    const handleRemoveCollection = (collectionId: string) => {
        if (onCollectionRemoved)
            onCollectionRemoved(collectionId);
    }
    if (!collections)
        return <div></div>;

    return (
        <div>


            <Alert variant="warning" >
                Changes to this section will be applied immediately.
            </Alert>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Location</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {collections.map((collection, index) => (
                        <tr key={index} style={{verticalAlign: "middle"}}>
                             <td><img src={collection.iconUrl} alt={collection.name} className="rounded" style={{ width: "40px" }} /></td>
                            <td>
                                <a target="_blank" rel="noreferrer" href={`${window.location.origin}/collections/${collection.id}`}>
                                    {collection.name}
                                </a>
                            </td>
                            <td>{[collection.country?.displayName, collection.region?.displayName, collection.place?.displayName].filter(x=>x!=null).join(", ")}</td>
                            <td>
                                <Button variant="danger" size="sm"  onClick={e => handleRemoveCollection(collection.id)}>
                                    <BiTrash />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {collections.length === 0 && <div>{emptyPlaceholder}</div>}

            <Stack direction="horizontal" gap={1} className="me-1">
                <Form.Control
                    type="text"
                    aria-describedby="passwordHelpBlock"
                    placeholder="Collection ID or URL (copy it from a browser address bar)"
                    value={collectionId}
                    onChange={e => setCollectionId(e.target.value)}
                />
                <Button variant="primary" size="sm" className="me-2" onClick={handleAddCollection}><BiPlus  /></Button>
            </Stack>
        </div>);
}

export default CollectionsListWithAddRemove;