import React, { useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { removeColObject } from "../../../../redux/action-creators/collection";
import { Table } from "react-bootstrap";
import styles from "../../../../styles/table.module.css";
import commonStyles from "../../../../styles/common.module.css";
import { BiTrash } from "react-icons/bi";
import CSImage from "../../../common/CSImage";
import CollectionObjectsService from "../../../../services/Collections/CollectionObjectsService";
import { ICollectionState } from "../../../../types/collections";
import PrivateAccess from "../../../common/PrivateAccess";
import { UserRole } from "../../../../types/userManagement";
import ObjectStatus from "../../../common/ObjectStatus";

function CollectionDetailsTable() {
  const [removedRows, setRemovedRows] = useState<string[]>([]);
  const collectionState = useTypedSelector((state) => state.collection);
  const dispatch = useDispatch();

  const handleRemoveObjectClick = (e: any, id: string) => {
    e.stopPropagation();
    collectionState.status === ICollectionState.Draft
      ? updateColObjects(id)
      : dispatch(removeColObject(id));
  };

  const updateColObjects = async (removedId: string) => {
    setRemovedRows((prevState) => [...prevState, removedId]);
    const updated = await CollectionObjectsService.updateCollectionObjects(
      collectionState.id,
      collectionState.objects
        .filter((obj) => obj.id !== removedId)
        .map((obj) => obj.id)
    );
    if (updated) {
      dispatch(removeColObject(removedId, false));
    }
    setRemovedRows((prevState) => [
      ...prevState.filter((r) => r !== removedId),
    ]);
  };

  const onRowClick = (id: string) => {
    window.open(window.location.origin + "/objects/" + id, "_blank");
  };

  return (
    <div className="overflow-auto" style={{ height: "calc(100% - 40px)" }}>
      <Table hover bordered data-test-id="collection-objects-list">
        <tbody className={styles.tbodyWithCenteredTd}>
          {collectionState.objects.map((object) => (
            <tr
              onClick={() => onRowClick(object.id)}
              key={object.id}
              className={`${commonStyles.pointer} ${
                removedRows.includes(object.id) ? styles.removedRow : ""
              }`}
            >
              <td width="40px">
                <CSImage alt="object" width="24px" src={object.iconUrl} />
              </td>
              <td style={{ borderWidth: 0 }}>
                <div className={styles.tdContent}>{object.internalName}</div>
              </td>
              <td style={{ borderWidth: 0, width: "100px" }}>
                <ObjectStatus value={object.status} />
              </td>
              <td style={{ borderWidth: 0, width: "34px" }}>
                <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
                  <BiTrash
                    onClick={(e: any) => handleRemoveObjectClick(e, object.id)}
                    className={styles.showOnRowHover}
                    color={"#DC3545"}
                  />
                </PrivateAccess>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default CollectionDetailsTable;
