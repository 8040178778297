import { IComponentCellProps, ITableColumn } from "../../../../types/common";
import styles from "../../../../styles/common.module.css";

function Columns(): ITableColumn[] {
  return [
    {
      id: "name",
      displayName: "Campaign name",
      width: "30vw",
    },
    {
      id: "startDisplayOn",
      displayName: "Show on",
      width: "10vw",
    },
    {
      id: "endDisplayOn",
      displayName: "Unavailable on",
      width: "10vw",
    },
    {
      id: "connectedCollectionsCount",
      displayName: "Collections",
      width: "10vw",
      component: CollectionsCounterWithLink
    },
    {
      id: "connectedObjectsCount",
      displayName: "Objects",
      width: "10vw",
      component: ObjectsCounterWithLink
    },
    {
      id: "status",
      displayName: "Status",
      width: "10vw",
      component: StatusCell,
    },
  ];
}

function ObjectsCounterWithLink(props: IComponentCellProps) {
  var objectsUrl = "/objects/?search=coupon%3A+" + props.row["id"];
  return (
    <div>
      <a href={objectsUrl}>{props.value}</a>
    </div>
  );
}

function CollectionsCounterWithLink(props: IComponentCellProps) {
  var objectsUrl = "/collections/?search=coupon%3A+" + props.row["id"];
  return (
    <div>
      <a href={objectsUrl}>{props.value}</a>
    </div>
  );
}

function StatusCell(props: IComponentCellProps) {
  let color = "#E9ECEF";
  let textColor = "#212529";
  if (!props.value) {
    return <span></span>;
  }
  switch (props.value) {
    case "Draft": {
      color = "#6B7AFF";
      textColor = "#FFFFFF";
      break;
    }
    case "Active": {
      color = "#28A745";
      textColor = "#FFFFFF";
      break;
    }
    case "Scheduled": {
      color = "#343A40";
      textColor = "#FFFFFF";
      break;
    }
    case "Expired": {
      color = "#DEE2E6";
      textColor = "#000000";
      break;
    }
    default:
      break;
  }
  return (
    <div
      className={styles.tag}
      style={{ backgroundColor: color, color: textColor }}
    >
      {props.value}
    </div>
  );
}

export default Columns;
