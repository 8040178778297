import AuthService from "./AuthService";
import axios from "axios";
import { store } from "../redux";
import * as Sentry from "@sentry/react";
import { AlertType } from "../types/alerts";
import AlertsService from "../redux/services/AlertsService";
import constants from "../constants";

class SetUpService {
  axiosSetUp = () => {
    const token = localStorage.getItem("token");
    if (token) {
      AuthService.setAuthToken(token);
    }

    axios.interceptors.response.use(
      (res) => res,
      async (error) => {
        let handled = false;
        const config = error?.config;
        if (error?.response?.status === 401 && !config?.sent) {
          handled = true;
          config.sent = true;
          if (!error?.config?.headers?.refreshToken) {
            const result = await AuthService.refreshToken();
            if (result) {
              config.headers = { ...config.headers };
              return axios(config);
            } else {
              store.dispatch({ type: "LOGOUT" });
            }
          } else {
            store.dispatch({ type: "LOGOUT" });
          }
        }
        if (error?.code === "ERR_CANCELED") return;
        if (error?.response?.status === 400) {
          const urlSegments = new URL(error.request.responseURL).pathname.split(
            "/"
          );
          error.name += ": " + urlSegments.pop() || urlSegments.pop();
          Sentry.captureException(error, {
            fingerprint: error.request.responseURL,
            extra: {
              "Error response": JSON.stringify(error.response.data),
            },
          });
        }
        if (!handled && !config.selfHandled) {
          AlertsService.addAlert(
            error.name +
              "\n " +
              (error.response && typeof error.response.data === "string"
                ? constants.API_ERROR_CODES[
                    error.response
                      .data as keyof typeof constants.API_ERROR_CODES
                  ] || error.response.data
                : error.message),
            AlertType.ERROR
          );
        }
        return Promise.reject(error);
      }
    );
  };

  applyUrl = () => {
    const search = new URLSearchParams(window.location.search).get("search");
    if (search) {
      store.dispatch({
        type: "SET_SEARCH",
        payload: search,
      });
    }
  };
}

export default new SetUpService();
