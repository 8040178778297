import React from "react";
import { ICollectionEditor } from "../../../types/collections";
import { Col, Form, Row } from "react-bootstrap";
import Images from "../../common/ObjectCollection/Images";
import { IFileWithUrl } from "../../../types/common";
import GeoDropdowns from "../../Object/AdminEditor/GeoDropdowns";
import FormInputLimited from "../../common/FormInputLimited";
import ObjectCollectionTags from "../../common/Tags/ObjectCollectionTags";
import MarkdownSupportComment from "../../common/MarkdownSupportComment";
import CouponSelect from "../../Advertisers/AdvertiserDetails/Coupons/CouponSelect";

interface IProps {
  collection: ICollectionEditor;
  collectionChange: (values: { name: string; value: any }[]) => void;
  errorValues: string[];
}
function CollectionEditorForm({
  collection,
  collectionChange,
  errorValues,
}: IProps) {
  const onFormControlChange = (event: any) => {
    collectionChange([{ name: event.target.name, value: event.target.value }]);
  };

  const handleDeleteImage = (
    index: number,
    field: "extraPhotos" | "adminPictures"
  ) => {
    collection[field].splice(index, 1);
    collectionChange([{ name: "extraPhotos", value: collection.extraPhotos }]);
  };

  const handleAddImage = (
    fileUrls: IFileWithUrl[],
    field: "extraPhotos" | "adminPictures"
  ) => {
    collectionChange([
      {
        name: field,
        value: collection[field].concat(fileUrls),
      },
    ]);
  };

  const handleGeoChange = (values: { name: string; value: any }[]) => {
    collectionChange(values);
  };

  const handleDeleteTag = (tag: string, index: number) => {
    collection.tags.splice(index, 1);
    collectionChange([{ name: "tags", value: [...collection.tags] }]);
  };

  const handleAddTag = (tag: string) => {
    if (!collection.tags.includes(tag)) {
      collectionChange([{ name: "tags", value: [...collection.tags, tag] }]);
    }
  };

  return (
    <div  style={{marginBottom:150}}>
      <Form>
        <FormInputLimited
          isInvalid={errorValues.includes("name")}
          data-test-id="col-editor-name"
          label="Title"
          onChange={onFormControlChange}
          lengthLimit={80}
          value={collection.name}
          type="text"
          name="name"
        />
        <FormInputLimited
          isInvalid={errorValues.includes("details")}
          data-test-id="col-editor-details"
          label="Description"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={collection.details}
          name="details"
          as="textarea"
          rows={5}
          comment={MarkdownSupportComment()}
        />

        <Images
          id="extraPhotos"
          images={collection.extraPhotos}
          onAddImage={(images) => handleAddImage(images, "extraPhotos")}
          onDeleteImage={(index) => handleDeleteImage(index, "extraPhotos")}
        />

        <Row>
          <Col>
            <FormInputLimited
              data-test-id="col-editor-linkTitle"
              label="Title link"
              onChange={onFormControlChange}
              lengthLimit={50}
              value={collection.linkTitle}
              type="text"
              name="linkTitle"
            />
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                data-test-id="col-editor-link"
                onChange={onFormControlChange}
                name="link"
                type="text"
                value={collection.link}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <h3 className="mt-5 mb-3">Admin info</h3>
      <GeoDropdowns
        country={collection.country}
        region={collection.region}
        place={collection.place}
        geoChange={handleGeoChange}
      />
      <Form className="mt-3" style={{ maxWidth: "632px" }}>
        <ObjectCollectionTags
          tags={collection.tags}
          onDeleteTag={handleDeleteTag}
          onAddTag={handleAddTag}
        />

        <FormInputLimited
          data-test-id="col-editor-adminNotes"
          label="Notes"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={collection.adminNotes}
          name="adminNotes"
          as="textarea"
          rows={6}
        />
      </Form>
      <Images
        id="adminPictures"
        images={collection.adminPictures}
        onAddImage={(images) => handleAddImage(images, "adminPictures")}
        onDeleteImage={(index) => handleDeleteImage(index, "adminPictures")}
      />

      <div>
        <h6>Coupons</h6>
        <CouponSelect 
          couponCampaignId={collection.couponCampaignId} 
          onChange={(v) => collectionChange([{ name: "couponCampaignId", value: v }])} />
      </div>
    </div>
  );
}

export default CollectionEditorForm;
