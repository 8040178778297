import { IModal, ModalActions, ModalActionTypes } from "../../types/modal";

const initialState: IModal = {
  show: false,
  title: "",
  text: "",
  primaryAction: () => {},
};

export const modalReducer = (
  state = initialState,
  action: ModalActions
): IModal => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL: {
      return { ...action.payload, show: true };
    }
    case ModalActionTypes.CLOSE_MODAL: {
      return initialState;
    }
    default:
      return state;
  }
};
