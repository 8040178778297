import constants from "../../constants";
import axios from "axios";
import { ILocation } from "../../types/advertisers";
import HelperService from "../HelperService";

class LocationsService {
  getLocations = (advertiserId: any) => {
    const url = `${constants.API_ADMIN_AD}/GetLocations?advertiserId=${advertiserId}`;

    return axios.get(url).then((res): ILocation[] => res.data);
  };

  getLocation = (locationId: string) => {
    const url = `${constants.API_ADMIN_AD}/GetLocation?id=${locationId}`;

    return axios.get(url).then(
      (res): ILocation => ({
        ...res.data,
        adminNotes: res.data.adminNotes || "",
        coordinates: HelperService.getCoordinatesString(
          res.data.lat,
          res.data.lon
        ),
        collectorNotes: res.data.collectorNotes || "",
        link: res.data.link || "",
      })
    );
  };

  updateLocation = async (data: ILocation) => {
    try {
      const url = `${constants.API_ADMIN_AD}/UpdateLocation`;
      const geoObject = HelperService.geoObjectFromString(data.coordinates);
      const updateData = {
        id: data.id,
        name: data.name,
        address: data.address,
        lat: geoObject.lat,
        lon: geoObject.lon,
        adminNotes: data.adminNotes,
        collectorNotes: data.collectorNotes,
        link: data.link,
      };
      await axios.post(url, updateData).then();
      return true;
    } catch {
      return false;
    }
  };

  createLocation = async (data: ILocation) => {
    try {
      const url = `${constants.API_ADMIN_AD}/CreateLocation`;
      const geoObject = HelperService.geoObjectFromString(data.coordinates);
      const createData = {
        advertiserId: data.advertiserId,
        name: data.name,
        address: data.address,
        lat: geoObject.lat,
        lon: geoObject.lon,
        adminNotes: data.adminNotes,
        collectorNotes: data.collectorNotes,
        link: data.link,
      };
      await axios.post(url, createData).then();
      return true;
    } catch {
      return false;
    }
  };

  deleteLocation = async (id: string) => {
    const url = `${constants.API_ADMIN_AD}/DeleteLocation?id=${id}`;
    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new LocationsService();
