import React, { useEffect, useState } from "react";
import { ICouponCampaignDisplay } from "../../../../types/coupons";
import { Form, Stack } from "react-bootstrap";
import AdvertiserService from "../../../../services/Advertisers/AdvertiserService";
import { IAdvertiserListItem } from "../../../../types/advertisers";
import CouponsService from "../../../../services/Advertisers/CouponsService";
import { Typeahead } from "react-bootstrap-typeahead";
import LoadingWrapper from "../../../common/LoadingWrapper";
import { IStatus } from "../../../../types/common";

interface IProps{

    couponCampaignId: string | null;
    onChange: (couponCampaignId: string | null) => void;
}

function CouponSelect({couponCampaignId, onChange}: IProps){

    const [advertiserSearch, setAdvertiserSearch] = useState<string>("");
    const [couponSearch, setCouponSearch] = useState<string>("");
    const [advertiserId, setAdvertiserId] = useState<string | null>(null);
    const [advertisers, setAdvertisers] = useState<IAdvertiserListItem[]>([]);
    const [coupons, setCoupons] = useState<ICouponCampaignDisplay[]>([]);
    const [ready, setReady] = useState<boolean>(couponCampaignId === null);
    const [useCoupon, setUseCoupon] = useState<boolean>(couponCampaignId !== null);
    useEffect(() => {

        // load coupons
        CouponsService.getCoupons(advertiserId).then((coupons) => {
            setCoupons(coupons);
            setReady(true);
        });

    }, [advertiserId, couponSearch]);

    useEffect(() => {

        // load advertisers
        AdvertiserService.getAdvertisers(0, 10000, advertiserSearch).then((advertisers) => {
            setAdvertisers(advertisers);
        });

    }, [advertiserSearch]);

    useEffect(() => {
        // load coupon by id
        if (couponCampaignId) {

            CouponsService.getCoupon(couponCampaignId).then((coupon) => {
                setAdvertiserId(coupon.advertiser.id);
            });
        }
    }, [couponCampaignId]);


    function onAdvertiserChange(selected: any[]): void {
        if (selected.length > 0) {
            setAdvertiserId(selected[0].id);
        }
    }

    function onCouponChange(selected: any[]): void {
        if (selected.length > 0) 
            onChange(selected[0].id);
        else
            onChange(null);
    }

    function onUseCouponChange(e: any): void {
        var newVal = !useCoupon;
        setUseCoupon(newVal);
        if(!newVal) {
            onChange(null);
            setAdvertiserId(null);
        }
    }

    return (
        <div>
            <LoadingWrapper status={ready? IStatus.Success : IStatus.Loading}>
                <Stack direction="vertical">
                    

                    
                    <Form.Check type="switch" id="publish-switcher">
                    
                        <Form.Check.Input checked={useCoupon} onChange={onUseCouponChange} />
                        <Form.Check.Label className="float-start" style={{ marginRight: "46px" }}>
                            Use coupon
                        </Form.Check.Label>
                    </Form.Check>

                    <Stack direction="horizontal" gap={3} hidden={!useCoupon} >
                        <Typeahead labelKey="name" options={advertisers} onInputChange={x=> setAdvertiserSearch(x)} 
                            paginate={true} maxResults={10} onChange={onAdvertiserChange} placeholder="Select advertiser" 
                            caseSensitive={false}
                            selected={advertisers.filter(x=>x.id === advertiserId)}
                        />

                        <Typeahead labelKey="name" options={coupons} onInputChange={x=> setCouponSearch(x)} 
                            paginate={true} maxResults={10} onChange={onCouponChange} placeholder="Select a coupon campaign" 
                            caseSensitive={false}
                            selected={coupons.filter(x=>x.id === couponCampaignId)}
                            style={{minWidth: 300}}
                        />
                        
                    </Stack>
                </Stack>
            </LoadingWrapper>

        </div>
    );
}

export default CouponSelect;