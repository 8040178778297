import React from "react";

export interface IModal {
  show?: boolean;
  title?: string;
  text: string;
  primaryText?: string;
  secondaryText?: string;
  primaryAction: () => void;
  secondaryAction?: () => void;
  oneActionMode?: boolean;
}

export enum ModalActionTypes {
  SHOW_MODAL = "SHOW_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

interface IShowModalAction {
  type: ModalActionTypes.SHOW_MODAL;
  payload: IModal;
}

interface ICloseModalAction {
  type: ModalActionTypes.CLOSE_MODAL;
}

export type ModalActions = IShowModalAction | ICloseModalAction;

export interface IModalInfo {
  content: React.ReactNode | null;
  show: boolean;
  centered?: boolean;
}

export enum ModalInfoActionTypes {
  SHOW_MODAL_INFO = "SHOW_MODAL_INFO",
  CLOSE_MODAL_INFO = "CLOSE_MODAL_INFO",
}

interface IShowModalInfoAction {
  type: ModalInfoActionTypes.SHOW_MODAL_INFO;
  payload: { content: React.ReactNode; centered?: boolean };
}

interface ICloseModalInfoAction {
  type: ModalInfoActionTypes.CLOSE_MODAL_INFO;
}

export type ModalInfoActions = IShowModalInfoAction | ICloseModalInfoAction;
