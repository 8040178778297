import React, { useCallback, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";
import styles from "../Object.module.css";
import detailStyles from "../../../styles/details.module.css";
import { Button, Form } from "react-bootstrap";
import SearchInfo from "../../Navbar/Search/SearchInfo";
import CSTable from "../../common/CSTable/CSTable";
import Columns from "../../common/ObjectCollection/CollectionsTableColumns";
import { ICollection, ICollectionShort } from "../../../types/collections";
import popoverObjects from "../../Navbar/Search/PopoverObjects";
import CollectionsService from "../../../services/Collections/CollectionsService";
import { debounce } from "lodash";

interface IProps {
  closeManager: () => void;
  onSaveClick: (v: any[]) => void;
  preSelected: ICollectionShort[];
}

function CollectionsManager({
  closeManager,
  onSaveClick,
  preSelected,
}: IProps) {
  const columns = Columns(45);
  const [selected, setSelected] = useState<ICollectionShort[]>([
    ...preSelected,
  ]);
  const [search, setSearch] = useState<string>("");
  const searchInfo = useMemo(() => popoverObjects(), []);

  const getData = useCallback(
    (s: number, l: number) => CollectionsService.getCollections(s, l, search),
    [search]
  );

  const debouncedSearch = debounce((nextValue: string) => {
    setSearch(nextValue.trim());
  }, 800);

  const onSearchChange = (e: any) => debouncedSearch(e.target.value);

  const handleRowClick = (col: ICollection) => {
    if (preSelectedCollection(col)) return;
    let index = null;
    selected.find((c, i) => {
      if (c.id === col.id) {
        index = i;
        return true;
      }
      return false;
    });
    index !== null
      ? selected.splice(index, 1)
      : selected.push({
          id: col.id,
          iconUrl: col.iconUrl,
          name: col.name,
        });
    setSelected([...selected]);
  };

  const handleSaveClick = () => {
    onSaveClick(selected);
    closeManager();
  };

  const preSelectedCollection = (col: ICollectionShort) => {
    return !!preSelected.find((selD) => selD.id === col.id);
  };

  return ReactDOM.createPortal(
    <div className={styles.collectionsManagerContainer}>
      <div className={detailStyles.editorContainer}>
        <div className={`${detailStyles.editorContent}`}>
          <div
            style={{ height: 40, zIndex: 9000 }}
            className="w-100 d-flex justify-content-center align-items-center position-relative shadow"
          >
            <div className="d-flex align-items-center">
              <Form.Control
                style={{ width: 400 }}
                size="sm"
                data-test-id="obj-collections-search"
                placeholder="Search"
                defaultValue={search}
                onChange={onSearchChange}
              />
              <SearchInfo popoverBody={searchInfo} />
            </div>
          </div>
          <CSTable
            dataTestId="collections-list"
            height={"calc(100vh - 95px)"}
            columns={columns}
            loadDataFunction={getData}
            onRowClick={{ action: handleRowClick }}
            selected={selected.map((obj) => obj.id)}
            inactiveRow={preSelectedCollection}
          />
        </div>
        <div
          className={`${detailStyles.buttonContainer} d-flex justify-content-between`}
        >
          <Button
            data-test-id="obj-collections-cancel"
            variant="light"
            size="sm"
            onClick={closeManager}
          >
            Cancel
          </Button>
          {selected.length > preSelected.length && (
            <Button
              data-test-id="obj-collections-add"
              variant="primary"
              size="sm"
              onClick={handleSaveClick}
            >
              Add
            </Button>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
}

export default CollectionsManager;
