import React from "react";
import ClickableImage from "../ClickableImage/ClickableImage";
import styles from "./ObjectCollection.module.css";
import { BiPlusCircle } from "react-icons/bi";
import { Form } from "react-bootstrap";
import { IFileWithUrl } from "../../../types/common";

interface IProps {
  id: string;
  images: IFileWithUrl[];
  onAddImage: (fileUrls: IFileWithUrl[]) => void;
  onDeleteImage: (index: number) => void;
}
function Images({ id, images, onDeleteImage, onAddImage }: IProps) {
  const handleInputFileChange = (event: any) => {
    let promises: Promise<IFileWithUrl>[] = [];
    for (const file of event.target.files) {
      let filePromise: Promise<IFileWithUrl> = new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () =>
          resolve({
            url: reader.result,
            file: file,
          } as IFileWithUrl);
      });
      promises.push(filePromise);
    }
    Promise.all(promises).then((files) => {
      onAddImage(files);
    });
  };

  return (
    <div
      className={`${styles.imagesContainer} mb-3 mt-3 d-flex align-items-center`}
    >
      <Form.Group controlId={id + "_extraImageInput"}>
        <Form.Label className="mb-0">
          <div className={styles.addImage}>
            <BiPlusCircle />
          </div>
        </Form.Label>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          accept=".png,.jpeg,.jpg"
          onChange={handleInputFileChange}
        />
      </Form.Group>
      {images.length === 0 ? (
        <div className="ms-2">Upload photos</div>
      ) : (
        images.map((image, index) => (
          <ClickableImage
            deleteClick={() => onDeleteImage(index)}
            style={{ objectFit: "cover" }}
            key={index + image.url}
            src={image.url}
            width={64}
            height={64}
            rounded
          />
        ))
      )}
    </div>
  );
}

export default Images;
