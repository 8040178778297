import React from "react";
import {
  IVisibilitySchedule,
  IScheduleRecord,
  initScheduleRecord,
} from "../../../types/visibilitySchedule";

import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs";
import FormInputLimited from "../../common/FormInputLimited";
import { TimeZonesSelect } from "../../common/TimeZonesSelect";
import VisibilityScheduleEditorFormRecord from "./VisibilityScheduleEditorFormRecord";

interface IProps {
  schedule: IVisibilitySchedule;
  scheduleChanged: (name: string, value: any) => void;
  errorValues: string[];
}

function VisibilityScheduleEditorForm({
  schedule,
  scheduleChanged,
  errorValues,
}: IProps) {
  const onFormControlChange = (event: any) => {
    console.log(event.target.name, event.target.value);
    scheduleChanged(event.target.name, event.target.value);
  };

  const onArchivedChanged = (event: any) => {
    scheduleChanged("isArchived", event.target.checked);
  }

  const onRecordChanged = (index: number, name: string, value: any) => {
    const newRecords = [...schedule.records] as any[];
    const targetRecord = newRecords[index];
    const key = name as keyof IScheduleRecord;
    const v: IScheduleRecord[keyof IScheduleRecord] = value;
    targetRecord[key] = v as any;

    scheduleChanged("records", newRecords);
  };

  const onRecordAdd = () => {
    const newRecords = [...schedule.records];
    const lastRecord =
      newRecords.length > 0 ? newRecords[newRecords.length - 1] : null;
    newRecords.push(initScheduleRecord(lastRecord));
    scheduleChanged("records", newRecords);
  };

  const onRecordRemoved = (index: number) => {
    const newRecords = [...schedule.records];
    newRecords.splice(index, 1);
    scheduleChanged("records", newRecords);
  };

  const renderArchivedTooltip = (props:any) => (
    <Tooltip {...props}>
      Archived schedules are still active, but they cannot be assigned to new objects.
    </Tooltip>
  );

  return (
    <Form>
      <FormInputLimited
        isInvalid={errorValues.includes("name")}
        data-test-id="schedule-form-name"
        label="Name"
        onChange={onFormControlChange}
        lengthLimit={50}
        value={schedule.name}
        type="text"
        name="name"
      />

      
      <Form.Check className="mb-3">
        <Form.Check.Input
          name="isArchived"
          checked={schedule.isArchived}
          onChange={onArchivedChanged}
        />
        <Form.Check.Label>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderArchivedTooltip} >
            <span>Archived <BsQuestionCircle /></span>
          </OverlayTrigger>
        </Form.Check.Label>
      </Form.Check>

      <TimeZonesSelect
        value={schedule.displayTimeZone}
        label="Time Zone"
        name="displayTimeZone"
        onChange={onFormControlChange}
      />

      <div>
        <label>Schedule records</label>

        {schedule.records.map((record: IScheduleRecord, index: number) => {
              return (
                <div key={index}>
                  <VisibilityScheduleEditorFormRecord
                    record={record}
                    timezone={schedule.displayTimeZone}
                    recordChanged={(fieldName: string, value: any) =>
                      onRecordChanged(index, fieldName, value)
                    }
                    recordRemoved={() => onRecordRemoved(index)}
                  />
                </div>
              );
            })}

        <Button size="sm" variant="outline-dark" onClick={onRecordAdd}>
          Add schedule element
        </Button>
      </div>

    
    </Form>
  );
}

export default VisibilityScheduleEditorForm;
