import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../common/PrivateRoute";
import Wrapper from "../common/Wrapper";
import Advertisers from "../Advertisers/AdvertisersLIst";
import AdvertiserEditor from "../Advertisers/AdvertiserEditor/AdvertiserEditor";
import AdvertiserDetails from "../Advertisers/AdvertiserDetails/AdvertiserDetails";
import DealsList from "../Advertisers/AdvertiserDetails/Deals/DealsList";
import LocationsList from "../Advertisers/AdvertiserDetails/Locations/LocationsList";
import LocationEditor from "../Advertisers/AdvertiserDetails/Locations/LocationEditor";
import SponsorshipList from "../Advertisers/AdvertiserDetails/Sponsorship/SponsorshipList";
import { navigation, navigationPages } from "../../types/navigation";
import DealEditor from "../Advertisers/AdvertiserDetails/Deals/DealEditor";
import { UserRole } from "../../types/userManagement";
import CouponsList from "../Advertisers/AdvertiserDetails/Coupons/CouponsList";
import CouponEditor from "../Advertisers/AdvertiserDetails/Coupons/CouponEditor";
import SponsorshipEditor from "../Advertisers/AdvertiserDetails/Sponsorship/SponsorshipEditor";
import AdvertiserUsersList from "../Advertisers/AdvertiserDetails/AdUsers/AdUsersList/AdvertiserUsersList";
import AdUserEditor from "../Advertisers/AdvertiserDetails/AdUsers/AdUserEditor/AdUserEditor";

const AdvertiserRoutes = [
  <Route
    key="advertisers"
    path="advertisers"
    element={
      <PrivateRoute
        roles={[
          UserRole.Admin,
          UserRole.Editor,
          UserRole.Reader,
          UserRole.AdvertisingManager,
        ]}
      >
        <Wrapper {...navigation[navigationPages.advertisers]}>
          <Advertisers />
        </Wrapper>
      </PrivateRoute>
    }
  />,
  <Route
    key="advertisers/new"
    path="advertisers/new"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <AdvertiserEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="advertisers/:adId/edit"
    path="advertisers/:adId/edit"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <AdvertiserEditor />
      </PrivateRoute>
    }
  />,

  // Deals
  <Route
    key="advertisers/:adId/deals/:dealId"
    path="advertisers/:adId/deals/:dealId"
    element={
      <PrivateRoute
        roles={[
          UserRole.Admin,
          UserRole.Editor,
          UserRole.Reader,
          UserRole.AdvertisingManager,
        ]}
      >
        <DealEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="advertisers/:adId/deals/new"
    path="advertisers/:adId/deals/new"
    element={
      <PrivateRoute
        roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}
      >
        <DealEditor />
      </PrivateRoute>
    }
  />,

  // Coupons
  <Route
    key="advertisers/:adId/coupons/:couponId"
    path="advertisers/:adId/coupons/:couponId"
    element={
      <PrivateRoute
        roles={[
          UserRole.Admin,
          UserRole.Editor,
          UserRole.Reader,
          UserRole.AdvertisingManager,
        ]}
      >
        <CouponEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="advertisers/:adId/coupons/new"
    path="advertisers/:adId/coupons/new"
    element={
      <PrivateRoute
        roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}
      >
        <CouponEditor />
      </PrivateRoute>
    }
  />,

  // Locations
  <Route
    key="advertisers/:adId/locations/new"
    path="advertisers/:adId/locations/new"
    element={
      <PrivateRoute
        roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}
      >
        <LocationEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="advertisers/:adId/locations/:locationId"
    path="advertisers/:adId/locations/:locationId"
    element={
      <PrivateRoute
        roles={[
          UserRole.Admin,
          UserRole.Editor,
          UserRole.Reader,
          UserRole.AdvertisingManager,
        ]}
      >
        <LocationEditor />
      </PrivateRoute>
    }
  />,
 // Sponsorship
 <Route
 key="advertisers/:adId/sponsorship/new"
 path="advertisers/:adId/sponsorship/new"
 element={
   <PrivateRoute
     roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}
   >
     <SponsorshipEditor />
   </PrivateRoute>
 }
/>,
<Route
 key="advertisers/:adId/sponsorship/:sponsorshipId"
 path="advertisers/:adId/sponsorship/:sponsorshipId"
 element={
   <PrivateRoute
     roles={[
       UserRole.Admin,
       UserRole.Editor,
       UserRole.AdvertisingManager,
     ]}
   >
     <SponsorshipEditor />
   </PrivateRoute>
 }
/>,
<Route
 key="advertisers/:adId/user-management/:adUserId"
 path="advertisers/:adId/user-management/:adUserId"
 element={
   <PrivateRoute
     roles={[
       UserRole.Admin,
       UserRole.Editor,
       UserRole.AdvertisingManager,
     ]}
   >
     <AdUserEditor />
   </PrivateRoute>
 }
/>,
  <Route
    key="advertisers/:adId"
    path="advertisers/:adId"
    element={
      <PrivateRoute
        roles={[
          UserRole.Admin,
          UserRole.Editor,
          UserRole.Reader,
          UserRole.AdvertisingManager,
        ]}
      >
        <AdvertiserDetails />
      </PrivateRoute>
    }
  >
    <Route path="coupons" element={<CouponsList />} />
    <Route path="deals" element={<DealsList />} />
    <Route path="locations" element={<LocationsList />} /> 
    <Route path="sponsorship" element={<SponsorshipList />} />
    <Route path="user-management" element={<AdvertiserUsersList />} />
  </Route>,
];

export default AdvertiserRoutes;
