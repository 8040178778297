import React, { ImgHTMLAttributes, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import Fallback from "./../../images/image-fallback.png";

export default function CSImage({ src, ...props }: ImgHTMLAttributes<any>) {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const [display, setDisplay] = useState("none");
  const onError = () => setImgSrc(Fallback);
  const onLoad = () => setDisplay("block");

  useEffect(() => {
    if (src) setImgSrc(src);
  }, [src]);

  return imgSrc ? (
    <Image
      src={imgSrc}
      onError={onError}
      onLoad={onLoad}
      style={{ display: display }}
      {...props} 
    />
  ) : (
    <></>
  );
}
