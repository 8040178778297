import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AuthService from "../../services/AuthService";
import { UserRole } from "../../types/userManagement";

interface IProps {
  emailState: [string, Dispatch<SetStateAction<string>>];
  handleLoginRole: (v: UserRole) => void;
}
function LoginOtp({ emailState, handleLoginRole }: IProps) {
  const [step, setStep] = useState<number>(0);
  const [otpSecret, setOtpSecret] = useState<string>("");
  const [otpId, setOtpId] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const onEmailChange = (e: any) => {
    setError(false);
    emailState[1](e.target.value);
  };

  const onOtpChange = (e: any) => {
    setError(false);
    setOtpSecret(e.target.value);
  };

  const disabledEmail = (): boolean => {
    if (error) return true;
    return !/\S+@\S+\.\S+/.test(emailState[0]);
  };

  const disabledOtp = (): boolean => {
    if (error) return true;
    return otpSecret.length < 5;
  };

  const handleSubmitEmail = async (event: any) => {
    event.preventDefault();
    const otp = await AuthService.loginOtpStart(emailState[0]);
    if (otp) {
      setOtpId(otp);
      setStep(1);
    } else {
      setError(true);
    }
  };

  const handleSubmitOTP = async (event: any) => {
    event.preventDefault();
    const loginRole = await AuthService.loginOtpComplete(
      emailState[0],
      otpId,
      otpSecret
    );
    loginRole ? handleLoginRole(loginRole) : setError(true);
  };

  return (
    <div>
      {step === 0 && (
        <Form style={{ width: "300px" }} onSubmit={handleSubmitEmail}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              data-test-id="login-otp-email"
              isInvalid={error}
              type="text"
              value={emailState[0]}
              onChange={onEmailChange}
            />
          </Form.Group>
          <Button
            data-test-id="login-otp-next"
            variant="primary"
            type="submit"
            className="w-100"
            disabled={disabledEmail()}
          >
            Next
          </Button>
        </Form>
      )}
      {step === 1 && (
        <Form style={{ width: "300px" }} onSubmit={handleSubmitOTP}>
          <Form.Group className="mb-3">
            <Form.Label>Sign in code</Form.Label>
            <Form.Control
              data-test-id="login-otp-secret"
              isInvalid={error}
              type="text"
              value={otpSecret}
              onChange={onOtpChange}
            />
            <Form.Text className="text-muted">
              Enter code from your email
            </Form.Text>
          </Form.Group>
          <Button
            data-test-id="login-otp-signin"
            variant="primary"
            type="submit"
            className="w-100"
            disabled={disabledOtp()}
          >
            Sign in
          </Button>
        </Form>
      )}
    </div>
  );
}

export default LoginOtp;
