export enum AlertType {
  "INFO" = "primary",
  "SUCCESS" = "success",
  "ERROR" = "danger",
  "WARNING" = "warning",
}

export interface IAlertItem {
  id: string;
  text: string;
  type?: AlertType;
  delay?: number;
}

export interface IAlerts {
  alerts: IAlertItem[];
}

export enum AlertActionTypes {
  ADD_ALERT = "ADD_ALERT",
  DELETE_ALERT = "DELETE_ALERT",
}

interface IAddAlertAction {
  type: AlertActionTypes.ADD_ALERT;
  payload: { text: string; type: AlertType | undefined };
}

interface IDeleteAlertAction {
  type: AlertActionTypes.DELETE_ALERT;
  payload: string;
}

export type IAlertAction = IAddAlertAction | IDeleteAlertAction;
