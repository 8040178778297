import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import { store } from "./redux";
import constants from "./constants";

if (constants.ENVIRONMENT !== constants.ENVS.DEV) {
  Sentry.init({
    dsn: "https://3b553d73a5104634b2b995d05c84fe05@o1337046.ingest.sentry.io/4504037572739072",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: constants.ENVIRONMENT,
  });
  console.log("Sentry initialized for environment: " + constants.ENVIRONMENT);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
