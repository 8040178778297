import { Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AuthService from "../../services/AuthService";
import Loading from "./Loading";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/action-creators/auth";
import { UserRole } from "../../types/userManagement";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface IProp {
  children: any;
  roles?: UserRole[];
}

const defaultRoles = [UserRole.Admin, UserRole.Editor, UserRole.Reader];

function PrivateRoute({ children, roles = defaultRoles }: IProp) {
  const [auth, setAuth] = useState<boolean>(false);
  const userRole = useTypedSelector((store) => store.auth.role);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      const isAuth = await AuthService.isAuth();
      if (isAuth) {
        const userRole = AuthService.getRole();
        dispatch(loginUser(userRole));
        setAuth(isAuth);
      }
      setLoading(false);
    };
    fetch();
  }, [dispatch]);

  return loading ? (
    <Loading />
  ) : auth && userRole ? (
    roles.includes(userRole) ? (
      children
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
