import axios from "axios";
import constants from "../constants";
import { AuthActionTypes, IJwt } from "../types/auth";
import { Buffer } from "buffer";
import AlertsService from "../redux/services/AlertsService";
import { AlertType } from "../types/alerts";
import { UserRole } from "../types/userManagement";
import { store } from "../redux";

class AuthService {
  getUserData = () => {
    const url = `${constants.API_AUTH_ENDPOINT}/Me`;
    return axios.get(url).then((res) => res.data);
  };

  login = (userName: string, password: string): Promise<false | UserRole> => {
    const url = `${constants.API_AUTH_ENDPOINT}/GetToken`;
    const loginPayload = {
      userName,
      password,
      userPool: constants.APP_POOL,
    };

    const config = {
      method: "post",
      url: url,
      data: loginPayload,
      selfHandled: true,
    };

    return axios(config)
      .then((res) => {
        this.storeUserEmail(userName);
        this.saveTokens(res.data.token, res.data.refreshToken);
        return this.getRoleFromToken(res.data.token);
      })
      .catch((e) => {
        AlertsService.addAlert(
          "Login failed. " +
            (e.response && typeof e.response.data === "string"
              ? e.response.data
              : e.message),
          AlertType.ERROR
        );
        return false;
      });
  };

  loginOtpStart = (email: string) => {
    const url = `${constants.API_AUTH_ENDPOINT}/StartSignInWithOtp`;
    const loginPayload = {
      email,
      userPool: constants.APP_POOL,
    };

    return axios
      .post(url, loginPayload)
      .then((res) => {
        return res.data.otpId;
      })
      .catch(() => false);
  };

  loginOtpComplete = (
    email: string,
    otpId: string,
    otpSecret: string
  ): Promise<UserRole | false> => {
    const url = `${constants.API_AUTH_ENDPOINT}/CompleteSignInWithOtp`;
    const loginPayload = {
      otpId,
      otpSecret,
    };

    const config = {
      method: "post",
      url: url,
      data: loginPayload,
      selfHandled: true,
    };

    return axios(config)
      .then((res) => {
        this.storeUserEmail(email);
        this.saveTokens(res.data.token, res.data.refreshToken);
        return this.getRoleFromToken(res.data.token);
      })
      .catch((e) => {
        AlertsService.addAlert(
          "Login failed. " +
            (e.response && typeof e.response.data === "string"
              ? constants.API_ERROR_CODES[
                  e.response.data as keyof typeof constants.API_ERROR_CODES
                ] || e.response.data
              : e.message),
          AlertType.ERROR
        );
        return false;
      });
  };

  clearTokens = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.setAuthToken(undefined);
  };

  private saveTokens = (token: string, refreshToken: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    this.setAuthToken(token);
  };

  refreshToken = async (): Promise<boolean> => {
    const refreshToken = localStorage.getItem("refreshToken");
    let success = false;
    if (refreshToken && this.isTokenValid(refreshToken)) {
      this.setAuthToken(refreshToken);
      const url = `${constants.API_AUTH_ENDPOINT}/RefreshToken`;
      await axios
        .post(url, {}, { headers: { refreshToken: true } })
        .then((res) => {
          this.saveTokens(res.data.token, res.data.refreshToken);
          success = true;
        })
        .catch(() => {
          success = false;
        });
    }
    return success;
  };

  setAuthToken = (token: string | undefined) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else delete axios.defaults.headers.common["Authorization"];
  };

  isAuth = async (): Promise<boolean> => {
    const token = localStorage.getItem("token");
    if (token) {
      if (this.isTokenValid(token)) {
        await this.setUserEmail();
        return true;
      } else {
        const refreshed = await this.refreshToken();
        if (refreshed) await this.setUserEmail();
        return refreshed;
      }
    }
    return false;
  };

  getRole = (): UserRole => {
    const token = localStorage.getItem("token");
    return token ? this.getRoleFromToken(token) : UserRole.Reader;
  };

  getRoleFromToken = (token: string): UserRole => {
    const decodedJwt = this.parseJwt(token);
    return decodedJwt !== null
      ? decodedJwt[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ] || UserRole.Reader
      : UserRole.Reader;
  };

  private isTokenValid = (token: string): boolean => {
    const decodedJwt = this.parseJwt(token);
    return decodedJwt !== null && decodedJwt.exp * 1000 > Date.now();
  };

  private parseJwt = (token: string): IJwt | null => {
    try {
      return JSON.parse(
        Buffer.from(token.split(".")[1], "base64").toString("ascii")
      );
    } catch (e) {
      return null;
    }
  };

  setUserEmail = async () => {
    if (store.getState().auth.email) return;
    const token = localStorage.getItem("token");
    if (!token) return;
    try {
      const { email } = await this.getUserData();
      this.storeUserEmail(email);
    } catch {
      return;
    }
  };

  storeUserEmail = (email: string) => {
    store.dispatch({
      type: AuthActionTypes.SET_EMAIL,
      payload: { email },
    });
  };
}

export default new AuthService();
