import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import AddNewButton from "../../../common/AddNewButton/AddNewButton";
import commonStyles from "../../../../styles/common.module.css";
import detailStyles from "../../../../styles/details.module.css";
import styles from "../../Collection.module.css";
import CollectionDetailsTable from "./CollectionDetailsTable";
import { useDispatch } from "react-redux";
import PlusButton from "../../../../images/plus-button-blue.svg";
import CollectionObjectsManager from "./CollectionObjectsManager";
import { ICollectionState } from "../../../../types/collections";
import CollectionObjectsService from "../../../../services/Collections/CollectionObjectsService";
import { setColObjects } from "../../../../redux/action-creators/collection";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IStatus } from "../../../../types/common";
import LoadingWrapper from "../../../common/LoadingWrapper";
import ModalInfoService from "../../../../redux/services/ModalInfoService";
import PrivateAccess from "../../../common/PrivateAccess";
import { UserRole } from "../../../../types/userManagement";
import { IObjectState } from "../../../../types/objects";
import { showModal } from "../../../../redux/action-creators/modal";

function CollectionDetailsObjects() {
  const collectionState = useTypedSelector((state) => state.collection);
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [showManager, setShowManager] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [publishedCount, setPublishedCount] = useState<number>(0);

  useEffect(() => {
    let published = 0;
    collectionState.objects.forEach(
      (o) => o.status === IObjectState.Published && published++
    );
    setPublishedCount(published);
  }, [collectionState.objects]);

  const getData = useCallback(async () => {
    const loadData = async () => {
      try {
        return await CollectionObjectsService.getObjects(collectionState.id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    loadData().then((res) => {
      if (res) {
        dispatch(setColObjects(res));
        setStatus(IStatus.Success);
      }
    });
  }, [collectionState.id, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleViewMapClick = () => {
    dispatch({ type: "SHOW_MAP" });
  };

  const handleAddObjectsClick = () => {
    setShowManager(true);
  };

  const handleCloseManager = () => {
    setShowManager(false);
  };

  const handleSaveObjectsFromManager = async (objects: any[]) => {
    if (collectionState.status === ICollectionState.Draft) {
      updateColObjects(objects);
    } else {
      dispatch(setColObjects(objects, true));
    }
  };

  const onDiscardChangesClick = () => {
    setStatus(IStatus.Loading);
    getData();
  };

  const onPublishObjectsClick = () => {
    let publishedObjectsLength = 0;
    collectionState.objects.forEach((obj) =>
      obj.status === IObjectState.Published ? publishedObjectsLength++ : null
    );
    publishedObjectsLength < 2
      ? dispatch(
          showModal({
            title: "Unable to publish",
            text: "Collection should have at least 2 published objects",
            primaryAction: () => {},
            primaryText: "Ok",
            oneActionMode: true,
          })
        )
      : updateColObjects();
  };

  const updateColObjects = async (objects = collectionState.objects) => {
    ModalInfoService.showModalInfoLoading();
    const updated = await CollectionObjectsService.updateCollectionObjects(
      collectionState.id,
      objects.map((obj) => obj.id)
    );
    if (updated) {
      ModalInfoService.closeModalInfo();
      setStatus(IStatus.Loading);
      getData();
    } else {
      ModalInfoService.showModalInfoError("We have problems adding objects");
    }
  };

  return (
    <div>
      <div className="w-100" style={{ height: "calc(100% - 55px)" }}>
        <LoadingWrapper status={status}>
          <>
            <div
              style={{ height: 40 }}
              className="d-flex justify-content-between align-items-center shadow"
            >
              <div className="d-flex" style={{ width: "44px" }}>
                <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
                  <AddNewButton
                    dataTestId="add-obj-to-col"
                    plusNavigation={handleAddObjectsClick}
                  />
                </PrivateAccess>
              </div>
              <div data-test-id="col-objects-count">
                {collectionState.objects.length} total / {publishedCount}{" "}
                published
              </div>
              <Button
                data-test-id="col-map"
                disabled={collectionState.wasChanged}
                variant="link"
                className={commonStyles.noUnderline}
                onClick={handleViewMapClick}
              >
                View map
              </Button>
            </div>
            {collectionState.objects.length ? (
              <CollectionDetailsTable />
            ) : (
              <div className="d-flex flex-column align-items-center">
                <Button
                  data-test-id="add-obj-to-col-big"
                  onClick={handleAddObjectsClick}
                  variant="link"
                  className={`${commonStyles.noUnderline} ${styles.addObjectsContainer}`}
                >
                  <div className="d-flex flex-column align-items-center">
                    <img alt="plus-button" src={PlusButton} className="mb-2" />
                    <span>Add objects</span>
                  </div>
                </Button>
              </div>
            )}
          </>
        </LoadingWrapper>
      </div>

      <div className={detailStyles.buttonContainer}>
        {collectionState.status !== ICollectionState.Draft &&
          collectionState.wasChanged && (
            <>
              <Button
                data-test-id="col-discard"
                variant="light"
                size="sm"
                onClick={onDiscardChangesClick}
              >
                Discard changes
              </Button>
              <Button
                data-test-id="col-publish-obj"
                variant="primary"
                size="sm"
                onClick={onPublishObjectsClick}
              >
                Publish objects
              </Button>
            </>
          )}
      </div>

      {showManager && (
        <CollectionObjectsManager
          closeManager={handleCloseManager}
          onSaveClick={handleSaveObjectsFromManager}
          preSelected={collectionState.objects}
        />
      )}
    </div>
  );
}

export default CollectionDetailsObjects;
