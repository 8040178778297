import React, { useCallback, useMemo, useState } from "react";
import styles from "../../Collection.module.css";
import detailStyles from "../../../../styles/details.module.css";
import { Button, Form } from "react-bootstrap";
import * as ReactDOM from "react-dom";
import Columns from "../../../common/ObjectCollection/ObjectsTableColumns";
import CSTable from "../../../common/CSTable/CSTable";
import ObjectsService from "../../../../services/Objects/ObjectsService";
import SearchInfo from "../../../Navbar/Search/SearchInfo";
import popoverObjects from "../../../Navbar/Search/PopoverObjects";
import { debounce } from "lodash";
import { IColObjectItem } from "../../../../types/collections";
import { IObject } from "../../../../types/objects";

interface IProps {
  closeManager: () => void;
  onSaveClick: (v: any[]) => void;
  preSelected: any[];
}

function CollectionObjectsManager({
  closeManager,
  onSaveClick,
  preSelected,
}: IProps) {
  const columns = Columns(45);
  const [selected, setSelected] = useState<IColObjectItem[]>([...preSelected]);
  const [search, setSearch] = useState<string>("");
  const searchInfo = useMemo(() => popoverObjects(), []);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const getData = useCallback(
    (s: number, l: number) => ObjectsService.getObjects(s, l, search),
    [search]
  );

  const debouncedSearch = debounce((nextValue: string) => {
    setSearch(nextValue.trim());
  }, 800);

  const onSearchChange = (e: any) => debouncedSearch(e.target.value);

  const handleRowClick = (object: IObject) => {
    let index = null;
    selected.find((obj, i) => {
      if (obj.id === object.id) {
        index = i;
        return true;
      }
      return false;
    });
    index !== null
      ? selected.splice(index, 1)
      : selected.push({
          id: object.id,
          iconUrl: object.iconUrl,
          internalName: object.internalName,
          status: object.status,
        });
    setSelected([...selected]);
    setHasChanges(true);
  };

  const handleSaveClick = () => {
    onSaveClick(selected);
    closeManager();
  };

  return ReactDOM.createPortal(
    <div className={styles.objectsManagerContainer}>
      <div className={detailStyles.editorContainer}>
        <div className={`${detailStyles.editorContent}`}>
          <div
            style={{ height: 40, zIndex: 9000 }}
            className="w-100 d-flex justify-content-center align-items-center position-relative shadow"
          >
            <div className="d-flex align-items-center">
              <Form.Control
                style={{ width: 400 }}
                size="sm"
                data-test-id="col-objects-search"
                placeholder="Search"
                defaultValue={search}
                onChange={onSearchChange}
              />
              <SearchInfo popoverBody={searchInfo} />
            </div>
          </div>
          <CSTable
            dataTestId="objects-list"
            height={"calc(100vh - 95px)"}
            columns={columns}
            loadDataFunction={getData}
            onRowClick={{ action: handleRowClick }}
            selected={selected.map((obj) => obj.id)}
          />
        </div>
        <div
          className={`${detailStyles.buttonContainer} d-flex justify-content-between`}
        >
          <Button
            data-test-id="col-objects-cancel"
            variant="light"
            size="sm"
            onClick={closeManager}
          >
            Cancel
          </Button>
          {hasChanges && (
            <Button
              data-test-id="col-objects-save"
              variant="primary"
              size="sm"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
}

export default CollectionObjectsManager;
