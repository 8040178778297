import React from "react";

function PopoverCollections() {
  return (
    <div data-test-id="search-popover-collections">
      <div className="mb-3">
        Case insensitive, search everywhere, not only in title
      </div>
      <div className="mb-3">
        It is also possible to narrow down to specific fields by using field
        names. Supported field names:
        <ul>
          <li>title</li>
          <li>details</li>
          <li>country</li>
          <li>region</li>
          <li>place</li>
          <li>tags</li>
          <li>notes</li>
          <li>coupon</li>
          <li>status (allowed values draft, published, archived, new)</li>
        </ul>
      </div>
      <div className="mb-3">
        Multiple search criteria could be combined using logical AND, OR and
        negation
        <ul>
          <li>& for AND</li>
          <li>| for OR</li>
          <li>! for negation</li>
        </ul>
      </div>
      <hr />
      <h6>Examples</h6>
      <ul>
        <li>"test" - will find all objects with word "test" anywhere</li>
        <li>"title:test" - will find all objects containing "test" in title</li>
        <li>
          "!title:test" - will find all objects not containing "test" in title
        </li>
        <li>
          "title:test & country:India & tags: building" - will find all objects
          containing "test" in title located in India with tag building
        </li>
        <li>
          "title:test & country:India & !tags: building | notes: ignore" - will
          find all objects containing "test" in title located in India without
          tag building or notes containing "ignore"
        </li>
      </ul>
    </div>
  );
}

export default PopoverCollections;
