import constants from "../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { ISponsorshipForm, ISponsorship, ISponsorshipDisplayItem } from "../../types/advertisers";

class SponsorshipService {
  createSponsorship = async (formData: ISponsorshipForm) => {
    try {
      const url = `${constants.API_ADMIN_SPONSORSHIP}/CreateSponsorship`;

      var createData = {
        advertiserId: formData.advertiserId,
        mapObjectId: formData.mapObjectId,
        startsOn: formData.startsOn,
        expiresOn: formData.expiresOn,
        sponsorshipType: formData.sponsorshipType,
      };

      const newId = await axios
        .post(url, createData)
        .then((res) => res.data.id);


      return newId;
    } catch {
      return false;
    }
  };
  
  getSponsorships = (advertiserId: any, tz: string | null = null) => {
    const url = `${constants.API_ADMIN_SPONSORSHIP}/GetList?advertiserId=${advertiserId}`;
    const timeZoneName = tz ? tz : moment.tz.guess();
    const timeZone = moment.tz.zone(timeZoneName);
    return axios.get(url).then((res): ISponsorshipDisplayItem[] =>
      res.data.map((x: ISponsorship) => ({
        id: x.id,
        itemTitle: x.mapObject?.displayName || x.collection?.displayName || "N/A",
        itemType: x.mapObject ? "Trophy" : "Collection",
        sponsorshipType: x.sponsorshipType,
        startsOn: moment.tz(x.startsOn, timeZoneName).format("ll") + ` (${timeZone?.abbr(x.startsOn)})`,
        expiresOn: moment.tz(x.expiresOn, timeZoneName).format("ll")+ ` (${timeZone?.abbr(x.expiresOn)})`,
      }))
    );
  };

  deleteSponsorship = async (sponsorshipId: string) => {
    const url = `${constants.API_ADMIN_SPONSORSHIP}/DeleteSponsorship?id=${sponsorshipId}`;
    try {
      await axios.delete(url).then();
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  };
}

export default new SponsorshipService();
