import React from "react";
import { Spinner } from "react-bootstrap";

function Loading() {
  return (
    <div className="d-flex justify-content-center m-5">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default Loading;
