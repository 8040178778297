import React from "react";
import { ICollectionConditions } from "../../../types/objects";
import styles from "./ObjectCollection.module.css";
import VisibilityScheduleView from "../../VisibilitySchedules/VisibilityScheduleView/VisibilityScheduleView";
import CollectionConditionsDisplay from "./CollectionConditionsInfo";

interface IProps {
  data: {
    scheduleId?: null | string;
    collectionConditions?: ICollectionConditions;
  };
}

function Conditions({ data }: IProps) {
  return (
    <div
      className="align-items-center ps-4"
      style={{ height: 41 }}
    >
      <h3>Conditions</h3>
      {data.scheduleId !== undefined && (
        <div className={styles.textBlock}>
          <h6>Visibility schedule</h6>
          <VisibilityScheduleView value={data.scheduleId} />
        </div>
      )}
      {data.collectionConditions !== undefined && (
      <CollectionConditionsDisplay {...data.collectionConditions} />
      )}
    </div>
  );
}

export default Conditions;
