import { ITableColumn } from "../../../../types/common";


function Columns(): ITableColumn[] {
  return [
    {
      id: "itemTitle",
      displayName: "Item",
      width: "30vw",
    },
    {
      id: "itemType",
      displayName: "Type",
      width: "10vw",
    },
    {
      id: "sponsorshipType",
      displayName: "Ownership",
      width: "10vw",
    },
    {
      id: "startsOn",
      displayName: "Starts on",
      width: "10vw",
    },
    {
      id: "expiresOn",
      displayName: "Expires on",
      width: "10vw",
    },
  ];
}

export default Columns;
