import React, { useCallback, useEffect, useState } from "react";
import CollectionsTable from "./CollectionsTable";
import CollectionObjectsService from "../../../services/Collections/CollectionObjectsService";
import { IStatus } from "../../../types/common";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  clearObjData,
  setObjCollections,
} from "../../../redux/action-creators/object";
import LoadingWrapper from "../../common/LoadingWrapper";
import styles from "../Object.module.css";
import PrivateAccess from "../../common/PrivateAccess";
import { UserRole } from "../../../types/userManagement";
import { Button } from "react-bootstrap";
import ModalInfoService from "../../../redux/services/ModalInfoService";

function Collections() {
  const objectState = useTypedSelector((state) => state.object);
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    const loadData = async () => {
      try {
        return await CollectionObjectsService.getCollectionsByObject(
          objectState.id
        );
      } catch {
        setStatus(IStatus.Error);
      }
    };
    loadData().then((res) => {
      if (res) {
        dispatch(setObjCollections(res));
        setStatus(IStatus.Success);
      }
    });
  }, [objectState.id, dispatch]);

  useEffect(() => {
    getData();
    return () => {
      dispatch(clearObjData());
    };
  }, [getData, dispatch]);

  const onDiscardClick = () => {
    setStatus(IStatus.Loading);
    getData();
  };

  const onUpdateClick = async () => {
    ModalInfoService.showModalInfoLoading();
    let success = true;
    for (const col of objectState.collections) {
      if (success && !col.saved) {
        const updated = await CollectionObjectsService.updateCollectionObjects(
          col.id,
          [objectState.id],
          false
        );
        if (!updated) {
          success = false;
          ModalInfoService.showModalInfoError(
            "We have problems updating collection(s)"
          );
        }
      }
    }
    if (success) ModalInfoService.closeModalInfo();
    setStatus(IStatus.Loading);
    getData();
  };

  return (
    <>
      <div style={{ height: "calc(100% - 126px)" }}>
        <LoadingWrapper status={status}>
          <>
            <div
              className="d-flex justify-content-between align-items-center ps-4"
              style={{ height: 41 }}
            >
              <h3>Collections</h3>
            </div>
            <CollectionsTable />
          </>
        </LoadingWrapper>
      </div>
      <div className={styles.buttonContainer}>
        {objectState.id && objectState.wasChanged && (
          <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
            <Button
              data-test-id="obj-col-discard"
              variant="light"
              size="sm"
              onClick={onDiscardClick}
            >
              Discard changes
            </Button>
            <Button
              data-test-id="obj-col-update"
              variant="primary"
              size="sm"
              onClick={onUpdateClick}
            >
              Update collection
            </Button>
          </PrivateAccess>
        )}
      </div>
    </>
  );
}

export default Collections;
