import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../../styles/details.module.css";
import { IStatus } from "../../../../types/common";
import { Button, Container, Row, Col } from "react-bootstrap";
import {
  ICouponCampaign,
  ICouponCampaignDisplay, ICouponEditForm, initCouponForm
} from "../../../../types/coupons";
import { showModal } from "../../../../redux/action-creators/modal";
import { useDispatch } from "react-redux";
import CouponsService from "../../../../services/Advertisers/CouponsService";
import CouponEditorForm from "./CouponEditorForm";
import { AlertType } from "../../../../types/alerts";
import constants from "../../../../constants";
import PublishSwitcher from "../Common/PublishSwitcher";
import AlertsService from "../../../../redux/services/AlertsService";
import LoadingWrapper from "../../../common/LoadingWrapper";
import ModalInfoService from "../../../../redux/services/ModalInfoService";
import PrivateAccess from "../../../common/PrivateAccess";
import { UserRole } from "../../../../types/userManagement";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import AdvertiserService from "../../../../services/Advertisers/AdvertiserService";
import CouponDisplayFormInfo from "./CouponDisplayFormInfo";
import CouponRelatedObjectsList from "./CouponRelatedObjectsList";

function CouponEditor() {
  const userRole = useTypedSelector((store) => store.auth.role);
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [couponViewData, setCouponViewData] = useState<ICouponCampaignDisplay | null>(null);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [couponForm, setCouponForm] = useState<ICouponEditForm | null>(null);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  const [tz, setTz] = useState<string>("UTC");
  let { adId } = useParams<{ adId: string }>();
  let { couponId } = useParams<{ couponId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdData = async (id: string) => {
      try {
        return await AdvertiserService.getAdvertiser(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    const loadCouponData = async (id: string, tz: string | null) => {
      try {
        return await CouponsService.getCoupon(id, tz);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    if (adId) {
      loadAdData(adId).then((resAd) => {
        if (resAd === undefined) return;
        setTz(resAd.displayTimeZone || "UTC");
        if (couponId) {
          loadCouponData(couponId, resAd.displayTimeZone).then((res) => {
            if (res) {
              setCouponViewData(res);
              generateCouponForm(res).then();
            }
          });
        } else {
          if (adId)
            setCouponForm({
              ...initCouponForm,
              advertiserId: adId,
            });
          setStatus(IStatus.Success);
        }
      });
    }
  }, [couponId, adId]);

  const generateCouponForm = async (coupon: ICouponCampaign) => {
    setCouponForm({
      id: coupon.id,
      advertiserId: coupon.advertiser.id,
      name: coupon.name,

      startOn: coupon.startOn,
      endOn: coupon.endOn,
    
      teaserTitle: coupon.teaserTitle,
      teaserDescription: coupon.teaserDescription,
      teaserImagePath: coupon.teaserImagePath,
      teaserButton: coupon.teaserButton,

      couponTitle: coupon.couponTitle,
      couponDescription: coupon.couponDescription,
      couponImagePath: coupon.couponImagePath,
      couponButton: coupon.couponButton,

      isActive: coupon.isActive,
    });

    setStatus(IStatus.Success);
  };

  const handleCouponChange = (name: string, value: any) => {
    setHasChanges(true);
    setCouponForm((prevState) =>
      prevState ? { ...prevState, [name]: value } : null
    );
    let errorId = name;
    if (name === "startDisplayOn" || name === "endDisplayOn") errorId = "dates";
    errorValues.includes(errorId) &&
      setErrorValues((prevState) => prevState.filter((er) => er !== errorId));
  };

  const onSaveClick = async () => {
    if ( couponForm && (await isCouponFormValid(couponForm))) {
      ModalInfoService.showModalInfoLoading();
      (couponId
        ? CouponsService.updateCoupon(couponForm, tz)
        : CouponsService.createCoupon(couponForm, tz)
      ).then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          handleBackToAdInfo();
        } else {
          ModalInfoService.showModalInfoError("We have problems saving coupon");
        }
      });
    }
  };

  const isCouponFormValid = async (
    coupon: ICouponEditForm
  ): Promise<boolean> => {
    let isValid: boolean = true;
    setErrorValues([]);
    if (!coupon.name) {
      saveProblemAlert("Campaign name is empty");
      setErrorValues((prevState) => [...prevState, "name"]);
      isValid = false;
    }

    // dates
    if(coupon.endOn<coupon.startOn){
      saveProblemAlert("'Unavailable on' should be after 'show on' date");
      setErrorValues((prevState) => [...prevState, "endOn"]);
      isValid = false;
    }

    if(coupon.endOn< Date.now()){
      saveProblemAlert("'Unavailable on' should be in future");
      setErrorValues((prevState) => [...prevState, "endOn"]);
      isValid = false;
    }

    // teaser
    if(!coupon.teaserTitle){
      saveProblemAlert("Teaser title is empty");
      setErrorValues((prevState) => [...prevState, "teaserTitle"]);
      isValid = false;
    }

    if(!coupon.teaserDescription){
      saveProblemAlert("Teaser description is empty");
      setErrorValues((prevState) => [...prevState, "teaserDescription"]);
      isValid = false;
    }

    if((coupon.teaserButton?.url && !coupon.teaserButton?.title) ||
      (!coupon.teaserButton?.url && coupon.teaserButton?.title)){
      saveProblemAlert("Teaser button should have title and link");
      setErrorValues((prevState) => [...prevState, "teaserButton"]);
      isValid = false;
    }

    if(coupon.teaserButton?.url && !coupon.teaserButton?.url.match(constants.URL_REGEXP)){
      saveProblemAlert("Teaser link is not valid");
      setErrorValues((prevState) => [...prevState, "teaserButton"]);
      isValid = false;
    }


    // coupon
    if(!coupon.couponTitle){
      saveProblemAlert("Coupon title is empty");
      setErrorValues((prevState) => [...prevState, "couponTitle"]);
      isValid = false;
    }

    if(!coupon.couponDescription){
      saveProblemAlert("Coupon description is empty");
      setErrorValues((prevState) => [...prevState, "couponDescription"]);
      isValid = false;
    }

    if((coupon.couponButton?.url && !coupon.couponButton?.title) ||
      (!coupon.couponButton?.url && coupon.couponButton?.title)){
      saveProblemAlert("Coupon button should have title and link");
      setErrorValues((prevState) => [...prevState, "couponButton"]);
      isValid = false;
    }

    if(coupon.couponButton?.url && !coupon.couponButton?.url.match(constants.URL_REGEXP)){
      saveProblemAlert("Coupon link is not valid");
      setErrorValues((prevState) => [...prevState, "couponButton"]);
      isValid = false;
    }

    return isValid;
  };

  const saveProblemAlert = (text: string) => {
    AlertsService.addAlert(text, AlertType.WARNING);
  };

  const onCancelClick = () => {
    hasChanges
      ? dispatch(
          showModal({
            text: "Go back without saving?",
            primaryAction: handleBackToAdInfo,
          })
        )
      : handleBackToAdInfo();
  };

  const handleBackToAdInfo = () => {
    navigate(`/advertisers/${adId}/coupons`);
  };

  const handlePublishSwitch = async () => {

    var published = !couponForm?.isActive;
    handleCouponChange("isActive", published);
 
    // if not valid, switch it back
    if (published) {
      if(couponForm && !(await isCouponFormValid(couponForm))) {
        setTimeout(() => handlePublishSwitch(), 300);
      }
    } 
  };

  const onDeleteClick = async () => {
    dispatch(
      showModal({
        title: "Delete",
        text: "This will delete the coupon. Proceed?",
        primaryAction: handleDelete,
        primaryText: "Delete coupon",
        secondaryText: "Cancel",
      })
    );
  };

  const handleDelete = async () => {
    if (couponId && (await CouponsService.deleteCoupon(couponId))) handleBackToAdInfo();
  };

  return (
    <div className={styles.editorContainer}>
      <div className={` ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <Container fluid={true}>
            <Row>
              <Col className="ps-3 pt-4 me-3">
                <h3>{couponForm?.id? "Edit coupon campaign" : "Create new coupon campaign" } </h3>
                {couponForm &&
                  (userRole === UserRole.Reader ? (
                    <CouponDisplayFormInfo coupon={couponViewData}  />
                  ) : (
                    <CouponEditorForm
                      coupon={couponForm}
                      tz={tz}
                      couponChange={handleCouponChange}
                      errorValues={errorValues}
                    />
                  ))}

              </Col>
              <Col className="pt-4 ps-2 border-start">
                  <CouponRelatedObjectsList couponCampaignId={couponId || null} />
              </Col>
            </Row>
          </Container>
        </LoadingWrapper>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <div>
          <Button
            data-test-id="coupon-editor-cancel"
            variant="light"
            size="sm"
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <PrivateAccess
            roles={[
              UserRole.Admin,
              UserRole.Editor,
              UserRole.AdvertisingManager,
            ]}
          >
            {couponId && (
              <>
                <Button
                  data-test-id="coupon-editor-delete"
                  variant="danger"
                  size="sm"
                  onClick={onDeleteClick}
                >
                  Delete
                </Button>
              </>
            )}
          </PrivateAccess>
        </div>
        <PrivateAccess
          roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}
        >
          <div className="d-flex align-items-center">
            <PublishSwitcher
              publish={couponForm?.isActive ?? false}
              publishSwitch={handlePublishSwitch}
            />
            <Button
              className="ms-4"
              data-test-id="coupon-editor-save"
              variant="primary"
              size="sm"
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>
        </PrivateAccess>
      </div>
    </div>
  );
}

export default CouponEditor;
