import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ObjectAdminService from "../../../services/Objects/ObjectAdminService";
import { IStatus } from "../../../types/common";
import { IObjectAdmin, initObjectAdmin } from "../../../types/objects";
import styles from "../Object.module.css";
import { Button } from "react-bootstrap";
import AdminEditorForm from "./AdminEditorForm";
import { showModal } from "../../../redux/action-creators/modal";
import { useDispatch } from "react-redux";
import LoadingWrapper from "../../common/LoadingWrapper";
import ModalInfoService from "../../../redux/services/ModalInfoService";
import moment from "moment-timezone";

interface IProps {
  backToInfo: () => void;
}

function getDefaultDisplayTimezone(response: any) {
  if (response.visibilitySchedule) {
    return response.displayTimeZone
      ? response.displayTimeZone
      : moment.tz.guess();
  }

  return null;
}

function AdminEditor({ backToInfo }: IProps) {
  let { objectId } = useParams<{ objectId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [object, setObject] = useState<IObjectAdmin>(initObjectAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        return await ObjectAdminService.getObjectAdmin(id);
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };
    if (objectId) {
      loadData(objectId).then((res) => {
        if (res) {
          setStatus(IStatus.Success);
          setObject({
            ...res,
            displayTimeZone: getDefaultDisplayTimezone(res),
            adminPictures: res.adminPictures.map((img: string) => ({
              url: img,
            })),
          });
        }
      });
    }
  }, [objectId]);

  const handleObjectChange = (values: { name: string; value: any }[]) => {
    setHasChanges(true);

    var newObject = { };
    values.forEach((v) => {
      var tmp = {[v.name]: v.value};
      newObject = {...newObject, ...tmp}; });

    setObject({...object, ...newObject});
  };

  const onSaveClick = () => {
    if (objectId) {
      ModalInfoService.showModalInfoLoading();

      ObjectAdminService.editorSaveObject(objectId, object).then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          backToInfo();
        } else {
          ModalInfoService.showModalInfoError(
            "We have problems saving object data"
          );
        }
      });
    }
  };

  const onCancelClick = () => {
    hasChanges
      ? dispatch(
          showModal({
            text: "Go back without saving?",
            primaryAction: backToInfo,
          })
        )
      : backToInfo();
  };

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <div className={styles.adminEditorContainer}>
            <AdminEditorForm
              object={object}
              objectChange={handleObjectChange}
            />
          </div>
        </LoadingWrapper>
      </div>
      <div
        className={`${styles.buttonContainer} d-flex justify-content-between`}
      >
        <Button variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default AdminEditor;
