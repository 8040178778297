import React from "react";
import { Form } from "react-bootstrap";
import moment from "moment-timezone";

const timeZones = moment.tz.names();

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  label: string;
  [propName: string]: any;
}

export function TimeZonesSelect({ onChange, value, label, ...props }: IProps) {
  const options = timeZones.map((timeZone) => (
    <option key={timeZone}>{timeZone}</option>
  ));

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select onChange={onChange} value={value} {...props}>
        {options}
      </Form.Select>
    </Form.Group>
  );
}
