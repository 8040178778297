const constants = {
  APP_POOL: "CS.AdminApi",
  API_AUTH_ENDPOINT: `${process.env.REACT_APP_AUTH_ENDPOINT}/api/Auth`,
  API_AUTH_MANAGEMENT_ENDPOINT: `${process.env.REACT_APP_AUTH_ENDPOINT}/api/Management`,

  API_ADMIN_USERS: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/AdminUsers`,
  API_ADMIN_COLLECTIONS: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/Collections`,
  API_ADMIN_OBJECTS_ENDPOINT: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/MapObjects`,
  API_ADMIN_VISIBILITY_SCHEDULES_ENDPOINT: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/VisibilitySchedules`,
  API_ADMIN_CONVERSATIONS_ENDPOINT: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/Conversations`,
  API_ADMIN_OBJECT_VERSIONS_ENDPOINT: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/MapObjectVersions`,
  API_ADMIN_GEONAMES: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/Geonames`,
  API_ADMIN_AD: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/Advertising`,
  API_ADMIN_AD_USERS: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/AdvertiserUsers`,
  API_ADMIN_SPONSORSHIP: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/Sponsorship`,
  API_ADMIN_COUPONS: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/CouponCampaigns`,
  API_ADMIN_COLLECTORS: `${process.env.REACT_APP_ADMIN_ENDPOINT}/api/Collectors`,
  API_ADMIN_ENDPOINT: process.env.REACT_APP_ADMIN_ENDPOINT,
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT
    : "Development",
  ENVS: {
    DEV: "Development", // developer's local machine
    UAT: "Stage", // https://console.timespacevault.com/
    PROD: "Production", // https://console.cybertrophy.social/
  },
  LATITUDE_REGEXP:
    "^(\\+|-)?(?:90(?:(?:\\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,15})?))$",
  LONGITUDE_REGEXP:
    "^(\\+|-)?(?:180(?:(?:\\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,15})?))$",
  URL_REGEXP:
    "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.%]+$",
  API_ERROR_CODES: {
    "user.not-found": "User was not found",
    "user.suspended": "User is suspended",
    "otp.invalid": "One-time password is invalid",
    "otp.too-many-requests": "Too many requests",
    "user.already-exists": "User with provided email already exists",


    "object-version.not-found": "The requested object version was not found.",
  "object-version.state.unexpected": "The object version is in an unexpected state.",
  "object-version.details.missing": "Details for the object version are missing.",
  "object-version.display-name.missing": "Display name for the object version is missing.",
  "version.cannot-remove-single-version": "Cannot remove the only version of an object.",
  
  "map-object.not-found": "The object was not found.",
  
  "file.invalid-format": "The file format is invalid.",
  
  "publisher.not-found": "The publisher was not found.",
  
  "collection.not-found": "The collection was not found.",
  "collection.cannot-delete-draft": "Cannot delete a draft collection.",
  "collection.publish-requirements-failed": "The collection does not meet the publishing requirements.",
  "collection.name-description-missing": "The collection's name or description is missing.",
  "collection.not-enough-object": "The collection does not have enough objects to be published.",
  "collection.publish-invalid-state": "The collection is in an invalid state for publishing.",
  
  "advertiser.not-found": "The advertiser was not found.",
  
  "deal.not-found": "The deal was not found.",
  "coupon-campaign.not-found": "The coupon campaign was not found.",
  
  "deal.invalid-dates": "The deal has invalid dates.",
  "location.has-deals": "The location already has deals associated with it.",
  
  "sponsorship.not-found": "The sponsorship was not found."
  },
  GOOGLE_MAP_URL_SEARCH: "https://www.google.com/maps/search/?api=1&query=",
};

export default constants;
