import React, { useEffect, useState } from "react";
import { IStatus } from "../../../../types/common";
import LoadingWrapper from "../../../common/LoadingWrapper";
import ObjectsService from "../../../../services/Objects/ObjectsService";
import { IObject } from "../../../../types/objects";
import { ICollection } from "../../../../types/collections";
import CollectionsService from "../../../../services/Collections/CollectionsService";
import CouponsService from "../../../../services/Advertisers/CouponsService";
import ObjectsListWithAddRemove from "../../../Objects/ObjectsListWithAddRemove/ObjectsListWithAddRemove";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CollectionsListWithAddRemove from "../../../Collection/CollectionListWithAddRemove/CollectionsListWithAddRemove";

interface IProp {
    couponCampaignId: string | null;
}
function CouponRelatedObjectsList({ couponCampaignId }: IProp) {

    const [objects, setObjects] = useState<IObject[]>([]);
    const [objectsLoadingState, setObjectsLoadingState] = useState<IStatus>(IStatus.Loading);

    const [collections, setCollections] = useState<ICollection[]>([]);
    const [collectionsLoadingState, setCollectionsLoadingState] = useState<IStatus>(IStatus.Loading);

    const reloadObjects = (campaignId: string) => {
        ObjectsService.getObjects(0, 200, "coupon: " + campaignId, null, null)
        .then((objects) => {
            setObjects(objects);
            setObjectsLoadingState(IStatus.Success);
        }).catch((err) => {
            setObjectsLoadingState(IStatus.Error); 
        });
    }

    const reloadCollections = (campaignId: string) => {
        CollectionsService.getCollections(0, 200, "coupon: " + campaignId)
        .then((collections) => {
            setCollections(collections);
            setCollectionsLoadingState(IStatus.Success);
        }).catch((err) => {
            setCollectionsLoadingState(IStatus.Error);
        });
    }


    useEffect(() => {
        if (couponCampaignId) {
            reloadObjects(couponCampaignId);
            reloadCollections(couponCampaignId);
        }
    }, [couponCampaignId]);

    const assignObject = async (objectId: string) => {
        if (couponCampaignId) {
            await CouponsService.assignObject(couponCampaignId, objectId)
            reloadObjects(couponCampaignId);
        }
    }

    const unassignObject = async (objectId: string) => {
        if (couponCampaignId) {
            await CouponsService.unassignObject(couponCampaignId, objectId);
            reloadObjects(couponCampaignId);
        }
    }

    const assignCollection = async (collectionId: string) => {
        if (couponCampaignId) {
            await CouponsService.assignCollection(couponCampaignId, collectionId)
            reloadCollections(couponCampaignId);
        }
    }

    const unassignCollection = async (collectionId: string) => {
        if (couponCampaignId) {
            CouponsService.unassignCollection(couponCampaignId, collectionId)
            reloadCollections(couponCampaignId);
        }
    }



    if (couponCampaignId === null)
        return <></>;

    return (
        <div>
            <Tabs defaultActiveKey="objects" id="coupon-related-objects">
                <Tab eventKey="objects" title="Assigned Objects">
                    <LoadingWrapper status={objectsLoadingState}>
                        <ObjectsListWithAddRemove 
                            objects={objects} 
                            emptyPlaceholder="No objects assigned" 
                            onObjectAdded={assignObject}
                            onObjectRemoved={unassignObject}
                            />
                    </LoadingWrapper>
                </Tab>
                <Tab eventKey="collections" title="Assigned Collections">
                    <LoadingWrapper status={collectionsLoadingState}>
                        <CollectionsListWithAddRemove 
                            collections={collections} 
                            emptyPlaceholder="No collections assigned"
                            onCollectionAdded={assignCollection}
                            onCollectionRemoved={unassignCollection} 
                        />
                    </LoadingWrapper>
                </Tab>
            </Tabs>
        </div>
    );
}

export default CouponRelatedObjectsList;
