import React from "react";
import { Col, Row } from "react-bootstrap";
import { IDealForm } from "../../../../types/advertisers";
import styles from "../../../common/ObjectCollection/ObjectCollection.module.css";
import ClickableImage from "../../../common/ClickableImage/ClickableImage";
import commonStyles from "../../../../styles/common.module.css";

interface IProp {
  deal: IDealForm;
}
function DealDisplayFormInfo({ deal }: IProp) {
  return (
    <div>
      <div className={styles.textBlock}>
        <h6>Admin deal identifier</h6>
        <div data-test-id="deal-info-name">{deal.name}</div>
      </div>
      <div className={styles.textBlock}>
        <h6>Description</h6>
        <div
          data-test-id="deal-info-description"
          className={commonStyles.whiteSpacePreLine}
        >
          {deal.description}
        </div>
      </div>
      <Row>
        <Col>
          <div className={styles.textBlock}>
            <h6>Start date</h6>
            <div data-test-id="deal-info-startDisplayOn">
              {deal.startDisplayOn}
            </div>
          </div>
        </Col>
        <Col>
          <div className={styles.textBlock}>
            <h6>End date</h6>
            <div data-test-id="deal-info-endDisplayOn">{deal.endDisplayOn}</div>
          </div>
        </Col>
      </Row>

      <div className={styles.textBlock}>
        <h6>Link</h6>
        <div data-test-id="deal-info-link">{deal.link}</div>
      </div>
      {deal.imageUrl && (
        <div className="d-flex justify-content-center mt-5">
          <ClickableImage
            style={{ objectFit: "cover", borderRadius: "18px" }}
            alt="deal"
            width="280px"
            height="116px"
            src={deal.imageUrl}
          />
        </div>
      )}
    </div>
  );
}

export default DealDisplayFormInfo;
