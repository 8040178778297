import { IComponentCellProps, ITableColumn } from "../../../types/common";
import styles from "../../../styles/common.module.css";
import React from "react";

function Columns(): ITableColumn[] {
  return [
    {
      id: "name",
      displayName: "Company",
      width: "240px",
    },
    {
      id: "activeDealsCount",
      displayName: "Active deals",
      width: "140px",
    },
    {
      id: "scheduledDealsCount",
      displayName: "Scheduled deals",
      width: "140px",
    },
    {
      id: "couponsCount",
      displayName: "Coupons",
      width: "140px",
    },
    {
      id: "country",
      displayName: "Country",
      pattern: (v) => (v ? v.displayName : ""),
    },
    {
      id: "isArchived",
      displayName: "Status",
      width: "100px",
      component: StatusCell,
    },
  ];
}

function StatusCell(props: IComponentCellProps) {
  return (
    <div
      className={styles.tag}
      style={{
        backgroundColor: props.value ? "#E9ECEF" : "#28A745",
        color: props.value ? "#212529" : "#FFFFFF",
      }}
    >
      {props.value ? "Archived" : "Published"}
    </div>
  );
}

export default Columns;
