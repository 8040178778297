import { ICollector } from "../types/collectors";
import constants from "../constants";
import axios from "axios";

class CollectorsService {
  getCollectorById = (id: string): Promise<ICollector | null> => {
    const url = `${constants.API_ADMIN_COLLECTORS}/GetById?id=${id}`;
    return this.getCollector(url);
  };

  getCollectorByEmail = async (email: string): Promise<ICollector | null> => {
    const encodedEmail = encodeURIComponent(email);
    const url = `${constants.API_ADMIN_COLLECTORS}/GetByEmail?email=${encodedEmail}`;
    return this.getCollector(url);
  };

  getCollector = async (url: string): Promise<ICollector | null> => {
    let collector: ICollector | null = null;
    const config = {
      method: "get",
      url: url,
      selfHandled: true,
    };
    await axios(config)
      .then((res) => {
        collector = res.data;
      })
      .catch(() => {
        collector = null;
      });
    return collector;
  };
}

export default new CollectorsService();
